import React from 'react';
import { Line } from 'react-konva';
import { markerTrailLength, PathProps, timestampSearchRange } from 'types/webPlayer';
import { PATH_COLOR, PATH_WIDTH } from '../track/ground/constants';

const Path = React.memo((props: PathProps) => {
  const {
    cursor,
    pathData,
    scale = 1,
    pathColor,
  } = props;

  const paths: number[][] = [[]];
  let lineNumber = 0;
  Object.keys(pathData).forEach((t) => {
    const timestamp = parseInt(t, 10);
    const values = pathData[t];
    if (timestamp > cursor - markerTrailLength && timestamp < (cursor - timestampSearchRange)) {
      if (values.x === null || values.y === null) {
        lineNumber += 1;
        paths[lineNumber] = [];
      } else {
        paths[lineNumber].push(values.x * scale);
        paths[lineNumber].push(values.y * scale);
      }
    }
  });

  return (
    <>
      {
        paths.map((path, idx) => (
          <Line
            /* eslint-disable-next-line react/no-array-index-key */
            key={idx}
            points={path}
            stroke={pathColor || PATH_COLOR}
            strokeWidth={PATH_WIDTH}
          />
        ))
      }
    </>
  );
});

export default Path;
