import { gql } from '@apollo/client/core';
import { TemplateType } from 'models/template';

export type TemplatesVars = {
  first?: number;
  skip?: number;
  sort?: string;
  teamId: string;
  withFields: boolean;
  name?: string[];
  sport?: string,
  visibility?: string[],
  roleGrouping?: boolean,
  highlights?: boolean,
  pdfOrientation?: string,
  startLastUpdate?: Date,
  endLastUpdate?: Date,
  exactLastUpdate?: Date | null,
};

export type TemplatesRes = {
  res: {
    content?: TemplateType[];
    count?: number;
    offset?: number;
    pageSize?: number;
  };
};

export const TEMPLATE_FIELDS = gql`
  fragment TemplateParts on TemplateType {
    sport
    updatedOn
    visibility
    roleGrouping
    pdfOrientation
    highlights
  }
`;

export const TEMPLATES = gql`
  ${TEMPLATE_FIELDS}
  query Templates(
    $teamId: ID, 
    $first: Int, 
    $skip: Int, 
    $sort: String, 
    $withFields: Boolean!
    $name: [String],
    $highlights: Boolean,
    $roleGrouping: Boolean,
    $pdfOrientation: String,
    $visibility: [String],
    $startLastUpdate: DateTime,
    $endLastUpdate: DateTime,
    $exactLastUpdate: DateTime,
    ) 
    {
    res: templates(
      teamId: $teamId, 
      first: $first, 
      skip: $skip, 
      sort: $sort
      name: $name,
      highlights: $highlights,
      roleGrouping: $roleGrouping,
      pdfOrientation: $pdfOrientation,
      visibility: $visibility,
      startLastUpdate: $startLastUpdate,
      endLastUpdate: $endLastUpdate,
      exactLastUpdate: $exactLastUpdate,
      ) 
      {
      content {
        id
        name
        ...TemplateParts @include(if: $withFields)
      }
    }
  }
`;

export const TEMPLATE = gql`
  ${TEMPLATE_FIELDS}
  query Template($id: ID!) {
    res: template(id: $id) {
      name
      id
      editable
      ...TemplateParts
      ownerEmail
      ordering
      orderingDirection
      templatefieldSet {
        field
        id
        position
      }
    }
  }
`;

export const IS_TEMPLATE_NAME_ALLOWED = gql`
query isTemplateNameAllowed (
  $teamId: ID!,
  $name: String!,
) {
  res: isTemplateNameAllowed (
    teamId: $teamId,
    name: $name,
  ) 
}
`;
