import {
  useQuery, useLazyQuery, DocumentNode, TypedDocumentNode, QueryHookOptions,
  QueryResult, OperationVariables, LazyQueryHookOptions, LazyQueryResultTuple, ApolloError,
} from '@apollo/client';

export const filterGraphQlData = (data) => Object.keys(data).filter((k) => k !== '__typename').reduce((acc, k) => {
  acc[k] = data[k];
  return acc;
}, {});

export function useQueryCached<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: QueryHookOptions<TData, TVariables> | undefined): QueryResult<TData, TVariables> {
  /**
 * A custom hook for using Apollo Client's `useQuery` with enhanced loading behavior.
 * The loading indicator is set to true only when data is not available in the cache and the query is actively executing.
 *
 * @param query - The GraphQL query document or operation to execute.
 * @param options - Additional options for the `useQuery` hook.
 * @returns A result object with modified loading behavior.
 */
  const result = useQuery<TData, TVariables>(query, options);
  const isActuallyLoading = result.loading
    && !result.client?.readQuery({
      query,
      variables: options?.variables,
    });
  return { ...result, loading: isActuallyLoading };
}

export function useLazyQueryCached<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: LazyQueryHookOptions<TData, TVariables> | undefined): LazyQueryResultTuple<TData, TVariables> {
  /**
 * A custom hook for using Apollo Client's `useLazyQuery` with enhanced loading behavior.
 * The loading indicator is set to true only when data is not available in the cache and the query is actively executing.
 *
 * @param query - The GraphQL query document or operation to execute.
 * @param options - Additional options for the `useLazyQuery` hook.
 * @returns A tuple containing the query execution function and a result object with modified loading behavior.
 */
  const result = useLazyQuery<TData, TVariables>(query, {
    ...options,
  });
  const [executeFunction, values] = result;
  const isActuallyLoading = values?.loading
    && !values?.client?.readQuery({
      query,
      variables: options?.variables,
    });
  return [executeFunction, { ...values, loading: isActuallyLoading }];
}

/**
 * Gets, if there is, a "404" `message" in the Apollo response
 *
 * We use `graphQLErrors.message` for response statuses in 404 situations
 *
 * @param graphQLErrors
 */
export const get404Responses = (graphQLErrors: ApolloError) => (graphQLErrors.graphQLErrors || graphQLErrors).filter((ge) => ge.message === '404').length > 0;
