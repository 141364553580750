import React, { useCallback } from 'react';
import { Circle, Group, Path, Text } from 'react-konva';
import { MarkerProps } from 'types/webPlayer';

const Marker = React.memo(( props: MarkerProps ) => {
  const {
    active,
    color,
    cursor,
    extraData,
    hover,
    id,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    pathData,
    scale,
    text,
    textColor,
  } = props;

  const handleClick = useCallback(() => onClick(id), [id, onClick]);
  const handleDblClick = useCallback(() => onDblClick?.(id), [id, onDblClick]);
  const handleEnter = useCallback(() => onMouseEnter?.(id), [id, onMouseEnter]);
  const handleLeave = useCallback(() => onMouseLeave?.(id), [id, onMouseLeave]);

  const position = pathData?.[cursor];
  const posX = position?.x !== null && position?.x !== undefined ? position.x * scale : 0;
  const posY = position?.y !== null && position?.y !== undefined ? position.y * scale : 0;

  if (!position) {
    return null;
  }

  return (
    <>

        {
          position.h && (
            <Group
              rotation={position.h || undefined}
              x={posX}
              y={posY}
            >
              <Path
                data="M13.7715 22.0564C16.5641 23.8395 20.2734 23.0211 22.0564 20.2285C23.8395 17.4359 23.0211 13.7266 20.2285 11.9436C17.436 10.1605 13.7267 10.9789 11.9436 13.7715C10.1605 16.5641 10.9789 20.2734 13.7715 22.0564Z"
                fill={color}
                offsetX={17}
                offsetY={21}
              />
              <Path
                data="M34 8.33333C34 7.57893 32.9474 7.5023 32.6467 8.19419C30.0563 14.1548 24.0259 18.3333 17 18.3333C9.97412 18.3333 3.94371 14.1548 1.35331 8.19419C1.05262 7.5023 0 7.57893 0 8.33333C0 17.5381 7.61116 25 17 25C26.3888 25 34 17.5381 34 8.33333Z"
                fill={color}
                offsetX={17}
                offsetY={21}
              />
            </Group>
          )
        }
        {
          !position.h && (
              <Circle
                fill={color}
                onClick={handleClick}
                onDblClick={handleDblClick}
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                radius={active || hover ? 8 : 5}
                stroke={active ? '' : hover ? 'orange' : 'white'}
                x={posX}
                y={posY}
              />
          )
        }

      <Text
        align="center"
        text={text}
        x={posX - text.length * 4}
        y={posY - 17}
        fill={textColor || '#828282'}
        fontSize={12}
        fontFamily="Roboto"
      />

      <Text
        align="center"
        text={extraData ? `${extraData.s?.toFixed(1)}km/h` : ''}
        x={posX  + 10}
        y={posY - 17}
        fill={textColor || '#dadada'}
        fontSize={12}
        fontFamily="Roboto"
      />
    </>
  );
});

export default Marker;
