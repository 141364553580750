import { Box } from '@material-ui/core';
import { Layer, Stage } from 'react-konva';
import React, { forwardRef } from 'react';
import { getOuterGroundColor } from '../../models/ground';
import { Pitch } from '../track/ground/Ground';
import PlayersLinks from './PlayersLinks';
import PlayersMarkers from './PlayersMarkers';
import ZoomControls from './ZoomControls';
import { GroundFieldData, GroundFieldProps } from '../../types/webPlayer';

const GroundField = forwardRef<HTMLDivElement, GroundFieldProps & GroundFieldData>((props, parentRef) => {
  const {
    activePlayer,
    hoverPlayer,
    children,
    cursor,
    defaultPlayersColor,
    dataGround,
    enabledPlayers,
    jerseyOrNumber,
    offX,
    offY,
    onPlayerClick,
    onPlayerDblClick,
    onPlayerEnter,
    onPlayerLeave,
    playersColors,
    playersDetails,
    playersExtraData,
    playersLines,
    removeLink,
    scale,
    series,
    setZoom,
    showVertices,
    trailsEnabled,
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
    zoom,
  } = props;

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="center" width="100%">
        <Box
          bgcolor={getOuterGroundColor(dataGround?.groundSurfaceColor)}
          position="relative"
          width="100%"
        >
          <div
            className="player__wrapper"
            ref={parentRef}
            style={{
              width: '100%',
              height: 'auto',
            }}
          >
            <Stage
              id="container"
              offsetX={offX}
              offsetY={offY}
              draggable
              width={WINDOW_WIDTH}
              height={WINDOW_HEIGHT}
              scaleX={zoom}
              scaleY={zoom}
              onDragEnd={(e) =>  console.log(e.currentTarget.x(), e.currentTarget.y())}
            >
              <Layer listening={false} id="layer-pitch">
                <Pitch
                  defaultGround={false}
                  groundData={dataGround}
                  scale={scale}
                  vertices={showVertices}
                />
              </Layer>

              {
                playersLines
                && removeLink
                && (
                  <PlayersLinks
                    playersLines={playersLines}
                    removeLink={removeLink}
                    groundSurfaceColor={dataGround?.groundSurfaceColor}
                  />
                )
              }

              {
                enabledPlayers
                && onPlayerClick
                && playersDetails
                && series
                && playersColors
                && (
                  <PlayersMarkers
                    activePlayer={activePlayer || null}
                    hoverPlayer={hoverPlayer || null}
                    cursor={cursor}
                    defaultPlayersColor={defaultPlayersColor}
                    enabledPlayers={enabledPlayers}
                    groundSurfaceColor={dataGround?.groundSurfaceColor}
                    jerseyOrNumber={!!jerseyOrNumber}
                    onPlayerClick={onPlayerClick}
                    onPlayerDblClick={onPlayerDblClick}
                    onPlayerEnter={onPlayerEnter}
                    onPlayerLeave={onPlayerLeave}
                    playersColors={playersColors}
                    playersDetails={playersDetails}
                    playersExtraData={playersExtraData || {}}
                    scale={scale}
                    series={series}
                    trailsEnabled={!!trailsEnabled}
                  />
                )
              }

              {children}
            </Stage>

            <ZoomControls setZoom={setZoom} zoom={zoom} />
          </div>
        </Box>
      </Box>
    </Box>
  );
});

export default GroundField;
