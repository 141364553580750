import React from 'react';
import { ApolloError } from '@apollo/client';
import { VariWideChart } from 'components/charts/VariWideChart';
import {
  getUomTranslation, secondsToHHMMSS, secondsToHMMSS, secondsToMin,
} from 'components/utils/utils';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import TitleView from 'components/layout/TitleView';
import WidgetComponentWithLoading from 'components/widgets/WidgetComponentWithLoading';
import { UNITS_LABELS } from 'components/utils/units';
import LabelWithMeasure from '../layout/LabelWithMeasure';
import { SimpleHorizontalChart, SimpleHorizontalOneChart } from '../charts/SimpleHorizontalChart';
import { GaugeChart } from '../charts/GaugeChart';
import {
  ACC_ZONES,
  CARDIO_ZONES,
  DEC_ZONES,
  GenericValueType,
  LayoutType,
  POWER_ZONES, REL_SPEED_ZONES,
  SPEED_ZONES,
} from '../../query/track';
import { IconWrapper, Loader } from '../../lib/icons';
import { GenericZoneChart } from './TrackZoneCharts';
import { elementIsEnabled, elementsAreEnabled } from '../utils/layout';
import JumpsByHeight from './JumpsByHeight';
import DistanceByBodyOrientation from './DistanceByBodyOrientation';
import DistanceTimes from './DistanceTimes';
import DivesByReturnTime from './DivesByReturnTime';
import { DivesByLoad } from './DivesByLoad';
import ImpactsByIntensity from './ImpactsByIntensity';
import Error from '../layout/Error';
import TrackStackedChart from './TrackStackedChart';
import ScatterChart from '../charts/ScatterChart';
import { eventsColors } from '../charts/colors';
import { DivesByTotalLoad } from './DivesByTotalLoad';
import DivesDetails from './DivesDetails';
import { Column } from '../layout/Column';
import { Row } from '../layout/Row';
import { OutlinedWrapper } from '../form/FieldsGroup';
import getAxisTitleFormat, { tooltipFormatter } from '../charts/utils';
import { getPrimaryColorGradient, colorsPalette, get2ColorsPalette } from '../utils/colors';
import { formatterChoice } from '../utils/unitsFormatter';

interface PropsIn {
  templateId: string;
  athleteSessionId?: string;
  currentDrill?: number;
  trackId?: string;
  layout?: LayoutType[];
  loading: boolean,
  error?: ApolloError,
  data: any,
  modelVersion?: number,
  isGk?: boolean,
}

const getEventsTooltip = (
  threshName: string,
  durationLabel: string,
  threshInfo?: GenericValueType,
  duration?: GenericValueType,
) => {
  if (threshInfo === undefined || duration === undefined) {
    return <span />;
  }

  const threshNameValue = formatterChoice(threshInfo.unit)(threshInfo.uom, threshInfo.value);
  const firstLine = `${threshName}: ${threshNameValue} ${threshInfo.uom}`;

  const threshDurValue = formatterChoice(duration.unit)(duration.uom, duration.value);
  const secondLine = `${durationLabel}: ${threshDurValue} ${duration.uom}`;

  return (
    <span>
      {firstLine}
      <br />
      {secondLine}
      <br />
    </span>
  );
};

const BasicKPI = React.memo((props: PropsIn) => {
  const {
    templateId, trackId, athleteSessionId, layout, currentDrill,
    loading, error, data,
  } = props;

  const { t } = useTranslation();
  const colors = get2ColorsPalette(colorsPalette);

  if (error) return <Error />;
  return (
    <Box className="basic-kpi">

      {elementsAreEnabled(layout, 'basic_kpi', ['tl', 'rpe']) && (
        <Row>
          {elementIsEnabled(layout, 'basic_kpi', 'tl') && (
            <Column xs={12} md={3} lg={2}>
              <WidgetComponentWithLoading loading={loading} height={160}>
                <LabelWithMeasure
                  text={t('kpi2.main.tl.label', 'TL')}
                  measure={data?.res?.tl?.value}
                  uom={data?.res?.tl?.uom}
                  unit={data?.res?.tl?.unit}
                  icon={<IconWrapper name="RPE" size="medium" color="primary" />}
                />
              </WidgetComponentWithLoading>
            </Column>
          )}

          {elementIsEnabled(layout, 'basic_kpi', 'rpe') && (
            <Column xs={12} md={9} lg={9}>
              <WidgetComponentWithLoading loading={loading}>
                <SimpleHorizontalOneChart
                  dataRight={data?.res?.rpe?.element?.value}
                  uom=""
                  unit="number"
                  title={null}
                  maxVal={data?.res?.rpe?.upperBound?.value}
                  hideLegend
                />
              </WidgetComponentWithLoading>
            </Column>
          )}
        </Row>
      )}

      <Row>
        <Column xs={12} md={3} lg={2}>
          {elementIsEnabled(layout, 'basic_kpi', 'average_time', 'info') && (
            <WidgetComponentWithLoading loading={loading} height={160}>
              <LabelWithMeasure
                text={t('table.duration', 'duration')}
                measure={secondsToHMMSS(Math.round(data?.res?.totalTime?.value) || 0)}
                unit={data?.res?.totalTime?.unit}
                icon={<IconWrapper name="duration" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={12} md={9} lg={10}>
          {elementIsEnabled(layout, 'basic_kpi', 'average_time', 'chart') && (
            <WidgetComponentWithLoading loading={loading}>
              <SimpleHorizontalChart
                key="duration_chart"
                dataLeft={Math.round(secondsToMin(data?.res?.timeWalking.value))}
                dataRight={Math.round(secondsToMin(data?.res?.timeRunning.value))}
                uom={getUomTranslation(t, UNITS_LABELS.MIN)}
                unit={data?.res?.timeWalking?.unit}
                isPercent={false}
                title={null}
                leftLabel={t('track.widgets.basicKPI.labels.walk', 'walk')}
                rightLabel={t('track.widgets.basicKPI.labels.run', 'run')}
                leftIcon={<IconWrapper name="walk" size="medium" color={colors[0]} noMargins center disableHover />}
                rightIcon={<IconWrapper name="run" size="medium" color={colors[1]} noMargins center disableHover />}
                hideLegend
                centeredZero
                maxVal={100}
                tooltipLeftKey={`${t('track.widgets.basicKPI.labels.walk', 'walk')} ${t('table.duration', 'duration')}`}
                tooltipRightKey={`${t('track.widgets.basicKPI.labels.run', 'run')} ${t('table.duration', 'duration')}`}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
      </Row>

      <Row>
        <Column xs={12} md={3} lg={2}>
          {elementIsEnabled(layout, 'basic_kpi', 'total_distance', 'info') && (
            <WidgetComponentWithLoading loading={loading} height={150}>
              <LabelWithMeasure
                text={t('kpi2.main.totalDistance.label', 'distance')}
                measure={data?.res?.distance?.value}
                unit={`${data?.res?.distance?.unit}`}
                uom={getUomTranslation(t, data?.res?.distance.uom)}
                icon={<IconWrapper name="distance" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={12} md={9} lg={10}>
          {elementIsEnabled(layout, 'basic_kpi', 'total_distance', 'chart') && (
            <WidgetComponentWithLoading loading={loading}>
              <SimpleHorizontalChart
                key="distance_chart"
                dataLeft={data?.res?.distWalking?.value}
                dataRight={data?.res?.distRunning?.value}
                uom={getUomTranslation(t, data?.res?.distWalking.uom)}
                unit={data?.res?.distWalking.unit}
                title={null}
                leftLabel={t('track.widgets.basicKPI.labels.walk', 'walk')}
                rightLabel={t('track.widgets.basicKPI.labels.run', 'run')}
                leftIcon={<IconWrapper name="walk" size="medium" color={colors[0]} noMargins center disableHover />}
                rightIcon={<IconWrapper name="run" size="medium" color={colors[1]} noMargins center disableHover />}
                hideLegend
                centeredZero
                tooltipLeftKey={`${t('track.widgets.basicKPI.labels.walk', 'walk')} ${t('kpi2.main.totalDistance.label', 'distance')}`}
                tooltipRightKey={`${t('track.widgets.basicKPI.labels.run', 'run')} ${t('kpi2.main.totalDistance.label', 'distance')}`}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
      </Row>

      <Row className="basic-kpi-gauge-row">
        <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'average_v', 'info') ? 'vertical-spacing__gauge' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'average_v', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.main.averageV.label', 'avg speed')}
                measure={data?.res?.averageV?.value}
                unit={data?.res?.averageV.unit}
                uom={getUomTranslation(t, data?.res?.averageV.uom)}
                icon={<IconWrapper name="avgSpeed" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column style={{ marginTop: '-73.5px' }} xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'max_values_speed', 'chart') ? 'vertical-spacing__gauge' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'max_values_speed', 'chart') && (
            <WidgetComponentWithLoading loading={loading} height={250}>
              <GaugeChart
                data={data?.res?.maxValuesSpeed?.element?.value}
                max={data?.res?.maxValuesSpeed?.upperBound?.value}
                min={data?.res?.maxValuesSpeed?.lowerBound?.value}
                i18nLabel="kpi.max_values_speed"
                uom={getUomTranslation(t, data?.res?.maxValuesSpeed?.element?.uom)}
                unit={data?.res?.maxValuesSpeed?.element?.unit}
                icon={<IconWrapper name="speed" size="medium" color="primary" />}
                tooltipKey={t('kpi2.main.maxValuesSpeed.shortLabel', 'max sp')}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'max_values_acc', 'info') ? 'vertical-spacing__gauge' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'max_values_acc', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.main.maxValuesAcc.label', 'max acc')}
                measure={data?.res?.maxValuesAcc?.value}
                unit={data?.res?.maxValuesAcc?.unit}
                uom={getUomTranslation(t, data?.res?.maxValuesAcc?.uom)}
                icon={<IconWrapper name="maxAcc" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'max_values_dec', 'info') ? 'vertical-spacing__gauge' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'max_values_dec', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.main.maxValuesDec.label', 'max dec')}
                measure={data?.res?.maxValuesDec?.value}
                unit={data?.res?.maxValuesDec?.unit}
                uom={getUomTranslation(t, data?.res?.maxValuesDec?.uom)}
                icon={<IconWrapper name="maxDec" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
      </Row>

      <Row>
        <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'speed_events', 'info') ? '' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'speed_events', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.speedZones.speedEvents.label', 'speed events')}
                measure={data?.res?.speedEvents?.eventCount?.value}
                unit={data?.res?.speedEvents?.eventCount?.unit}
                uom={getUomTranslation(t, data?.res?.speedEvents?.eventCount?.uom)}
                icon={<IconWrapper name="speedEvent" size="medium" color="primary" />}
                isEventCounter
                tooltipTitle={getEventsTooltip(
                  t('teams.labels.speed', 'speed'),
                  t('table.time', 'time'),
                  data?.res?.speedEvents?.thresholdValue,
                  data?.res?.speedEvents?.eventDuration,
                )}
                tooltipPlacement="right"
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'relative_speed_events', 'info') ? '' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'relative_speed_events', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.%SpeedZones.relativeSpeedEvents.label', '%speed events')}
                measure={data?.res?.relativeSpeedEvents?.eventCount?.value}
                unit={data?.res?.relativeSpeedEvents?.eventCount?.unit}
                uom={getUomTranslation(t, data?.res?.relativeSpeedEvents?.eventCount?.uom)}
                icon={<IconWrapper name="speedEvent" size="medium" color="primary" />}
                isEventCounter
                tooltipTitle={getEventsTooltip(
                  t('teams.labels.%speed', '%speed'),
                  t('table.time', 'time'),
                  data?.res?.relativeSpeedEvents?.thresholdValue,
                  data?.res?.relativeSpeedEvents?.eventDuration,
                )}
                tooltipPlacement="right"
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        {elementIsEnabled(layout, 'basic_kpi', 'distance_by_body_orientation', 'chart') && (
        <Column xs={12} md={4} className="imu-kpi-charts chart-distance-by-body-orientation">
          <WidgetComponentWithLoading loading={loading} height={400}>
            <DistanceByBodyOrientation
              data={data}
            />
          </WidgetComponentWithLoading>
        </Column>
        )}
        <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'acceleration_events', 'info') ? '' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'acceleration_events', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.accZones.accelerationEvents.label', 'acc events')}
                measure={data?.res?.accelerationEvents?.eventCount?.value}
                unit={data?.res?.accelerationEvents?.eventCount?.unit}
                uom={getUomTranslation(t, data?.res?.accelerationEvents?.eventCount?.uom)}
                icon={<IconWrapper name="accEvent" size="medium" color="primary" />}
                isEventCounter
                tooltipTitle={getEventsTooltip(
                  t('teams.labels.acceleration', 'acceleration'),
                  t('table.time', 'time'),
                  data?.res?.accelerationEvents?.thresholdValue,
                  data?.res?.accelerationEvents?.eventDuration,
                )}
                tooltipPlacement="left"
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'basic_kpi', 'deceleration_events', 'info') ? '' : 'hide-column'}>
          {elementIsEnabled(layout, 'basic_kpi', 'deceleration_events', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.decZones.decelerationEvents.label', 'dec events')}
                measure={data?.res?.decelerationEvents?.eventCount?.value}
                unit={data?.res?.decelerationEvents?.eventCount?.unit}
                uom={getUomTranslation(t, data?.res?.decelerationEvents?.eventCount?.uom)}
                icon={<IconWrapper name="decEvent" size="medium" color="primary" />}
                isEventCounter
                tooltipTitle={getEventsTooltip(
                  t('teams.labels.acceleration', 'acceleration'),
                  t('table.time', 'time'),
                  data?.res?.decelerationEvents?.thresholdValue,
                  data?.res?.decelerationEvents?.eventDuration,
                )}
                tooltipPlacement="left"
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
      </Row>

      <Box className="inner" />

      <Row>
        {elementIsEnabled(layout, 'basic_kpi', 'speed_zones', 'chart') && (
          <Column xs={12} md={12} lg={6}>
            <Box className="chart-box" mb={5} mr={1} mt={2} ml={2}>
              <Box className="zonechart-label">
                <TitleView
                  title={t('kpi2.speedZones.title', 'speed zones')}
                  prefixIcon={<IconWrapper name="speedZones" size="medium" color="primary" />}
                  align="left"
                />
              </Box>
              <Box className="generic-chart">
                <WidgetComponentWithLoading loading={loading}>
                  <GenericZoneChart
                    templateId={templateId}
                    trackId={trackId}
                    athleteSessionId={athleteSessionId}
                    currentDrill={currentDrill}
                    query={SPEED_ZONES(trackId ? 'track' : 'session')}
                    // colorMap={greenColors}
                    measurePrefix="speedzone"
                    zoneName="speedZones"
                    timeEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'speed_zones', 'chart_time')}
                    distanceEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'speed_zones', 'chart_distance')}
                  />
                </WidgetComponentWithLoading>
              </Box>
            </Box>
          </Column>
        )}
        {elementIsEnabled(layout, 'basic_kpi', 'acceleration_zones', 'chart') && (
          <Column xs={12} md={12} lg={6}>
            <Box className="chart-box" mb={5} mr={1} mt={2} ml={2}>
              <Box className="zonechart-label">
                <TitleView
                  title={t('kpi2.accZones.title', 'acc zones')}
                  prefixIcon={<IconWrapper name="accZones" size="medium" color="primary" />}
                  align="left"
                />
              </Box>

              <Box className="generic-chart">
                <WidgetComponentWithLoading loading={loading}>
                  <GenericZoneChart
                    templateId={templateId}
                    currentDrill={currentDrill}
                    athleteSessionId={athleteSessionId}
                    trackId={trackId}
                    query={ACC_ZONES(trackId ? 'track' : 'session')}
                    // colorMap={greenColors}
                    measurePrefix="acczone"
                    zoneName="accZones"
                    timeEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'acceleration_zones', 'chart_time')}
                    distanceEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'acceleration_zones', 'chart_distance')}
                  />
                </WidgetComponentWithLoading>
              </Box>
            </Box>
          </Column>
        )}
        {elementIsEnabled(layout, 'basic_kpi', 'relative_speed_zones', 'chart') && (
          <Column xs={12} md={12} lg={6}>
            <Box className="chart-box" mb={5} mr={1} mt={2} ml={2}>
              <Box className="zonechart-label">
                <TitleView
                  title={t('teams.labels.relativeSpeedZones', 'relative speed zones')}
                  prefixIcon={<IconWrapper name="speedZones" size="medium" color="primary" />}
                  align="left"
                />
              </Box>
              <Box className="generic-chart">
                <WidgetComponentWithLoading loading={loading}>
                  <GenericZoneChart
                    templateId={templateId}
                    trackId={trackId}
                    athleteSessionId={athleteSessionId}
                    currentDrill={currentDrill}
                    query={REL_SPEED_ZONES(trackId ? 'track' : 'session')}
                    // colorMap={greenColors}
                    measurePrefix="relativespeedzone"
                    zoneName="relativeSpeedZones"
                    timeEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'relative_speed_zones', 'chart_time')}
                    distanceEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'relative_speed_zones', 'chart_distance')}
                  />
                </WidgetComponentWithLoading>
              </Box>
            </Box>
          </Column>
        )}
        {elementIsEnabled(layout, 'basic_kpi', 'deceleration_zones', 'chart') && (
          <Column xs={12} md={12} lg={6}>
            <Box className="chart-box " mb={5} mr={1} mt={2} ml={2}>
              <Box className="zonechart-label">
                <TitleView
                  title={t('kpi2.decZones.title', 'dec zones')}
                  prefixIcon={<IconWrapper name="decZones" size="medium" color="primary" />}
                  align="left"
                />
              </Box>

              <Box className="generic-chart">
                <WidgetComponentWithLoading loading={loading}>
                  <GenericZoneChart
                    templateId={templateId}
                    trackId={trackId}
                    currentDrill={currentDrill}
                    athleteSessionId={athleteSessionId}
                    query={DEC_ZONES(trackId ? 'track' : 'session')}
                    // colorMap={greenColors}
                    measurePrefix="acczone" // @todo fix
                    zoneName="decZones"
                    timeEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'deceleration_zones', 'chart_time')}
                    distanceEnabled={!!elementIsEnabled(layout, 'basic_kpi', 'deceleration_zones', 'chart_distance')}
                  />
                </WidgetComponentWithLoading>
              </Box>
            </Box>
          </Column>
        )}
      </Row>
    </Box>
  );
});

const MetKPI = React.memo((props: PropsIn) => {
  const {
    templateId, athleteSessionId, layout, currentDrill,
    loading, error, data, // trackId,
  } = props;

  const { t } = useTranslation();
  const colors = get2ColorsPalette(colorsPalette);

  if (error) {
    console.log(error);
    return <Error />;
  }

  return (
    <Row className="met-kpi">
      <Row>
        <Column xs={12} md={2} lg={2}>
          {elementIsEnabled(layout, 'met_kpi', 'energy', 'info') && (
            <WidgetComponentWithLoading loading={loading} height={150}>
              <LabelWithMeasure
                text={t('kpi2.met.totalEnergy.label', 'energy')}
                measure={data?.res?.totalEnergy?.value}
                unit={data?.res?.totalEnergy?.unit}
                uom={getUomTranslation(t, data?.res?.totalEnergy?.uom)}
                icon={<IconWrapper name="energy" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={12} md={9} lg={10}>
          <Box className="simple-chart">
            {elementIsEnabled(layout, 'met_kpi', 'energy', 'chart') && (
              <WidgetComponentWithLoading loading={loading}>
                <SimpleHorizontalChart
                  key="distance_chart"
                  dataLeft={data?.res?.totalEnergy?.value - data?.res?.anaerobicEnergy?.value}
                  dataRight={data?.res?.anaerobicEnergy.value}
                  hideLegend
                  uom={getUomTranslation(t, data?.res?.anaerobicEnergy?.uom)}
                  unit={data?.res?.anaerobicEnergy?.unit}
                  title={null}
                  leftIcon={<IconWrapper name="aerobic" size="medium" color={colors[0]} noMargins center disableHover />}
                  leftLabel={t('track.widgets.metKPI.labels.aerobic', 'aerobic')}
                  rightIcon={<IconWrapper name="anaerobic" size="medium" color={colors[1]} noMargins center disableHover />}
                  rightLabel={t('track.widgets.metKPI.labels.anaerobic', 'anaerobic')}
                  centeredZero
                  tooltipLeftKey={`${t('kpi2.met.aerobicEnergy.longLabel', 'aerobic energy')}`}
                  tooltipLeftKeyPerc={t('kpi2.met.aerobicIndex.longLabel', 'aerobic index')}
                  tooltipRightKey={t('kpi2.met.anaerobicEnergy.longLabel', 'anaerobic energy')}
                  tooltipRightKeyPerc={t('kpi2.met.anaerobicIndex.longLabel', 'anaerobic index')}
                />
              </WidgetComponentWithLoading>
            )}
          </Box>
        </Column>
      </Row>

      <Row style={{ marginTop: '40px' }}>
        <Column xs={6} md={3} lg={3} className="vertical-spacing__gauge">
          {elementIsEnabled(layout, 'met_kpi', 'max_values_power', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.met.maxValuesPower.label', 'max met power')}
                measure={data?.res?.maxValuesPower?.value}
                unit={data?.res?.maxValuesPower?.unit}
                uom={getUomTranslation(t, data?.res?.maxValuesPower?.uom)}
                icon={<IconWrapper name="MPMax" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={6} md={3} lg={3}>
          {elementIsEnabled(layout, 'met_kpi', 'average_p', 'chart') && (
            <WidgetComponentWithLoading loading={loading} height={250}>
              <GaugeChart
                data={data?.res?.averageP?.element?.value}
                max={data?.res?.averageP?.upperBound?.value}
                min={data?.res?.averageP?.lowerBound?.value}
                i18nLabel="kpi.average_p"
                uom={getUomTranslation(t, data?.res?.averageP?.element?.uom)}
                unit={data?.res?.averageP?.element?.unit}
                icon={<IconWrapper name="avgMP" size="medium" color="primary" />}
                tooltipKey={t('kpi2.met.averageP.label', 'avg MP')}
                tooltipPercKey={t('athlete.widgets.label.vO2Max', 'VO₂ max')}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={6} md={3} lg={3} className="vertical-spacing__gauge">
          {elementIsEnabled(layout, 'met_kpi', 'equivalent_distance', 'info') && (
            <WidgetComponentWithLoading loading={loading}>
              <LabelWithMeasure
                text={t('kpi2.met.equivalentDistance.longLabel', 'equivalent distance')}
                measure={data?.res?.equivalentDistance?.value}
                unit={data?.res?.equivalentDistance?.unit}
                uom={getUomTranslation(t, data?.res?.equivalentDistance?.uom)}
                icon={<IconWrapper name="equivalentDistance" size="medium" color="primary" />}
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
        <Column xs={6} md={3} lg={3}>
          {elementIsEnabled(layout, 'met_kpi', 'equivalent_distance_index', 'chart') && (
            <WidgetComponentWithLoading loading={loading} height={250}>
              <GaugeChart
                data={data?.res?.equivalentDistanceIndex?.element?.value}
                max={data?.res?.equivalentDistanceIndex?.upperBound?.value}
                min={data?.res?.equivalentDistanceIndex?.lowerBound?.value}
                i18nLabel="kpi.equivalent_distance_index"
                uom={getUomTranslation(t, data?.res?.equivalentDistanceIndex?.element?.uom)}
                unit={data?.res?.equivalentDistanceIndex?.element?.unit}
                icon={<IconWrapper name="EDI" size="medium" color="primary" />}
                tooltipKey={t('kpi2.met.equivalentDistanceIndex.shortLabel', 'EDI')}
                noTooltipTextPerc
              />
            </WidgetComponentWithLoading>
          )}
        </Column>
      </Row>

      {elementIsEnabled(layout, 'met_kpi', 'power_zones', 'chart') && (
        <Row>
          <Column xs={12} md={6} lg={6}>
            <Box className="chart-box" mb={5} mr={1} mt={2} ml={2}>
              <Box className="zonechart-label">
                <TitleView
                  title={t('kpi2.powerZones.title', 'power zones')}
                  prefixIcon={<IconWrapper name="MPZones" size="medium" color="primary" />}
                  align="left"
                />
              </Box>
              <Box className="wrapper">
                <WidgetComponentWithLoading loading={loading} height={250}>
                  <GenericZoneChart
                    templateId={templateId}
                    currentDrill={currentDrill}
                    athleteSessionId={athleteSessionId}
                    query={POWER_ZONES}
                    zoneName="powerZones"
                    measurePrefix="powerZones"
                    // colorMap={greenColors}
                    timeEnabled={!!elementIsEnabled(layout, 'met_kpi', 'power_zones', 'chart_time')}
                    distanceEnabled={!!elementIsEnabled(layout, 'met_kpi', 'power_zones', 'chart_distance')}
                  />
                </WidgetComponentWithLoading>
              </Box>
            </Box>
          </Column>
        </Row>
      )}

      <Row>
        <Column lg={12} lx={8}>
          <OutlinedWrapper disabled style={{ margin: '8px' }} padding="0 28px">
            <>
              <Row>
                <Column xs={12}>
                  <Box className="title">
                    <Row className="mpe-title">
                      <Column xs={6} style={{ marginTop: '2rem' }}>
                        <TitleView
                          title={t('kpi2.metabolicPowerEvents.title', 'metabolic power events')}
                          prefixIcon={<IconWrapper name="MPEvent" size="medium" color="var(--secondary-color)" disableHover />}
                          align="left"
                          color="var(--secondary-color)"
                        />
                      </Column>
                      <WidgetComponentWithLoading loading={loading}>
                        <div style={{ marginTop: '12px' }}>
                          {data?.res?.mpe?.value}
                          {' '}
                        </div>
                      </WidgetComponentWithLoading>
                    </Row>
                  </Box>
                </Column>
              </Row>

              <Row>
                <Column lg={6}>
                  {elementIsEnabled(layout, 'met_kpi', 'metabolic power events', 'chart') && (
                  <Box className="chart--variwide">
                    <WidgetComponentWithLoading loading={loading} height={580}>
                      <VariWideChart
                        xLabel={{ name: t('table.duration', 'duration'), uom: getUomTranslation(t, data?.res?.powerEventsAvgTime?.uom) }}
                        xIcon={<IconWrapper name="time" size="medium" color="primary" />}
                        yLabel={{
                          name: t('kpi2.met.averageP.longLabel', 'average metabolic power'),
                          uom: getUomTranslation(t, data?.res?.powerEventsAvgPower?.uom),
                        }}
                        yIcon={<IconWrapper name="time" size="medium" color="primary" />}
                        name="mpevents"
                        data={[
                          {
                            nameY: t('kpi2.metabolicPowerEvents.powerEventsAvgPower.shortLabel', 'MPE av MP'),
                            nameZ: t('kpi2.metabolicPowerEvents.powerEventsAvgTime.shortLabel', 'MPE av t'),
                            y: data?.res?.powerEventsAvgPower?.value,
                            z: data?.res?.powerEventsAvgTime?.value,
                          },
                          {
                            nameY: t('kpi2.metabolicPowerEvents.recoveryAveragePower.shortLabel', 'MPE rec av MP'),
                            nameZ: t('kpi2.metabolicPowerEvents.recoveryAverageTime.shortLabel', 'MPE rec av t'),
                            y: data?.res?.recoveryAveragePower?.value,
                            z: data?.res?.recoveryAverageTime?.value,
                          },
                        ]}
                      />
                    </WidgetComponentWithLoading>

                    <Box className="custom-bottom-legend">
                      <span>
                        <IconWrapper name="MPEAvgPw" size="medium" color={colors[1]} disableHover />
                        {' '}
                        <p style={{ color: colors[1] }}>{t('tracks.labels.event', 'event')}</p>
                      </span>
                      {' '}
                      <span>
                        <IconWrapper name="recovery" size="medium" color={colors[0]} disableHover />
                        {' '}
                        <p style={{ color: colors[0] }}>{t('tracks.labels.recovery', 'recovery')}</p>
                      </span>
                    </Box>
                  </Box>
                  )}
                </Column>
                <Column lg={6}>
                  <Box className="distance-chart" pb={4}>
                    <WidgetComponentWithLoading loading={loading} height={600}>
                      <DistanceTimes
                        data={data}
                      />
                    </WidgetComponentWithLoading>
                  </Box>
                </Column>
              </Row>
            </>
          </OutlinedWrapper>
        </Column>
      </Row>
    </Row>
  );
});

const HrKPI = React.memo((props: PropsIn) => {
  const {
    templateId, trackId, athleteSessionId, layout, currentDrill,
    loading, error, data,
  } = props;

  const { t } = useTranslation();
  const colors = get2ColorsPalette(colorsPalette);

  if (loading) return <Loader />;
  if (error) {
    console.log(error);
    return <Error />;
  }
  // @todo prendere hrmax e min di default da chiamata a BE
  return (
    <Box className="basic-kpi">
      <Row>

        <Column xs={6} md={3} lg={3}>
          {elementIsEnabled(layout, 'hr_kpi', 'max_values_cardio', 'chart') && (
            <Box className="item">
              <GaugeChart
                data={data?.res?.maxValuesCardio?.value}
                max={data?.res?.athlete?.hrMax?.value || 195}
                min={data?.res?.athlete?.hrMin?.value || 55}
                i18nLabel="kpi.max_values_cardio"
                uom={getUomTranslation(t, data?.res?.maxValuesCardio?.uom)}
                unit={data?.res?.maxValuesCardio?.unit}
                icon={<IconWrapper name="HRMax" size="medium" color="primary" />}
                tooltipKey={t('kpi2.main.maxValuesCardio.shortLabel', 'max HR')}
              />
            </Box>
          )}
        </Column>
        <Column xs={6} md={3} lg={2} className="vertical-spacing__gauge">
          {elementIsEnabled(layout, 'hr_kpi', 'average_hr', 'info') && (
            <LabelWithMeasure
              text={t('kpi2.HRZones.averageHr.label', 'avg HR')}
              isNumAverage
              measure={data?.res?.averageHr?.value}
              unit={data?.res?.averageHr?.unit}
              uom={getUomTranslation(t, data?.res?.averageHr?.uom)}
              icon={<IconWrapper name="HR" size="medium" color="primary" />}
            />
          )}
        </Column>
        <Column xs={6} md={3} lg={2} className="vertical-spacing__gauge">
          {elementIsEnabled(layout, 'hr_kpi', 'average_hr_percentual', 'info') && (
            <LabelWithMeasure
              text={t('kpi2.main.averageHrPercentual.label', 'avg HR %')}
              isNumAverage
              measure={data?.res?.averageHrPercentual?.value}
              unit={data?.res?.averageHrPercentual?.unit}
              uom={getUomTranslation(t, data?.res?.averageHrPercentual?.uom)}
              icon={<IconWrapper name="avgHR" size="medium" color="primary" />}
            />
          )}
        </Column>
        <Column xs={6} md={3} lg={2} className="vertical-spacing__gauge">
          {elementIsEnabled(layout, 'hr_kpi', 'edwards_tl', 'info') && (
            <LabelWithMeasure
              text={t('kpi2.HRZones.edwardsTl.label', 'edwards TL')}
              measure={data?.res?.edwardsTl?.value}
              unit={data?.res?.edwardsTl?.unit}
              uom={getUomTranslation(t, data?.res?.edwardsTl?.uom)}
              icon={<IconWrapper name="HR" size="medium" color="primary" />}
            />
          )}
        </Column>
      </Row>

      <Row>
        <Column xs={12} md={6} lg={6}>
          {elementIsEnabled(layout, 'hr_kpi', 'heart_rate_zones', 'chart') && (
          <Box className="chart-box" mb={5}>
            <Box>
              <TitleView
                title={t('track.widgets.hrKPI.labels.HRZones', 'hr zones')}
                prefixIcon={<IconWrapper name="HRZones" size="medium" color="primary" />}
                align="left"
              />
            </Box>
            <Box className="generic-chart">
              <GenericZoneChart
                templateId={templateId}
                currentDrill={currentDrill}
                athleteSessionId={athleteSessionId}
                query={CARDIO_ZONES}
                // colorMap={greenColors}
                measurePrefix="cardio"
                zoneName="cardioZones"
                timeEnabled={!!elementIsEnabled(layout, 'hr_kpi', 'heart_rate_zones', 'chart_time')}
                distanceEnabled={!!elementIsEnabled(layout, 'hr_kpi', 'heart_rate_zones', 'chart_distance')}
              />
            </Box>
          </Box>
          )}
        </Column>
      </Row>
    </Box>
  );
});

const MechKPI = React.memo((props: PropsIn) => {
  const {
    modelVersion,
    isGk,
    layout,
    loading,
    error,
    data, // trackId
  } = props;
  const { t } = useTranslation();
  const colors = get2ColorsPalette(colorsPalette);

  const highExtWorkNeg = [
    {
      id: 'ext_work_over_zone2_neg',
      props: { key: 'high', value: t('kpi2.mech.extWorkOverZone2Neg.shortLabel', 'high EWᐨ sp') },
      data: [data?.res?.mech?.extWorkOverZone2Neg],
    },
    {
      id: 'ext_work_over_zone1_neg',
      props: { key: 'medium', value: t('kpi2.mech.extWorkOverZone1Neg.shortLabel', 'high EWᐨ P') },
      data: [data?.res?.mech?.extWorkOverZone1Neg],
    },
    {
      id: 'ext_work_over_zone0_neg',
      props: { key: 'low', value: t('kpi2.mech.extWorkOverZone0Neg.shortLabel', 'high EWᐨ F') },
      data: [data?.res?.mech?.extWorkOverZone0Neg],
    },
  ];

  const highExtWorkPos = [
    {
      id: 'ext_work_over_zone2',
      props: { key: 'high', value: t('kpi2.mech.extWorkOverZone2.shortLabel', 'high EWᐩ sp') },
      data: [data?.res?.mech?.extWorkOverZone2],
    },
    {
      id: 'ext_work_over_zone1',
      props: { key: 'medium', value: t('kpi2.mech.extWorkOverZone1.shortLabel', 'high EWᐩ P') },
      data: [data?.res?.mech?.extWorkOverZone1],
    },
    {
      id: 'ext_work_over_zone0',
      props: { key: 'low', value: t('kpi2.mech.extWorkOverZone0.shortLabel', 'high EWᐩ F') },
      data: [data?.res?.mech?.extWorkOverZone0],
    },
  ];

  const burstBrakeYMax = Math.max(...[
    ...data?.res?.mech?.brakeEventsSeries?.map((b) => b?.y?.value),
    ...data?.res?.mech?.burstEventsSeries?.map((b) => b?.y?.value),
  ]);

  if (error) {
    console.log(error);
    return <Error />;
  }

  return (
    <Row className="met-kpi">
      {
        modelVersion === 2
        && (
          <>
            <Row>
              <Column xs={12} md={3} lg={2} className="icon-label-track">
                {elementIsEnabled(layout, 'mec_kpi', 'external_work', 'info') && (
                  <WidgetComponentWithLoading loading={loading} height={150}>
                    <LabelWithMeasure
                      text={t('kpi2.mech.externalWork.label', 'ext work⁺')}
                      measure={data?.res?.mech?.externalWork?.value}
                      unit={data?.res?.mech?.externalWork?.unit}
                      uom={getUomTranslation(t, data?.res?.mech?.externalWork?.uom)}
                      icon={<IconWrapper name="extWork+" size="medium" color="primary" />}
                    />
                  </WidgetComponentWithLoading>
                )}
              </Column>

              <Column xs={6} md={3} lg={3} className={elementIsEnabled(layout, 'mec_kpi', 'average_external_power', 'chart') ? '' : 'hide-column'}>
                {elementIsEnabled(layout, 'mec_kpi', 'average_external_power', 'chart') && (
                  <Box className="item">
                    <WidgetComponentWithLoading loading={loading} height={250}>
                      <GaugeChart
                        data={data?.res?.mech?.averageExternalPower?.element?.value}
                        max={data?.res?.mech?.averageExternalPower?.upperBound?.value}
                        min={data?.res?.mech?.averageExternalPower?.lowerBound?.value}
                        i18nLabel="kpi.average_external_power"
                        uom={getUomTranslation(t, data?.res?.mech?.averageExternalPower?.element?.uom)}
                        unit={data?.res?.mech?.averageExternalPower?.element?.unit}
                        icon={<IconWrapper name="avEP+" size="medium" color="primary" />}
                        tooltipKey={t('kpi2.mech.averageExternalPower.shortLabel', 'av EPᐩ')}
                        noTooltipTextPerc
                      />
                    </WidgetComponentWithLoading>
                  </Box>
                )}
              </Column>

              <Column xs={12} md={3} lg={2} className={elementIsEnabled(layout, 'mec_kpi', 'eccentric_index', 'chart') ? 'icon-label-track' : 'hide-column'}>
                {elementIsEnabled(layout, 'mec_kpi', 'eccentric_index', 'chart') && (
                  <WidgetComponentWithLoading loading={loading} height={250}>
                    <LabelWithMeasure
                      text={t('kpi2.mech.eccentricIndex.shortLabel', 'EI')}
                      measure={data?.res?.mech?.eccentricIndex?.element?.value}
                      unit={data?.res?.mech?.eccentricIndex?.element?.unit}
                      uom={getUomTranslation(t, data?.res?.mech?.eccentricIndex?.element?.uom)}
                      icon={<IconWrapper name="EIPower" size="medium" color="primary" />}
                    />
                  </WidgetComponentWithLoading>
                )}
              </Column>

              {/* DECOMMENT IF YOU WANT TO ADD THE ext_work_over HORIZONTAL CHART
              <Column xs={12} md={9} lg={10}>
                <Box className="simple-chart">
                  {elementIsEnabled(layout, 'mec_kpi', 'ext_work_over', 'chart') && (
                    <WidgetComponentWithLoading loading={loading}>
                      <SimpleHorizontalChart
                        key="distance_chart"
                        dataLeft={data?.res?.mech?.externalWork?.value - data?.res?.mech?.extWorkOver?.value}
                        dataRight={data?.res?.mech?.extWorkOver?.value}
                        hideLegend
                        uom={getUomTranslation(t, data?.res?.mech?.externalWork?.uom)}
                        unit={data?.res?.mech?.externalWork?.unit}
                        title={null}
                        rightIcon={<IconWrapper name="highExternalWork+" size="medium" color="primary" noMargins center />}
                        rightLabel={t('kpi2.mech.extWorkOver.label', 'high ext workᐩ')}
                        centeredZero
                      />
                    </WidgetComponentWithLoading>
                  )}
                </Box>
              </Column> */}
            </Row>

            {
              !isGk
              && (
                <>
                  <Row style={{ marginTop: '-8px', padding: '0px 50px' }}>
                    {elementIsEnabled(layout, 'mec_kpi', 'ext_work_over_zone0', 'chart') && (
                    <Column xs={12} md={5} style={{ marginRight: '5rem' }}>
                      <WidgetComponentWithLoading loading={loading} height={400}>
                        <TrackStackedChart
                          iconName="highExternalWork+"
                          chartLabel={t('kpi2.mech.extWorkOver.longLabel', 'high external workᐩ')}
                          data={{ data: highExtWorkPos }}
                          yMax={Math.max(data?.res?.mech?.extWorkOverNeg?.value, data?.res?.mech?.extWorkOver?.value)}
                          yLabel={t('track.widgets.mecKPI.labels.extWork', 'ext work')}
                          unit={data?.res?.mech?.externalWork?.unit}
                          uom={data?.res?.mech?.externalWork?.uom}
                          hideXLabel
                        />
                      </WidgetComponentWithLoading>
                    </Column>
                    )}

                    <Column xs={12} md={6}>
                      {elementIsEnabled(layout, 'mec_kpi', 'burst_events', 'chart') && (
                      <Box className="chart-widget">
                        <div className="bursts-brakes-title">
                          <div className="label">
                            <IconWrapper name="bursts" size="medium" color="primary" noMargins />
                            <span>{t('kpi2.mech.totBurstEvents.label', 'bursts')}</span>
                          </div>
                          <div className="number">{data?.res?.mech?.burstEvents?.value}</div>
                        </div>
                        <WidgetComponentWithLoading loading={loading} height={400}>
                          <ScatterChart
                            xLabel={getAxisTitleFormat(
                              t('track.widgets.metKPI.labels.startingSpeed', 'starting speed'),
                              getUomTranslation(t, data?.res?.mech?.burstEventsSeries[0]?.x?.uom),
                            )}
                            xIcon={<IconWrapper name="speed" size="medium" color="primary" />}
                            yLabel={getAxisTitleFormat(
                              t('kpi2.main.totalDistance.label', 'distance'),
                              getUomTranslation(t, data?.res?.mech?.burstEventsSeries[0]?.y?.uom),
                            )}
                            yIcon={<IconWrapper name="distance" size="medium" color="primary" />}
                            xMax={data?.res?.athlete?.v0?.value}
                            yMax={burstBrakeYMax}
                            xMin={0}
                            data={
                                [
                                  {
                                    name: 'event_bursts',
                                    data: data?.res?.mech?.burstEventsSeries.map((dot) => ({
                                      x: dot.x.value,
                                      y: dot.y.value,
                                      id: dot.id,
                                      color: eventsColors[2],
                                      tooltip: tooltipFormatter(dot.props.map((p) => ({
                                        key: p.key,
                                        value: p.value.unit === 'time' ? secondsToHHMMSS(p.value.value) : p.value.value,
                                        unit: p.value.unit,
                                        uom: p.value.unit === 'time' ? '' : getUomTranslation(t, p.value.uom),
                                        valueType: 'CustomValueType',
                                      }))),
                                    })),
                                  },
                                ]
                              }
                            plotLines={
                                data?.res?.athlete?.v0?.value ? [
                                  {
                                    color: 'var(--secondary-color)',
                                    width: 1,
                                    value: data?.res?.athlete?.v0?.value / 3,
                                  },
                                  {
                                    color: 'var(--secondary-color)',
                                    width: 1,
                                    value: (2 * data?.res?.athlete?.v0?.value) / 3,
                                  },
                                ] : []
                              }
                          />
                        </WidgetComponentWithLoading>
                      </Box>
                      )}
                    </Column>
                  </Row>
                  <Row style={{ marginTop: '50px', padding: '0px 50px' }}>
                    {elementIsEnabled(layout, 'mec_kpi', 'ext_work_over_zone0_neg', 'chart') && (
                    <Column xs={12} md={5} style={{ marginRight: '5rem' }}>
                      <WidgetComponentWithLoading loading={loading} height={400}>
                        <TrackStackedChart
                          iconName="highExternalWork-"
                          chartLabel={t('kpi2.mech.extWorkOverNeg.longLabel', 'high external workᐨ')}
                          data={{ data: highExtWorkNeg }}
                          yMax={Math.max(data?.res?.mech?.extWorkOverNeg?.value, data?.res?.mech?.extWorkOver?.value)}
                          yLabel={t('track.widgets.mecKPI.labels.extWork', 'ext work')}
                          unit={data?.res?.mech?.externalWork?.unit}
                          uom={data?.res?.mech?.externalWork?.uom}
                          hideXLabel
                        />
                      </WidgetComponentWithLoading>
                    </Column>
                    )}
                    <Column xs={12} md={6}>
                      {elementIsEnabled(layout, 'mec_kpi', 'brake_events', 'chart') && (
                      <Box className="chart-widget">
                        <div className="bursts-brakes-title">
                          <div className="label">
                            <IconWrapper name="brakes" size="medium" color="primary" noMargins />
                            <span>{t('kpi2.mech.totBrakeEvents.label', 'brakes')}</span>
                          </div>
                          <div className="number">{data?.res?.mech?.brakeEvents?.value}</div>
                        </div>
                        <WidgetComponentWithLoading loading={loading} height={400}>
                          <ScatterChart
                            xLabel={getAxisTitleFormat(
                              t('track.widgets.metKPI.labels.startingSpeed', 'starting speed'),
                              getUomTranslation(t, data?.res?.mech?.burstEventsSeries[0]?.x?.uom),
                            )}
                            xIcon={<IconWrapper name="speed" size="medium" color="primary" />}
                            yLabel={getAxisTitleFormat(
                              t('kpi2.main.totalDistance.label', 'distance'),
                              getUomTranslation(t, data?.res?.mech?.burstEventsSeries[0]?.y?.uom),
                            )}
                            yIcon={<IconWrapper name="distance" size="medium" color="primary" />}
                            xMax={data?.res?.athlete?.v0?.value}
                            yMax={burstBrakeYMax}
                            xMin={0}
                            data={[
                              {
                                name: 'events_brakes',
                                data: data?.res?.mech?.brakeEventsSeries.map((dot) => ({
                                  x: dot.x.value,
                                  y: dot.y.value,
                                  id: dot.id,
                                  color: eventsColors[2],
                                  tooltip: tooltipFormatter(dot.props.map((p) => ({
                                    key: p.key,
                                    value: p.value.unit === 'time' ? secondsToHHMMSS(p.value.value) : p.value.value,
                                    uom: p.value.unit === 'time' ? '' : getUomTranslation(t, p.value.uom),
                                    unit: p.value.unit,
                                    valueType: 'CustomValueType',
                                  }))),
                                })),
                              },
                            ]}
                            plotLines={
                              data?.res?.athlete?.v0?.value ? [
                                {
                                  color: 'var(--secondary-color)',
                                  width: 1,
                                  value: data?.res?.athlete?.v0?.value / 3,
                                },
                                {
                                  color: 'var(--secondary-color)',
                                  width: 1,
                                  value: (2 * data?.res?.athlete?.v0?.value) / 3,
                                },
                              ] : []
                            }
                          />
                        </WidgetComponentWithLoading>
                      </Box>
                      )}
                    </Column>
                  </Row>
                </>
              )
            }

          </>
        )
      }
      {
        modelVersion === 1
        && (
          <Row>
            {elementIsEnabled(layout, 'mec_kpi', 'active_muscle_load', 'info') && (
              <Column xs={12} md={3} lg={2} className="vertical-spacing__gauge">
                <Box className="col-span-1 flex flex-col justify-evenly">
                  <WidgetComponentWithLoading loading={loading}>
                    <LabelWithMeasure
                      text={t('kpi2.mech.activeMuscleLoad.longLabel', 'active muscle load')}
                      measure={data?.res?.mech?.activeMuscleLoad?.value}
                      unit={data?.res?.mech?.activeMuscleLoad?.unit}
                      uom={getUomTranslation(t, data?.res?.mech?.activeMuscleLoad?.uom)}
                      icon={<IconWrapper name="activeMuscleLoad" size="medium" color="primary" />}
                    />
                  </WidgetComponentWithLoading>
                </Box>
              </Column>
            )}
            {elementIsEnabled(layout, 'mec_kpi', 'average_active_muscle_power', 'info') && (
              <Column xs={12} md={3} lg={2} className="vertical-spacing__gauge">
                <Box className="col-span-1 flex flex-col justify-evenly">
                  <WidgetComponentWithLoading loading={loading}>
                    <LabelWithMeasure
                      text={t('track.widgets.mecKPI.labels.avgActiveMusclePower', 'avg active muscle power')}
                      measure={data?.res?.mech?.averageActiveMusclePower?.value}
                      unit={data?.res?.mech?.averageActiveMusclePower?.unit}
                      uom={getUomTranslation(t, data?.res?.mech?.averageActiveMusclePower?.uom)}
                      icon={<IconWrapper name="avgActiveMusclePower" size="medium" color="primary" />}
                    />
                  </WidgetComponentWithLoading>
                </Box>
              </Column>
            )}
            {elementIsEnabled(layout, 'mec_kpi', 'eccentric_index', 'chart') && (
              <Column xs={12} md={3} lg={2}>
                <WidgetComponentWithLoading loading={loading} height={250}>
                  <GaugeChart
                    data={data?.res?.mech?.eccentricIndex?.element.value}
                    max={data?.res?.mech?.eccentricIndex?.upperBound.value}
                    min={data?.res?.mech?.eccentricIndex?.lowerBound.value}
                    i18nLabel="kpi.eccentric_index"
                    uom={getUomTranslation(t, data?.res?.mech?.eccentricIndex?.element.uom)}
                    unit={data?.res?.mech?.eccentricIndex?.element.unit}
                    tooltipKey={t('kpi2.mech.eccentricIndex.shortLabel', 'EI')}
                  />
                </WidgetComponentWithLoading>
              </Column>
            )}
          </Row>
        )
      }

      <Row style={{ marginTop: '30px' }}>
        {(elementIsEnabled(layout, 'mec_kpi', 'distance_by_body_orientation', 'chart')
        || elementIsEnabled(layout, 'mec_kpi', 'jumps_by_height', 'chart')
        || elementIsEnabled(layout, 'mec_kpi', 'dives_by_load', 'chart')
        || elementIsEnabled(layout, 'mec_kpi', 'dives_by_return_time', 'chart')) && (
          <Column xs={12}>
            <OutlinedWrapper disabled style={{ margin: '8px' }} padding="28px">
              <Box className="title chart-widget">
                <TitleView
                  title={t('track.widgets.mecKPI.labels.imuKPI', 'IMU KPI')}
                  prefixIcon={<IconWrapper name="IMU" size="medium" color="primary" noMargins />}
                  align="left"
                />
              </Box>
              <Row>
                {elementIsEnabled(layout, 'mec_kpi', 'distance_by_body_orientation', 'chart') && (
                <Column xs={12} md={4} className="imu-kpi-charts chart-distance-by-body-orientation">
                  <WidgetComponentWithLoading loading={loading} height={400}>
                    <DistanceByBodyOrientation
                      data={data}
                    />
                  </WidgetComponentWithLoading>
                </Column>
                )}
                {elementIsEnabled(layout, 'mec_kpi', 'impacts_by_intensity', 'chart') && (
                <Column xs={12} md={4} className="imu-kpi-charts chart-impacts-by-intensity">
                  <WidgetComponentWithLoading loading={loading} height={400}>
                    <ImpactsByIntensity
                      data={data}
                    />
                  </WidgetComponentWithLoading>
                </Column>
                )}
                {elementIsEnabled(layout, 'mec_kpi', 'jumps_by_height', 'chart') && (
                <Column xs={12} md={4} className="imu-kpi-charts chart-jumps-by-height">
                  <WidgetComponentWithLoading loading={loading} height={400}>
                    <JumpsByHeight
                      data={data}
                      jumps={data?.res?.imu?.eventJumpsCount?.value}
                    />
                  </WidgetComponentWithLoading>
                </Column>
                )}
                {elementIsEnabled(layout, 'mec_kpi', 'dives_by_launch_load', 'chart') && (
                <Column xs={12} md={4} className="imu-kpi-charts chart-dives-by-total-load">
                  <WidgetComponentWithLoading loading={loading} height={400}>
                    <DivesByTotalLoad
                      data={data}
                    />
                  </WidgetComponentWithLoading>
                </Column>
                )}
                {elementIsEnabled(layout, 'mec_kpi', 'dives_by_return_time', 'chart') && (
                <Column xs={12} md={4} className="imu-kpi-charts chart-dives-by-return-time">
                  <WidgetComponentWithLoading loading={loading} height={400}>
                    <DivesByReturnTime
                      data={data}
                    />
                  </WidgetComponentWithLoading>
                </Column>
                )}
                {elementIsEnabled(layout, 'mec_kpi', 'dives_by_load', 'chart') && (
                <Column xs={12} md={4} className="imu-kpi-charts chart-dives-by-load">
                  <WidgetComponentWithLoading loading={loading} height={400}>
                    <DivesByLoad
                      data={data}
                    />
                  </WidgetComponentWithLoading>
                </Column>
                )}
                {elementIsEnabled(layout, 'mec_kpi', 'dives_details', 'chart') && (
                <Column xs={12} md={8} className="imu-kpi-charts">
                  <Box className="title chart-widget">
                    <TitleView
                      title={t('tracks.labels.diveDetails', 'dives details by launch load')}
                      prefixIcon={<IconWrapper name="dives" size="medium" color="primary" />}
                      align="left"
                    />
                  </Box>
                  <Box className="distance-chart">
                    <WidgetComponentWithLoading loading={loading} height={400}>
                      <DivesDetails
                        data={data}
                      />
                    </WidgetComponentWithLoading>
                  </Box>
                </Column>
                )}
              </Row>
            </OutlinedWrapper>
          </Column>
        )}
      </Row>
    </Row>

  );
});

export {
  BasicKPI, MetKPI, HrKPI, MechKPI,
};
