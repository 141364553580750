import React, { useState, useEffect } from 'react';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import {
  ApolloClient, ApolloLink, HttpLink, InMemoryCache, useMutation,
} from '@apollo/client';
import { TokenAuthRes, TokenAuthVars, TOKEN_AUTH } from 'mutation/authentication';
import CLIENT_SETTINGS from 'lib/client_settings';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import LogoGpexe from '@images/logo/GpexeWhite';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import { useSnackbar } from 'notistack';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import BaseButton from './components/form/BaseButton';
import { validateEmail } from './components/utils/form';
import { TextField } from './components/form/TextField';
import { randomIntFromInterval } from './components/utils/numbers';
import {
  UpdateUserPasswordRes,
  UpdateUserPasswordVars,
  UPDATE_USER_PASSWORD,
} from './query/user';
import { RESET_USER_PASSWORD, ResetUserPasswordRes, ResetUserPasswordVars } from './mutation/user';
import { APP_STATES, AppState } from './lib/global';

const BoxProperties = {
  mt: 1.5,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export type IJWTToken = JwtPayload & {
  email?: string;
};

const imageStyle = {
  // backgroundImage: `url(${Background})`,
  minHeight: '100vh',
  minWidth: '1024px',

  /* Set up proportionate scaling */
  width: '100%',
  height: 'auto',

  /* Set up positioning */
  position: 'fixed',
  top: 0,
  left: 0,
} as React.CSSProperties;

export const getTokenDataFromLocalStorage = () => {
  const jwtToken = localStorage.getItem('exelio_token')
    && jwt_decode<IJWTToken>(localStorage.getItem('exelio_token'));
  return jwtToken;
};

export const resetTranslationsFallback = (key: string) => {
  const blockedArr = key.split('-');
  if (blockedArr[0] === 'blockedFor') {
    const timeLeft = parseInt(blockedArr[1], 10);
    return `Too many attempts. Try again in ${timeLeft} minutes`;
  }
  const translations = {
    almostReady: 'Too many attempts. Try again in less than a minute',
    MinimumLength: 'Password is too short',
    UserAttributeSimilarity: 'Password is too similar to other user attributes',
    tokenError: 'This form is no longer available. Request the password reset one more time.',
    userInactive: 'This user is inactive!',
    unknownError: 'An error occurred while sending email',
    invalidForm: 'Form invalid',
  };

  if (Object.keys(translations).includes(key)) {
    return translations[key];
  }

  return key;
};

export default function Login() {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [section, setSection] = useState<'login' | 'recover' | 'reset'>(window.location.pathname.match(/^\/reset/) ? 'reset' : 'login');
  const [username, setUsername] = useState('');
  const [dialogMessage, setDialogMessage] = useState<false | string>(false);
  const [recoverUsername, setRecoverUsername] = useState('');
  const [resetPassword1, setResetPassword1] = useState('');
  const [resetPassword2, setResetPassword2] = useState('');
  const [password, setPassword] = useState('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<{[k: string]: string}>({});
  const [bgImage, setBgImage] = useState<any>(null);
  const notificationRef = React.useRef<any>();

  useEffect(() => {
    /* USED TO AUTOMATICALLY AUTHENTICATE USER FROM OLD UI */
    // Retrieve the jwt_token from cookies and set it in localStorage
    const jwtToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('jwt_token='))
      ?.split('=')[1];

    if (jwtToken) {
      localStorage.setItem('exelio_token', jwtToken);
      const refreshJwtToken = localStorage.getItem('exelio_refresh_token');
      setTimeout(() => {
        // Delete the cookie
        document.cookie = 'jwt_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        // temporarily solution, then the entire code block will be removed;
        if (refreshJwtToken) {
          window.location.replace('/');
        }
      }, 250);
    }
  }, []);

  useEffect(() => {
    import(/* webpackChunkName: "bg-slideshow-image" */ `./img/slideshow/login_00${randomIntFromInterval(1, 8)}.webp`).then((module) => {
      setBgImage(module.default);
    });
  }, []);

  const [updateUserPassword] = useMutation<UpdateUserPasswordRes, UpdateUserPasswordVars>(UPDATE_USER_PASSWORD, {
    onError: (error) => {
      console.error(error);
      setIsSending(false);
      enqueueSnackbar(
        t('login.resetPassword.error', 'Error resetting your password, please try later.'),
        {
          variant: 'error',
          autoHideDuration: 5000,
        },
      );
    },
    onCompleted: (data) => {
      if (data.updateUserPassword.success) {
        enqueueSnackbar(
          t('user.resetPassword.resetSuccessful', 'Password created, please now login with your new credentials'),
          {
            variant: 'success',
            autoHideDuration: 5000,
          },
        );
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      } else {
        setIsSending(false);
        enqueueSnackbar(
          <div>
            <h3>{t('login.resetPassword.errors', 'Error updating password')}</h3>
            <br />
            <ul>
              {data.updateUserPassword.messageKeys.map((errorKey) => (
                <li>
                  {' '}
                  -
                  {' '}
                  {t(`user.resetPassword.errorMessage.${errorKey}`, resetTranslationsFallback(errorKey))}
                </li>
              ))}
            </ul>
          </div>,
          {
            variant: 'error',
            autoHideDuration: 5000,
            title: 'Error',
          },
        );
      }
    },
  });

  const [requestResetUserPasswordMut] = useMutation<ResetUserPasswordRes, ResetUserPasswordVars>(RESET_USER_PASSWORD, {
    onError: (error) => {
      console.error(error);
      setIsSending(false);
      enqueueSnackbar(
        t('login.resetPassword.error', 'Error resetting your password, please try later.'),
        {
          variant: 'error',
          autoHideDuration: 5000,
        },
      );
    },
    onCompleted: (data) => {
      setIsSending(false);
      if (data.requestResetUserPassword.success) {
        enqueueSnackbar(
          t('login.recoverPassword.emailSent', 'Request sent! Check your inbox.'),
          {
            variant: 'success',
            autoHideDuration: 5000,
          },
        );
      } else {
        enqueueSnackbar(
          <div>
            <h3>{t('login.requestResetPassword.errors', 'Error requesting password reset')}</h3>
            <br />
            <ul>
              {data.requestResetUserPassword.messageKeys.map((errorKey) => (
                <li>
                  {' '}
                  -
                  {' '}
                  {t(`user.resetPassword.errorMessage.${errorKey}`, resetTranslationsFallback(errorKey))}
                </li>
              ))}
            </ul>
          </div>,
          {
            variant: 'error',
            autoHideDuration: 5000,
            title: 'Error',
          },
        );
      }
    },
  });

  const login = async () => {
    setIsSending(true);

    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        onError(({
          graphQLErrors, networkError, operation, forward,
        }) => {
          if (notificationRef.current) {
            closeSnackbar(notificationRef.current);
          }

          console.log('ERRRR', graphQLErrors, networkError);
          if (networkError) {
            const context = operation.getContext();
            console.error(`[Network error]: ${networkError}`, context.response?.status || 'no response');
            if (context.response?.status === 503) {
              AppState.setValue(APP_STATES.maintenance);
            }

            // tento retry su problemi di rete
            console.log('Retry on Network Error...');

            enqueueSnackbar(
              t('login.errors.networkError', 'Network error, please try again in a few minutes'),
              {
                variant: 'error',
                autoHideDuration: 5000,
              },
            );

            return forward(operation);
          }

          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => console.error(
              `[GraphQL error]: Message: ${message}, Path: ${path}`,
              locations,
              message,
            ));

            // tento retry su problemi di GraphQL
            console.log('Retry on GraphQL Error...');

            if (graphQLErrors[0].message === 'Please enter valid credentials') {
              enqueueSnackbar(
                t('login.errors.invalidCredentials', 'Invalid credentials. Please try again.'),
                {
                  variant: 'error',
                  autoHideDuration: 5000,
                },
              );
            } else {
              enqueueSnackbar(
                t('login.errors.graphqlError', 'Error, please try again in a few minutes'),
                {
                  variant: 'error',
                  autoHideDuration: 5000,
                },
              );
            }

            return forward(operation);
          }
        }),
        new RetryLink({
          delay: (count) => count * 1000 * Math.random(),
          attempts: {
            max: 5,
            retryIf: (error) => {
              const validToRetry = error.statusCode !== 400;

              if (validToRetry && !notificationRef.current) {
                notificationRef.current = enqueueSnackbar(
                  t('login.errors.retryWarning', 'Please wait...'),
                  {
                    variant: 'info',
                    persist: true,
                  },
                );
              }

              return validToRetry;
            },
          },
        }),
        new HttpLink({
          uri: CLIENT_SETTINGS.public.gpexeGraphUrl,
        }),
      ]),
    });

    const { data, errors } = await client.mutate<TokenAuthRes, TokenAuthVars>({
      mutation: TOKEN_AUTH,
      variables: {
        email: username,
        password,
      },
      errorPolicy: 'all',
    });

    if (errors) {
      setDialogMessage(t('login.dialog.errorTitle', 'Please enter valid credentials'));
      setIsSending(false);
      return;
    }

    if (data) {
      if (!data.res.isActive) {
        setDialogMessage('This account is inactive');
        setIsSending(false);
        return;
      }
      await localStorage.setItem('exelio_token', data.res.token);
      await localStorage.setItem('exelio_refresh_token', data.res.refreshToken);
      await localStorage.setItem('exelio_refresh_token_exp', JSON.stringify(data.res.refreshExpiresIn));
    }

    window.location.replace('/');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const requestResetPassword = async () => {
    setIsSending(true);
    requestResetUserPasswordMut({
      variables: {
        email: recoverUsername,
      },
    });
  };

  const resetUserPassword = async () => {
    const urlParts = section === 'reset' && window.location.pathname.match(/^\/reset\/(.*)/);
    let resetUrl = urlParts ? urlParts[1] : undefined;

    if (resetUrl && resetUrl[resetUrl.length - 1] === '/') {
      resetUrl = resetUrl.substring(0, resetUrl?.length - 1);
    }

    if (resetUrl) {
      setIsSending(true);
      await updateUserPassword({
        variables: {
          password: resetPassword1,
          resetUrl,
        },
      });
    }
  };
  const handleChangeSection = (sect) => {
    setUsername('');
    setRecoverUsername('');
    setPassword('');
    setSection(sect);
  };

  const loginForm = () => {
    const submitDisabled = isSending
      || username === ''
      || password === ''
      || !validateEmail(username);

    return (
      <>
        <Box mt={3} width="100%" onKeyDown={!submitDisabled ? handleKeyPress : undefined}>
          { !!dialogMessage
          && <Typography className="login-error" variant="subtitle1">{dialogMessage}</Typography>}
          <TextField
            fullWidth
            onChange={(e) => setUsername(e.target.value)}
            placeholder="email"
            type="email"
            required
            startAdornment={<EmailRoundedIcon />}
            error={!!invalidFields.email}
            value={username}
          />
        </Box>
        <Box mt={1.5} width="100%" onKeyDown={!submitDisabled ? handleKeyPress : undefined}>
          <TextField
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
            type="password"
            required
            startAdornment={<LockRoundedIcon />}
            error={!!invalidFields.password}
          />
        </Box>

        <Box {...BoxProperties}>
          <a href="#" onClick={() => handleChangeSection('recover')} className="login-form__links">
            {t('login.lostPassword', 'lost your password?')}
          </a>
          <BaseButton
            onClick={login}
            color="primary"
            isValidation={!submitDisabled}
            disabled={submitDisabled}
          >
            {t('login.login', 'login')}
          </BaseButton>
        </Box>
      </>
    );
  };

  const recoverForm = () => {
    const submitDisabled = isSending
      || recoverUsername === ''
      || !validateEmail(recoverUsername);

    return (
      <FormControl error={!!invalidFields.recoverEmail}>
        <Box mt={5} width="100%">
          <Box className="recover-form__intro">
            {t('login.recoverPassword.introText', 'enter your email and, if an account with this email exists, we will send you an email with the recovery link.')}
          </Box>
          <TextField
            fullWidth
            onChange={(e) => setRecoverUsername(e.target.value)}
            placeholder="email"
            type="email"
            required
            startAdornment={<EmailRoundedIcon />}
            error={!!invalidFields.recoverEmail}
            value={recoverUsername}
          />
          <FormHelperText>{invalidFields.recoverEmail}</FormHelperText>
        </Box>
        <Box {...BoxProperties}>
          <a href="/" onClick={() => handleChangeSection('login')} className="login-form__links">
            {t('login.goToLogin', 'go to login')}
          </a>
          <BaseButton
            onClick={requestResetPassword}
            color="primary"
            isValidation={!submitDisabled}
            disabled={submitDisabled}
          >
            {t('login.recover', 'recover')}
          </BaseButton>
        </Box>
      </FormControl>
    );
  };

  const resetForm = () => {
    const submitDisabled = isSending
      || resetPassword1 === ''
      || resetPassword2 === ''
      || (resetPassword1 !== resetPassword2);

    React.useEffect(() => {
      const passwordsAreDifferent = resetPassword1 && resetPassword1 !== ''
        && (
          resetPassword2 === '' || resetPassword2 !== resetPassword1
        );

      setInvalidFields((fields) => ({
        ...fields,
        ...{
          resetPassword1: resetPassword1.length < 8 ? t('login.resetPassword.lessThanMin', 'At least 8 characters') : '',
          resetPassword2: passwordsAreDifferent ? t('login.resetPassword.notEqual', 'Passwords are different') : '',
        },
      }));
    }, [resetPassword1, resetPassword2]);

    return (
      <FormControl error={!!invalidFields.recoverEmail}>
        <Box mt={5} width="100%">
          <Box className="recover-form__intro">
            {t('login.resetPassword.introText', 'Please enter your new password.')}
          </Box>
          <TextField
            fullWidth
            onChange={(e) => setResetPassword1(e.target.value)}
            placeholder="new password"
            type="password"
            required
            error={!!invalidFields.resetPassword1}
          />
          <FormHelperText>{invalidFields.resetPassword1}</FormHelperText>
          <TextField
            fullWidth
            onChange={(e) => setResetPassword2(e.target.value)}
            placeholder="repeat password"
            type="password"
            required
            error={!!invalidFields.resetPassword2}
          />
          <FormHelperText>{invalidFields.resetPassword2}</FormHelperText>
        </Box>
        <Box {...BoxProperties}>
          <a href="/" onClick={() => handleChangeSection('login')} className="login-form__links">
            {t('login.goToLogin', 'go to login')}
          </a>
          <BaseButton
            onClick={resetUserPassword}
            color="primary"
            isValidation={!submitDisabled}
            disabled={submitDisabled}
          >
            {t('login.activate', 'activate')}
          </BaseButton>
        </Box>
      </FormControl>
    );
  };

  return (
    <Box className="login-form" display="flex" flexDirection="row" justifyContent="center">
      <img style={imageStyle} src={bgImage} alt="background" />
      <Box className="login__wrapper" display="flex" flexDirection="column" alignItems="start" py={3} px={4}>
        <LogoGpexe />

        {section === 'login' && loginForm()}
        {section === 'recover' && recoverForm()}
        {section === 'reset' && resetForm()}
      </Box>
      {/* <DialogBox
        title={t('login.dialog.errorTitle', 'Please enter valid credentials')}
        content={dialogMessage || ''}
        visible={!!dialogMessage}
        buttonAction={t('login.buttonOk', 'Ok')}
        onClose={() => setDialogMessage(false)}
        onClick={() => setDialogMessage(false)}
      /> */}
    </Box>
  );
}
