export const WINDOW_WIDTH = 1120;
export const WINDOW_HEIGHT = 600;

export const BASKET_CENTER_RADIUS = 1.6;
export const BASKET_AREA_RADIUS = 6.25;
export const BASKET_NO_CHARGING_AREA_RADIUS = 1;
export const BASKET_FREE_TROW_AREA = 5.8;
export const BASKET_AREA_OFFSET = 1.575;
export const BASKET_RADIUS = 0.225;

export const FOOTBALL_CENTER_RADIUS = 9.15;
export const FOOTBALL_GOAL_AREA_WIDTH = 16.5;
export const FOOTBALL_GOAL_AREA_HEIGHT = 40.3;
export const FOOTBALL_GOAL_AREA_WIDTH_SMALL = 5.5;
export const FOOTBALL_GOAL_AREA_HEIGHT_SMALL = 18.3;
export const FOOTBALL_DISK_OFFSET = 11;
export const FOOTBALL_DISK_RADIUS = 0.2;

export const FUTSAL_CENTER_RADIUS = 3;
export const FUTSAL_AREA_WIDTH = 6;
export const FUTSAL_DISK_OFFSET = 10;
export const FUTSAL_DISK_RADIUS = 0.15;
export const FUTSAL_GOAL_HEIGTH = 3;

export const HANDBALL_AREA_WIDTH = 6;
export const HANDBALL_9 = 9;
export const HANDBALL_7 = 7;
export const HANDBALL_4 = 4;
export const HANDBALL_7_LENGHT = 1.5;
export const HANDBALL_4_LENGHT = 0.7;
export const HANDBALL_GOAL_HEIGTH = 3;

export const RUGBY_5 = 5;
export const RUGBY_10 = 10;
export const RUGBY_15 = 15;
export const RUGBY_22 = 22;
export const RUGBY_DASH = 5;
export const RUGBY_MINIMUM_WIDTH = 94;

export const ICE_HOCKEY_CENTER_RADIUS = 4.5;
export const ICE_HOCKEY_DISK_RADIUS = 0.3;
export const ICE_HOCKEY_CORNER_RADIUS = 8.5;
export const ICE_HOCKEY_SCOREKEEPER_AREA_RADIUS = 3;
export const ICE_HOCKEY_GOAL_AREA_RADIUS = 1.83;
export const ICE_HOCKEY_GOAL_LINE = 4;
export const ICE_HOCKEY_FIRST_DISK_X_OFFSET = 10.7;
export const ICE_HOCKEY_SECOND_DISK_X_OFFSET = 7.33;
export const ICE_HOCKEY_DISK_Y_OFFSET = 6.7;
export const ICE_HOCKEY_LINE_OFFSET = 8.83;
export const ICE_HOCKEY_MARK_SPACING = 0.45;
export const ICE_HOCKEY_MARK_LENGHT = 0.6;

export const MOUSE_SCALE_FACTOR = 1.02;
export const BUTTON_SCALE_FACTOR = 1.08;

export const PATH_WIDTH = 1;
export const PATH_WIDTH_HOVER = 3;
export const PATH_COLOR = 'black';
export const DEFAULT_GROUND_COLOR = '#00ddb5';
export const DEFAULT_PITCH_STROKEWIDTH = 2.5;
export const DEFAULT_PITCH_LINE_COLOR = 'white';

export const LEFT_OFFSET = 25;
export const RIGHT_OFFSET = 5;
export const TOP_OFFSET = 15;
export const BOTTOM_OFFSET = 5;

export const PLAYER_MARKER_COLOR = 'red';
