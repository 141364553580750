/* eslint-disable react/jsx-closing-bracket-location */
import {
  Box,
} from '@material-ui/core';
import { Row } from 'components/layout/Row';
import React, {
  useState, useMemo, useEffect, ChangeEvent, useRef, useCallback,
} from 'react';
import { Column } from 'components/layout/Column';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { DEFAULT_GROUND_COLOR, PLAYER_MARKER_COLOR } from 'components/track/ground/constants';
import { CLUB, ClubTypeRes, ClubTypeVars } from 'query/club';
import { GroundType, getPairedGroundColor } from 'models/ground';
import { useLazyQueryCached, useQueryCached } from '../utils/graphql';
import { TeamSessionsType } from '../../models/team_session';
import { TeamSessionsVars, SESSION, TEAMSESSION_ATHLETES_SESSIONS } from '../../query/session';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import { rotate, flipPoint, computeAngle } from '../track/ground/utils';
import { CursorContext } from '../track/TrackChartContainer';
import { Maybe, TeamSessionType } from '../../__generated__/graphql';
import PlayerControls, { MarkDrill } from './PlayerControls';
import PlayersTable, { PlayersDetails } from './PlayersTable';
import ErrorGuard from '../layout/ErrorGuard';
import WebPlayerHeader from './WebPlayerHeader';
import { LPSFromGPSPath } from '../../lib/geo';
import Ground from './Ground';

const timeFormat = 'H:mm:ss';
const valueLabelFormat = ( value: number ) => format(value, timeFormat);

export const STATUS_TEXT = {
  DATA_SESSION_LOADED: 'data: session loaded, computing...',
  INIT: 'starting...',
  PLAYERS_LOADED: 'players: loaded, computing...',
  PLAYERS_LOADING: 'players: loading...',
  PLAYERS_READY: 'players: ready!',
  SET_DRILLS: 'set: drills',
  SET_START_TIME: 'set: start time',
};

interface WebPlayerProps {
  currentDrill: number | null;
  defaultGround?: boolean;
  sessionGround?: GroundType
  sessionId: string;
  showVertices?: boolean;
  teamId: string
  templateId: string;
}

export interface SeriesState {
  [k: string]: {
    pathData: {
      x: number | null,
      x_0?: number | null,
      y_0?: number | null,
      timestamp: number
    }[]
  };
}

export type DrillTimesType = { start: number; end: number; }[]
export type PlayersColors = Record<string, string>

const WebPlayer = ( props: WebPlayerProps ) => {
  const {
    currentDrill,
    defaultGround,
    sessionGround,
    sessionId,
    showVertices,
    templateId,
    teamId,
  } = props;

  const [currentActivePlayer, setCurrentActivePlayer] = useState<null | string>(null);
  const [currentHoverPlayer, setCurrentHoverPlayer] = useState<null | string>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [enabledPlayers, setEnabledPlayers] = useState<Array<string>>([]);
  const [loadingPlayers, setLoadingPlayers] = useState<Array<string>>([]);
  const [jerseyOrNumber, setJerseyOrNumber] = useState(true);
  const [marks, setMarks] = useState<MarkDrill[]>([]);
  const [playerSpeed, setPlayerSpeed] = useState(40); // ms tra i frame (1/25)
  const [playerStatus, setPlayerStatus] = useState<0 | 1>(0);
  const [playersColors, setPlayersColors] = useState<Record<string, string>>({});
  const [series, setSeries] = useState<SeriesState>({});
  const [status, setStatus] = useState(STATUS_TEXT.INIT);
  const [trailsEnabled, setTrailsEnabled] = useState(false);
  const [ground, setGround] = useState<GroundType | undefined>(sessionGround);
  const [workerIsReady, setWorkerIsReady] = useState(false)

  const currTime = React.useRef<ReturnType<typeof setInterval> | null>(null);
  const playerRef = React.useRef <null | HTMLDivElement>(null);
  const currDrillRef = useRef(1);
  const workerCheckInterval = useRef<any>(null);
  const workerCheckActive = useRef(false);
  const workerRef = useRef<any>(null);
  useEffect(() => {
    workerRef.current = new Worker(new URL('../../workers/webplayer.ts', import.meta.url));

    return () => {
      if (workerRef.current) {
        console.log('%cTerminating webplayer worker', 'color: orange');
        workerRef.current.terminate();
      }
    }
  }, []);


  // const WINDOW_WIDTH = ref?.current?.offsetWidth || 1;
  // const WINDOW_HEIGHT = ref?.current?.offsetHeight || (WINDOW_WIDTH * 9 / 16);

  const cursorValue = useMemo(() => ({
    cursor: currentTime,
    setCursor: setCurrentTime,
  }), [currentTime]);

  const intervalRef = React.useRef<ReturnType<typeof setInterval> | null>(null);
  const {
    // error,
    // loading,
    data,
  } = useQueryCached<{ res: TeamSessionType }, TeamSessionsVars>(SESSION, {
    variables: {
      drill: currentDrill || undefined,
      id: parseInt(sessionId, 10),
      templateId: parseInt(templateId, 10),
      withAthleteSession: true,
      withGround: true,
    },
    ...CACHE_AND_NETWORK,
    // returnPartialData: true,
    onCompleted: ( result ) => {
      const tracksGround = result?.res?.athleteSessions && result.res.athleteSessions.length > 0
        && result.res.athleteSessions[0] && result.res.athleteSessions[0].track?.ground;

      if (result?.res?.startTimestamp) {
        const timezoneOffset = -new Date(result.res.startTimestamp).getTimezoneOffset() * 60000;
        setCurrentTime((new Date(result.res.startTimestamp).getTime() + timezoneOffset));
        setStatus(STATUS_TEXT.SET_START_TIME);
      }

      if (result?.res?.drills?.relatedDrills) {
        const drillsMarks: {
          value: number,
          label: string,
          index?: Maybe<number>,
          id?: Maybe<number>,
          tags?: Maybe<string>[],
        }[] = [];
        setStatus(STATUS_TEXT.DATA_SESSION_LOADED);
        result.res.drills.relatedDrills.forEach(( drill ) => {
          const timezoneOffset = drill?.start
            ? -new Date(drill.start).getTimezoneOffset() * 60000
            : 0;

          drillsMarks.push({
            value: drill ? (new Date(drill.start)).getTime() + timezoneOffset : 0,
            label: drill ? `${format(parseISO(drill.start), timeFormat)}` : '---',
            index: drill?.index || undefined,
            id: drill?.id || undefined,
            tags: drill?.tags || undefined,
          });

          drillsMarks.push({
            value: drill ? (new Date(drill.end)).getTime() + timezoneOffset : 0,
            label: drill ? `${format(parseISO(drill.end), timeFormat)}` : '---',
            index: drill?.index || undefined,
            id: drill?.id || undefined,
            tags: drill?.tags || undefined,
          });
        });

        setMarks(drillsMarks);
        setStatus(STATUS_TEXT.SET_DRILLS);
      }

      if (tracksGround) {
        setGround(tracksGround);
      }

      setStatus(STATUS_TEXT.PLAYERS_LOADING);
      // eslint-disable-next-line no-use-before-define
      loadSessions();
    },
  });

  // @todo usare webworker per non bloccare il main thread
  const [loadSessions, {
    // error: detailsError,
    data: detailsData,
    // loading: detailsLoading,
  }] = useLazyQueryCached<{ res: TeamSessionsType }, TeamSessionsVars>(TEAMSESSION_ATHLETES_SESSIONS, {
    variables: {
      drill: currentDrill || undefined,
      fieldsLimit: 6,
      id: parseInt(sessionId, 10),
      templateId: parseInt(templateId, 10),
    },
    fetchPolicy: 'cache-first',
    onCompleted: () => {
      setStatus(STATUS_TEXT.PLAYERS_READY);
    },
    onError: ( error ) => {
      console.error('Error fetching athletes', error);
    },
  });

  const isGPSGround = ground?.groundCoordsType !== 'LOCAL';

  const currentGround = !isGPSGround
    ? ground
    : ground || sessionGround;


  const loadAthSessionData = useCallback(async () => {
    if (!detailsData?.res?.athleteSessions || !currentGround) {
      return;
    }

    const athSess = detailsData.res.athleteSessions;

    setStatus(STATUS_TEXT.PLAYERS_LOADED);

    if (athSess.length > 0) {
      let newLoadingPlayers = athSess.map(( athS ) => athS.id);
      setLoadingPlayers(newLoadingPlayers);

      const jwtToken = (localStorage.getItem('exelio_token')) || undefined;

      workerRef.current.postMessage({
        action: 'loadMultiplePathData',
        athleteSessionIds: athSess.map(( a ) => a.id),
        drill: currentDrill,
        jwtToken
      })
    }

    setStatus(STATUS_TEXT.PLAYERS_READY);
  }, [detailsData?.res?.athleteSessions, currentDrill, currentGround]);

  const groundDetails = data?.res?.athleteSessions && data.res.athleteSessions.length > 0
    && data.res.athleteSessions[0] && data.res.athleteSessions[0].track?.ground;

  useEffect(() => {
    workerRef.current.onmessage = ( e: MessageEvent<{
      type: 'loadedPathData' | 'ready',
      serie: {
        id: string,
        drill: number | null,
        data: any
      }
    }> ) => {
      // il worker risponde, è pronto
      if (e.data?.type === 'ready') {
        console.log('%c - webplayerWorker ✔', 'color: green');
        workerCheckActive.current = true;
        clearInterval(workerCheckInterval.current);

        setWorkerIsReady(true);
      } else {
        const {
          id,
          drill, // @todo verificare se serve in visualizzazione
          data: d,
        } = e.data.serie;

        let tmpPath: {
          timestamp: number,
          x: number | null,
          x_0?: number | null,
          y: number | null,
          y_0?: number | null,
        }[] = [];

        if (d.res) {
          if (d.res.path?.data) {
            if (ground?.groundCoordsType === 'LOCAL') { // è un ground LPS
              d.res.path.data.forEach(( p: [number, number, number] ) => {
                const point = {
                  x: p[1],
                  y: p[2],
                };

                const newPoint = !p[1] || !p[2]
                  ? {
                    x: null,
                    y: null,
                  }
                  : groundDetails
                    ? defaultGround
                      ? point
                      : rotate(
                        flipPoint(point, groundDetails),
                        computeAngle(groundDetails),
                      )
                    : point;
                tmpPath.push({
                  timestamp: Math.trunc(p[0] / 100) * 100,
                  x: newPoint.x,
                  y: newPoint.y,
                });
              });
            } else if (currentGround) {
              try {
                tmpPath = LPSFromGPSPath(
                  d.res.path.data.map(( p ) => ({
                    timestamp: Math.trunc(p[0] / 100) * 100,
                    x: p[1],
                    y: p[2],
                  })),
                  {latitude: currentGround?.vertexALatitude, longitude: currentGround?.vertexALongitude},
                  {latitude: currentGround?.vertexBLatitude, longitude: currentGround?.vertexBLongitude},
                  {latitude: currentGround?.vertexCLatitude, longitude: currentGround?.vertexCLongitude},
                );
              } catch (e) {
                console.log('Cannot compute LPS from GPS path', e, currentGround);
              }
            }

            const pathData = tmpPath.map(( p ) => ({
              timestamp: p.timestamp,
              x: p.x,
              x_0: p.x_0,
              y: p.y,
              y_0: p.y_0,
            }));

            setSeries(( prevState ) => ({
              ...prevState,
              [String(id)]: {pathData}
            }));

            setEnabledPlayers(( prevState ) => {
              const newEnabledPlayers = [...prevState];

              if (!newEnabledPlayers.includes(id)) {
                newEnabledPlayers.push(id);
              }

              return newEnabledPlayers;
            });

            setLoadingPlayers(( prevState ) => {
              const newLoadingPlayers = [...prevState];
              newLoadingPlayers.splice(newLoadingPlayers.indexOf(String(id)), 1)
              return newLoadingPlayers;
            });
          } else {
            console.log('No path data', d);
            setLoadingPlayers(( prevState ) => {
              const newLoadingPlayers = [...prevState];
              newLoadingPlayers.splice(newLoadingPlayers.indexOf(String(id)), 1)
              return newLoadingPlayers;
            });
          }
        } else {
          setLoadingPlayers(( prevState ) => {
            const newLoadingPlayers = [...prevState];
            newLoadingPlayers.splice(newLoadingPlayers.indexOf(String(id)), 1)
            return newLoadingPlayers;
          });
        }
      }
    }
  }, [data?.res?.athleteSessions, defaultGround, currentGround, ground?.groundCoordsType]);

  // avvio controllo per attesa worker pronto
  useEffect(() => {
    console.log('Requesting worker init')
    function startCheck(){
      workerCheckInterval.current = setInterval(() => {
        if (!workerCheckActive.current) {
          workerRef.current.postMessage({
            action: 'requireInit',
          })
        } else {
          clearInterval(workerCheckInterval.current)
        }
      }, 500)
    }

    if (!workerIsReady) {
      startCheck();
    } else {
      clearInterval(workerCheckInterval.current)
      workerCheckActive.current = true;
    }

    return () => clearInterval(workerCheckInterval.current)
  }, [workerIsReady]);

  // worker pronto, dati necessari anche, carico le serie
  useEffect(() => {
    if (detailsData && workerIsReady && (currentGround || groundDetails || defaultGround)) {
      loadAthSessionData();
    }
  }, [detailsData, ground, currentDrill, workerIsReady, groundDetails, defaultGround, currentGround]);

  const {
    data: dataGrounds,
  } = useQueryCached<ClubTypeRes, ClubTypeVars>(CLUB, {
    variables: {
      id: teamId,
    },
    ...CACHE_AND_NETWORK,
  });

  const groundSet = dataGrounds?.res?.club?.groundSet || [];

  const groundOptions = useMemo<Partial<GroundType>[]>(() => groundSet?.map(( g ) => ({
    id: String(g.id),
    value: String(g.name),
  })), [groundSet]);

  useEffect(() => {
    if (
      !ground
      && groundOptions.length > 0
      && groundSet.length > 0
      && detailsData
      && data
    ) {
      const selectedGround = groundSet.find(( g ) => g.id === groundOptions[0].id);

      if (selectedGround) {
        setGround(selectedGround);
      }
    }
  }, [groundOptions, ground, groundSet, detailsData, data])

  const handleChange = ( _: ChangeEvent<{}>, time: number | number[] ) => {
    if (typeof time === 'number') {
      setCurrentTime(time);
    }
  };

  const goToNextDrill = () => {
    const nextDrill = marks.filter(( m ) => m.index === currDrillRef.current + 1);
    if (nextDrill.length) {
      setCurrentTime(nextDrill[0].value);
      currDrillRef.current += 1;
    }
  };

  const drillTimes: DrillTimesType = useMemo(() => {
    const times = {};

    marks.forEach(( drill ) => {
      const {index, value} = drill;
      if (!times[index]) {
        times[index] = {start: value, end: value};
      } else {
        times[index].start = Math.min(times[index].start, value);
        times[index].end = Math.max(times[index].end, value);
      }
    });

    return Object.values(times);
  }, [marks]);

  const stopIntervals = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = null;
    currTime.current = null;
  };

  const handlePlayClick = ( forcePlay = false ) => {
    if (forcePlay || playerStatus === 0 && !intervalRef.current) {
      // @ts-ignore
      currTime.current = new Date().getTime();
      intervalRef.current = setInterval(() => {
        setCurrentTime(( prevState ) => {
          const isInAnyDrill = drillTimes.some(( {start, end} ) => prevState >= start - 1000 && prevState <= end);
          if (prevState > 0 && !isInAnyDrill) {
            const nextDrill = marks.filter(( m ) => m.index === currDrillRef.current + 1);
            if (nextDrill.length) {
              currDrillRef.current += 1;
              return nextDrill[0].value;
            }
          }
          return prevState + playerSpeed;
        });
      }, 40);

      setPlayerStatus(1);
    } else {
      stopIntervals();
      setPlayerStatus(0);
    }
  };

  // se sono in play aggiorno la velocità al cambio nella select
  useEffect(() => {
    // se sono in play aggiorno la velocità
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      // @ts-ignore
      currTime.current = (new Date()).getTime();
      intervalRef.current = setInterval(() => {
        setCurrentTime(( prevState ) => prevState + playerSpeed);
      }, 40);
    }

    if (playerStatus === 1) {
      handlePlayClick(true);
    }

    return () => {
      stopIntervals();
    };
  }, [playerSpeed]);

  // @todo mettere su funzioni a parte, deve pulire eventuali selezioni non attive
  const [playerLinks, setPlayerLinks] = useState<Set<[string, string]>>(new Set());

  const handlePlayerClick = useCallback(( playerID: string ) => {
    if (!currentActivePlayer) {
      setCurrentActivePlayer(playerID);
    } else if (currentActivePlayer && currentActivePlayer === playerID) {
      setCurrentActivePlayer(null);
    } else {
      const existingLink = Array.from(playerLinks)
      .find(( link ) => link[0] === currentActivePlayer && link[1] === playerID
        || link[0] === playerID && link[1] === currentActivePlayer);

      if (!existingLink) {
        const newPlayerLinks = new Set(playerLinks);
        newPlayerLinks.add([currentActivePlayer, playerID]);
        setPlayerLinks(newPlayerLinks);
      }

      setCurrentActivePlayer(null);
    }
  }, [currentActivePlayer]);

  const handlePlayerEnter = ( playerID: string ) => {
    setCurrentHoverPlayer(playerID);
  };

  const handlePlayerLeave = () => {
    setCurrentHoverPlayer(null);
  };

  const playersDetails: PlayersDetails = useMemo(() => (detailsData?.res?.athleteSessions
    ? detailsData?.res?.athleteSessions.reduce(( acc, curr ) => {
      if (curr.athlete?.id) {
        acc[curr.id] = {
          id: curr.athlete?.id,
          name: curr.athlete?.name,
          number: curr.athlete?.playerSet.find(( ps ) => ps.team?.id && ps.team?.id === teamId)?.number || null,
          shortName: curr.athlete?.shortName,
        };
      }

      return acc;
    }, {})
    : {}), [detailsData?.res?.athleteSessions]);

  const handleRemoveLink = ( linkIdx: number ) => {
    // eslint-disable-next-line no-restricted-globals
    const newPlayerLinks = Array.from(playerLinks);
    newPlayerLinks.splice(linkIdx, 1);
    setPlayerLinks(new Set(newPlayerLinks));
  };

  const enterFullscreen = () => {
    const elem = playerRef.current;
    // @ts-ignore
    if (elem.requestFullscreen) {
      // @ts-ignore
      elem.requestFullscreen();
      // @ts-ignore
    } else if (elem.mozRequestFullScreen) { // Firefox
      // @ts-ignore
      elem.mozRequestFullScreen();
      // @ts-ignore
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      // @ts-ignore
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      // @ts-ignore
    } else if (document.mozCancelFullScreen) {
      // @ts-ignore
      document.mozCancelFullScreen();
      // @ts-ignore
    } else if (document.webkitExitFullscreen) {
      // @ts-ignore
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreenClick = async () => {
    if (document.fullscreenElement) {
      exitFullscreen();
    } else if (playerRef.current) {
      try {
        enterFullscreen();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const defaultPlayersColor = !isGPSGround
    ? ground?.groundSurfaceColor ? getPairedGroundColor(ground?.groundSurfaceColor) : PLAYER_MARKER_COLOR
    : sessionGround?.groundSurfaceColor
      ? getPairedGroundColor(sessionGround?.groundSurfaceColor)
      : PLAYER_MARKER_COLOR;

  return (
    <ErrorGuard>
      <div className="tracks-player" ref={playerRef}>
        <WebPlayerHeader
          ground={ground}
          groundOptions={groundOptions}
          groundSet={groundSet}
          jerseyOrNumber={jerseyOrNumber}
          setGround={setGround}
          setJerseyOrNumber={setJerseyOrNumber}
          setTrailsEnabled={setTrailsEnabled}
          trailsEnabled={trailsEnabled}
          isSessionDataReady={!!data}
        />
        <Row>
          <Column xs={8}>
            <Box p={2} style={{paddingLeft: '10px'}}>
              <CursorContext.Provider value={cursorValue}>
                {
                  currentGround
                  && (
                    <Ground
                      currentActivePlayer={currentActivePlayer}
                      hoverPlayer={currentHoverPlayer}
                      defaultPlayersColor={defaultPlayersColor}
                      enabledPlayers={enabledPlayers}
                      ground={currentGround}
                      handlePlayerClick={handlePlayerClick}
                      handlePlayerEnter={handlePlayerEnter}
                      handlePlayerLeave={handlePlayerLeave}
                      handleRemoveLink={handleRemoveLink}
                      jerseyOrNumber={jerseyOrNumber}
                      playerLinks={playerLinks}
                      playersColors={playersColors}
                      playersDetails={playersDetails}
                      series={series}
                      showVertices={showVertices}
                      trailsEnabled={trailsEnabled}
                    />
                  )
                }

                <PlayerControls
                  currDrillRef={currDrillRef}
                  currentDrill={currentDrill}
                  currentTime={currentTime}
                  drillTimes={drillTimes}
                  endTimestamp={data?.res?.endTimestamp}
                  goToNextDrill={goToNextDrill}
                  handleChange={handleChange}
                  handleFullscreenClick={handleFullscreenClick}
                  handlePlayClick={() => handlePlayClick()}
                  marks={marks}
                  playerSpeed={playerSpeed}
                  playerStatus={playerStatus}
                  // setCurrentTime={setCurrentTime}
                  setPlayerSpeed={setPlayerSpeed}
                  startTimestamp={data?.res?.startTimestamp}
                  timeFormat={timeFormat}
                  valueLabelFormat={valueLabelFormat}
                />
              </CursorContext.Provider>
            </Box>
          </Column>

          <Column xs={4}>
            <PlayersTable
              athleteSessions={detailsData?.res?.athleteSessions || []}
              enabledPlayers={enabledPlayers}
              loadingPlayers={loadingPlayers}
              groundSurfaceColor={ground?.groundSurfaceColor || DEFAULT_GROUND_COLOR}
              jerseyOrNumber={jerseyOrNumber}
              playersColors={playersColors}
              playersDetails={playersDetails}
              setEnabledPlayers={setEnabledPlayers}
              setPlayersColors={setPlayersColors}
              status={status}
            />
          </Column>

        </Row>

      </div>
    </ErrorGuard>
  );
};

export default WebPlayer;
