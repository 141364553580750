import { GenericValueType } from 'query/track';
import { AthleteType, getAthleteFragment, getAthleteFragmentName } from './athlete';
import { ClubType, getClubFragment, getClubFragmentName, LimitedClubType } from './club';
import {
  getTeamSessionCategoryFragment,
  getTeamSessionCategoryFragmentName,
  TeamSessionCategoryType,
  TeamSessionTagType,
} from './team_session';

type PlayerType = {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;
  team: TeamType;
  athlete: AthleteType;
  // playingrole: PlayingRoleType,
  number: string;
  startDate: Date;
  endDate: Date;
};
type PortalTeamSportChoices = 'FOOTBALL' | 'FOOTBALL_GK' | 'RUGBY' | 'OTHER' | 'FIELD_HOCKEY' | 'BASKET';
type PortalTeamRpeFormatChoices = 'A_1' | 'A_6';
type PortalTeamAnalysisModelVersionChoices = 'A_1' | 'A_2';
export type TeamType = {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;
  name: string;
  season: string;
  club?: ClubType;
  limitedClub?: LimitedClubType;
  sport: PortalTeamSportChoices;
  startDate: Date; //   Format yyyy-mm-dd
  endDate: Date;
  controllerIp: string;
  rpeFormat: PortalTeamRpeFormatChoices;
  analysisModelVersion: PortalTeamAnalysisModelVersionChoices;
  // trackSet: TrackType[],
  playingroleSet: { id: string; name: string }[];
  playerSet: PlayerType[];
  // userprofileSet: UserType[],
  teamsessioncategorySet: TeamSessionCategoryType[];
  teamsessiontagSet: TeamSessionTagType[];
  // teamsessionSet: TeamSessionsType[],
  // templateSet: TemplateType[],
  league?: string;
  hasRpes?: boolean;
  locked?: boolean
};

export type TeamClubType = {id: string | number, mapService?: string; }

export type LimitedTeamType = {
  id: string | number;
  startDate?: Date;
  endDate?: Date;
  locked?: boolean;
  name: string;
  season?: string;
  club?: TeamClubType;
}

export type GenericValueInputType = {value?: number, uom?: string, unit?: string}

export type TeamChangelogType = {
  id: string;
  team: TeamType;
  areSpeedEventsZonesVisible: boolean;
  areSpeedTimeZonesVisible: boolean;
  areSpeedZonesPlusVisible: boolean;
  areSpeedDistanceZonesVisible: boolean;
  areRelativeSpeedEventsZonesVisible: boolean;
  areRelativeSpeedTimeZonesVisible: boolean;
  areRelativeSpeedDistanceZonesVisible: boolean;
  areRelativeSpeedZonesPlusVisible: boolean;
  speedThresholds: [GenericValueType];
  relativeSpeedThresholds: [GenericValueType];
  speedEventsThreshold: GenericValueType;
  relativeSpeedEventsThreshold: GenericValueType;
  relativeSpeedEventsDuration: GenericValueType;
  speedEventsDuration: GenericValueType;
  areAccelerationEventsZonesVisible: boolean;
  areAccelerationTimeZonesVisible: boolean;
  areAccelerationDistanceZonesVisible: boolean;
  areAccelerationZonesPlusVisible: boolean;
  accelerationThresholds: [GenericValueType];
  accelerationEventsThreshold: GenericValueType;
  accelerationEventsDuration: GenericValueType;
  areDecelerationEventsZonesVisible: boolean;
  areDecelerationTimeZonesVisible: boolean;
  areDecelerationDistanceZonesVisible: boolean;
  areDecelerationZonesPlusVisible: boolean;
  decelerationThresholds: [GenericValueType];
  decelerationEventsThreshold: GenericValueType;
  decelerationEventsDuration: GenericValueType;
  areHeartRateTimeZonesVisible: boolean;
  areHeartRateDistanceZonesVisible: boolean;
  areHeartRateZonesPlusVisible: boolean;
  heartRateThresholdsInPercent: [GenericValueType];
  arePowerEventsZonesVisible: boolean;
  arePowerTimeZonesVisible: boolean;
  arePowerDistanceZonesVisible: boolean;
  powerThresholds: [GenericValueType];
  powerEventsSpeedThresholds: [GenericValueType];
  powerEventsDistanceThresholds: [GenericValueType];
  powerEventsTimeThresholds: [GenericValueType];
  arePowerZonesPlusVisible: boolean;
  jumpHeightThresholds: [GenericValueType];
  diveLoadThresholds: [GenericValueType];
  diveLaunchLoadThresholds: [GenericValueType];
  diveReturnTimeThresholds: [GenericValueType];
  impactIntensityThresholds: [GenericValueType];
};

export type TeamResponseType =
  | 'with_club'
  | 'with_playerset'
  | 'with_teamsessioncategory'
  | 'with_teamsessionset'
  | 'with_parameters'
  | 'with_teamsessiontagSet';

export type LimitedTeamResponseType =
  | 'with_club';

export const getLimitedTeamFragmentName = (types: LimitedTeamResponseType[]) => `LimitedTeamTypeFields${types
  .sort()
  .map((t) => `_${t}`)
  .join('')}`;

export const getLimitedTeamFragment = (types: LimitedTeamResponseType[]) => `
  fragment ${getLimitedTeamFragmentName(types)} on LimitedTeamType {
    id
    startDate
    endDate
    locked
    name
    season
    ${types.includes('with_club')
    ? `
      club {
        id
        locked
        mapService
        name
      }`
    : ''}
  }
`;

export const getTeamFragmentName = (types: TeamResponseType[]) => `TeamTypeFields${types
  .sort()
  .map((t) => `_${t}`)
  .join('')}`;
export const getTeamFragment = (types: TeamResponseType[]) =>
  // tslint:disable-next-line: prefer-template
  `
    ${types.includes('with_club') ? getClubFragment([]) : ''}
    ${types.includes('with_playerset') ? getAthleteFragment(['no_thumbnail']) : ''}
    ${types.includes('with_teamsessioncategory') ? getTeamSessionCategoryFragment([]) : ''}
    fragment ${getTeamFragmentName(types)} on TeamType {
      id
      name
      isDeletable
      hasRpes
      analysisModelVersion
      ${
  types.includes('with_playerset')
    ? `
      playerSet {
        id
        athlete {
          ...${getAthleteFragmentName(['no_thumbnail'])}
        }
        playingrole {
          id
          name
        }
        number
        startDate
        endDate
      }`
    : ''
}${
  types.includes('with_teamsessioncategory')
    ? `
      teamsessioncategorySet {
        ...${getTeamSessionCategoryFragmentName([])}
      }`
    : ''
}
  ${
  types.includes('with_teamsessiontagSet')
    ? `
      teamsessiontagSet {
          id
          name
          occurrences
        }
    `
    : ''
}
      season${
  types.includes('with_club')
    ? `
      club {
        ...${getClubFragmentName([])}
      }`
    : ''
}
      sport
      startDate
      locked
      endDate
      controllerIp
      rpeFormat
      analysisModelVersion
      playingroleSet{id, name, order, isDeletable}
      defaultTeamsessionCategory{id, color, name}
      ${
  types.includes('with_parameters')
    ? `
        parameters {
          areSpeedEventsZonesVisible
          areSpeedTimeZonesVisible
          areSpeedDistanceZonesVisible
          areSpeedZonesPlusVisible
          areRelativeSpeedEventsZonesVisible
          areRelativeSpeedTimeZonesVisible
          areRelativeSpeedDistanceZonesVisible
          areRelativeSpeedZonesPlusVisible
          speedThresholds {value, uom, unit}
          relativeSpeedThresholds {value, uom, unit}
          speedEventsThreshold {value, uom, unit}
          relativeSpeedEventsThreshold {value, uom, unit}
          relativeSpeedEventsDuration {value, uom, unit}
          speedEventsDuration {value, uom, unit}
          areAccelerationEventsZonesVisible
          areAccelerationTimeZonesVisible
          areAccelerationDistanceZonesVisible
          areAccelerationZonesPlusVisible
          accelerationThresholds {value, uom, unit}
          accelerationEventsThreshold {value, uom, unit}
          accelerationEventsDuration {value, uom, unit}
          areDecelerationEventsZonesVisible
          areDecelerationTimeZonesVisible
          areDecelerationDistanceZonesVisible
          areDecelerationZonesPlusVisible
          decelerationThresholds {value, uom, unit}
          decelerationEventsThreshold {value, uom, unit}
          decelerationEventsDuration {value, uom, unit}
          useHrMin
          areHeartRateTimeZonesVisible
          areHeartRateDistanceZonesVisible
          areHeartRateZonesPlusVisible
          heartRateThresholdsInPercent {value, uom, unit}
          arePowerEventsZonesVisible
          arePowerTimeZonesVisible
          arePowerDistanceZonesVisible
          arePowerZonesPlusVisible
          powerThresholds {value, uom, unit}
          powerEventsSpeedThresholds {value, uom, unit}
          powerEventsDistanceThresholds {value, uom, unit}
          powerEventsTimeThresholds {value, uom, unit}
          jumpHeightThresholds {value, uom, unit}
          diveLoadThresholds {value, uom, unit}
          diveLaunchLoadThresholds {value, uom, unit}
          diveReturnTimeThresholds {value, uom, unit}
          impactIntensityThresholds {value, uom, unit}
        }
        `
    : ''
}
      league
      ${
  types.includes('with_teamsessionset')
    ? `
      teamsessionSet {
        id
        drill
        startTimestamp
      }`
    : ''
}
    }
  `;
