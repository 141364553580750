import React from 'react';
import GroundLPS from './GroundLPS';
import GroundGPS from './GroundGPS';
import { CircularProgress } from '@material-ui/core';
import { GroundProps } from '../../types/webPlayer';

const Ground = (props: GroundProps) => {
  const boxRef = React.useRef<HTMLDivElement>(null);

  const {
    currentActivePlayer,
    hoverPlayer,
    defaultPlayersColor,
    enabledPlayers,
    ground,
    jerseyOrNumber,
    handlePlayerClick,
    handlePlayerEnter,
    handlePlayerLeave,
    isLoadingData,
    playerLinks,
    playersColors,
    playersDetails,
    playersExtraData,
    handleRemoveLink,
    series,
    showVertices,
    trailsEnabled,
  } = props;

  const isGPSGround = ground?.groundCoordsType !== 'LOCAL';

  return (
    <div style={{ border: '1px solid #ddd', overflow: 'hidden', position: 'relative' }} ref={boxRef}>
      {
        isLoadingData
        && (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10, color: 'white'
          }}>
            <CircularProgress color="inherit" size={30} />
          </div>
        )
      }
      {
        ground?.id
        && (
          <div style={ isLoadingData ? {opacity: 0.8, pointerEvents: 'none', transition: 'opacity 0.5s'} : {}}>
            {
              !isGPSGround
              && (
                <GroundLPS
                  activePlayer={currentActivePlayer}
                  hoverPlayer={hoverPlayer}
                  defaultPlayersColor={defaultPlayersColor}
                  enabledPlayers={enabledPlayers}
                  groundId={String(ground.id)}
                  jerseyOrNumber={jerseyOrNumber}
                  onPlayerClick={handlePlayerClick}
                  onPlayerEnter={handlePlayerEnter}
                  onPlayerLeave={handlePlayerLeave}
                  playerLinks={playerLinks}
                  playersColors={playersColors}
                  playersDetails={playersDetails}
                  playersExtraData={playersExtraData}
                  removeLink={handleRemoveLink}
                  series={series}
                  trailsEnabled={trailsEnabled}
                />
              )
            }

            {
              isGPSGround && (
                <GroundGPS
                  activePlayer={currentActivePlayer}
                  hoverPlayer={hoverPlayer}
                  defaultPlayersColor={defaultPlayersColor}
                  enabledPlayers={enabledPlayers}
                  groundId={String(ground.id)}
                  jerseyOrNumber={jerseyOrNumber}
                  onPlayerClick={handlePlayerClick}
                  onPlayerEnter={handlePlayerEnter}
                  onPlayerLeave={handlePlayerLeave}
                  playerLinks={playerLinks}
                  playersColors={playersColors}
                  playersDetails={playersDetails}
                  playersExtraData={playersExtraData}
                  removeLink={handleRemoveLink}
                  series={series}
                  showVertices={showVertices}
                  trailsEnabled={trailsEnabled}
                />
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default Ground;
