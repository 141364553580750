import React, { Dispatch, SetStateAction, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { t } from 'i18next';
import { OutlinedWrapper } from './form/FieldsGroup';
import { Row } from './layout/Row';
import { Column } from './layout/Column';
import { AVAILABLE_LANGUAGES } from '../query/user';
import ListField from './form/ListField';
import { TextField } from './form/TextField';
import { SelectField } from './form/SelectField';
import { countryFlags } from './users/utils';
import { formatLanguage } from './Account';
import { UserRoleTable } from './user/UserTables';
import { UNITS_VALUES } from './utils/units';
import ResetPasswordDialogButton from './user/ResetPasswordDialogButton';
import { Loader } from '../lib/icons';
import Error from './layout/Error';
import { useQueryCached } from './utils/graphql';
import BaseButton from './form/BaseButton';
import { colorsPaletteOptions } from './utils/colors';
import Checkbox from './form/Checkbox';
import DividerWithText from './layout/DividerWithText';

type PropsIn = {
  userUnits: object,
  setUserUnits: (key: object) => void,
  userInfo?: {
    lastName: string,
    firstName: string,
    email: string,
    userroleSet: [],
  },
  userName: { lastName: string; firstName: string; },
  setUserName: Dispatch<SetStateAction<{lastName: string; firstName: string; }>>,
  userLanguage: string,
  setUserLanguage: (key: string) => void,
  colorPalette: string,
  setColorPalette: (key: string) => void,
  availableUnits?: object[],
  forceUom: boolean,
  setForceUom: (key: boolean) => void,
}

const AdminButton = () => {
  const adminUrl = `${process.env.GPEXE_BACKEND_URL}admin`;
  return (
    <BaseButton
      style={{ marginLeft: '1px' }}
      buttonSize="sm"
      onClick={() => {
        window.open(adminUrl, '_blank', 'noopener,noreferrer');
      }}
    >
      admin page
    </BaseButton>
  );
};

const MySettings = (props: PropsIn) => {
  const {
    userUnits,
    setUserUnits,
    userInfo,
    userName,
    setUserName,
    userLanguage,
    setUserLanguage,
    availableUnits,
    colorPalette,
    setColorPalette,
    forceUom,
    setForceUom,
  } = props;

  const { t } = useTranslation();
  const { loading: languageLoading, error: languageError, data: languageData } = useQueryCached(AVAILABLE_LANGUAGES);
  if (languageLoading) return <Loader />;
  if (languageError) return <Error />;

  const languageOptions = (languageData?.res?.availableLanguages || []).map((d, n) => ({
    id: `language-${n}`, label: d.value, value: d.key, image: countryFlags[d.key],
  }));

  function handleUnitChoice(key, value) {
    const uu = { ...userUnits };
    uu[key] = value;
    setUserUnits(() => ({ ...uu }));
  }
  return (
    <Row spacing={2}>
      <Column xs={6}>
        <TextField
          label={t('user.drawer.content.lastName', 'last name')}
          value={userName.lastName}
          onChange={(e) => {
            setUserName((prev) => ({ ...prev, lastName: e.target.value }));
          }}
        />
      </Column>
      <Column xs={6}>
        <TextField
          label={t('user.drawer.content.firstName', 'first name')}
          value={userName.firstName}
          onChange={(e) => {
            setUserName((prev) => ({ ...prev, firstName: e.target.value }));
          }}
        />
      </Column>
      <Column xs={6}>
        <TextField
          label={t('user.drawer.content.email', 'email')}
          value={userInfo?.email}
          readonly
        />
      </Column>
      <Column xs={6}>
        <SelectField
          fullWidth
          label={t('user.drawer.content.languages', 'languages')}
          value={userLanguage}
          options={languageOptions}
          onChange={(e) => setUserLanguage(formatLanguage(e.target.value))}
        />
      </Column>
      <Column xs={6}>
        <SelectField
          fullWidth
          label={t('user.drawer.content.colorPalette', 'color palette')}
          value={colorPalette}
          options={colorsPaletteOptions}
          onChange={(e) => setColorPalette(e.target.value)}
        />
      </Column>
      <Column xs={12}>
        {!userInfo?.userroleSet?.length || userInfo?.userroleSet?.roleType === 'ADMIN'
          ? (
            <TextField
              label={t('user.userRole', 'user role')}
              value={t('user.userRolesType.admin', 'admin')}
              readonly
            />
          )
          : (
            <>
              <DividerWithText text={t('user.drawer.content.roles', 'roles')} textClassName="list-field__title" ySpacing />
              <UserRoleTable user={userInfo} />
            </>
          )}
      </Column>
      {
        userInfo?.email
        && (
          <Column lg={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: !userInfo?.userroleSet?.length ? 'space-between' : 'end',
                pt: '8px',
              }}
            >
              { !userInfo?.userroleSet?.length && <AdminButton /> }
              <ResetPasswordDialogButton email={userInfo?.email} buttonSize="sm" />
            </Box>
          </Column>
        )
      }
      <Column xs={12}>
        <ListField
          rows={[
            {
              id: 'units',
              title: t('user.drawer.content.divider.units', 'UNITS'),
              component: <div>
                { availableUnits && availableUnits?.slice(1).map((uu, index) => (
                  <Box pt={2} className={uu.key == 'speed' ? 'box-flex' : ''}>
                    <OutlinedWrapper
                      key={`units-${uu.key}`}
                      label={t(`user.drawer.content.units.${uu.key}`, `${uu.key}`)}
                    >
                      <RadioGroup
                        key={`userunits-${index + 1}`}
                        id="userunits-radio-buttons"
                        aria-labelledby="radio-buttons-group-label"
                        name="userunits-radio-buttons-group"
                        value={userUnits[uu.key]}
                        row
                        onChange={(e) => {
                          handleUnitChoice(uu.key, e.target.value);
                        }}
                      >
                        {uu?.value?.map((d, n) => (
                          <FormControlLabel
                            key={`userunits-${uu.key}-${n}`}
                            value={d}
                            control={<Radio />}
                            label={UNITS_VALUES[d]}
                          />
                        ))}
                      </RadioGroup>
                    </OutlinedWrapper>
                    { uu.key == 'speed'
                    && (
                    <Checkbox
                      disabled={userUnits?.speed === 'M_MIN'}
                      value={forceUom}
                      onClick={() => { setForceUom((prev) => !prev); }}
                      label={t('user.drawer.content.units.forceMmin', 'm/min for avg speed')}
                    />
                    ) }
                  </Box>
                ))}
                         </div>,
            },
          ]}
        />
      </Column>
    </Row>
  );
};

export default MySettings;
