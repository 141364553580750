import { gql } from '@apollo/client';
import { getAthleteFragment, getAthleteFragmentName } from 'models/athlete';
import { AthleteSessionType } from 'models/athlete_session';
import {
  getTeamSessionFragment,
  getTeamSessionFragmentName,
  SessionType,
} from 'models/team_session';

export type AthleteSessionListExportOptions = {
  athleteIds: string[] | null,
  categoryIds: string[] | null,
  tagIds: string[] | null,
  startDate: Date | string | null,
  endDate: Date | string | null,
  sort?: string,
  drillEnabled?: Boolean,
  matchDistance: number[] | null,
  matchCycle: number[] | null,
  nature: SessionType[] | null,
  starter: boolean | null,
  exactDate: Date | string | null,
  multipleFilters: string | null,
  deviceIds: null,
  enabledXMLExport: boolean,
};

export type TeamSessionOptions = {
  id?: string | number,
  drill?: string | number,
  endTs?: Date,
  startTs?: Date,
  createdOn?: number,
  activeSeries?: boolean,
  exportTemplate?: string,
  drills_details?: string | null,
  new_web_app?: boolean,
  template_id?: string,
};

export const ATHLETE_SESSIONS_DETAILS = gql`
  query AthleteSessionDetails(
    $teamId: ID!,
    $athleteIds: [ID],
    $categoryIds: [ID],
    $startDate: Date,
    $endDate: Date,
    $templateId: ID,
    $first: Int,
    $skip: Int,
    $sort: String,
    $drillEnabled: Boolean,
    $matchDistance: [Int],
    $matchCycle: [Int],
    $nature: [AthleteSessionNatureFilter],
    $trackIds: [ID],
    $starter: Boolean,
    $multipleFilters: String,
  ) {
    res: athletesessions(
      teamId: $teamId,
      templateId: $templateId,
      first: $first,
      skip: $skip,
      sort: $sort,
      athleteIds: $athleteIds,
      categoryIds: $categoryIds,
      startDate: $startDate,
      endDate: $endDate,
      drillEnabled: $drillEnabled,
      matchDistance: $matchDistance,
      matchCycle: $matchCycle,
      nature: $nature,
      trackIds: $trackIds,   
      starter: $starter,
      multipleFilters: $multipleFilters,
    ) {
      content {
        id
        teamsession {
          id
          drill
          startTimestamp
          category{
              id
              name
              color
          }
          tags{
            id
            name
          }
          nature
          matchDistance {
              prevDays
              nextDays
          }
          matchCycle
          startTimestamp  
          drillCount
        }
      }
      count
      offset
      pageSize
    }
  }
`;

export const ATHLETE_SESSIONS_ATHLETES = gql`
    query AthleteSessionAthletes(
        $teamId: ID!,
        $athleteIds: [ID],
        $categoryIds: [ID],
        $startDate: Date,
        $endDate: Date,
        $exactDate: Date,
        $drillEnabled: Boolean,
        $matchDistance: [Int],
        $matchCycle: [Int],
        $nature: [AthleteSessionNatureFilter],
        $trackIds: [ID],
        $starter: Boolean,
        $multipleFilters: String,
        $first: Int,
        $skip: Int,
        $sort: String
    ) {
        res: athletesessions(
            teamId: $teamId,
            athleteIds: $athleteIds,
            categoryIds: $categoryIds,
            startDate: $startDate,
            endDate: $endDate,
            exactDate: $exactDate,
            drillEnabled: $drillEnabled,
            matchDistance: $matchDistance,
            matchCycle: $matchCycle,
            nature: $nature,
            trackIds: $trackIds,
            starter: $starter,
            multipleFilters: $multipleFilters,
            first: $first,
            skip: $skip,
            sort: $sort,
        ) {
            content {
                athlete {
                    name
                    id
                }
                id
                drill
                startTimestamp
                teamsession {
                    id
                    drill
                    category{
                        id
                        name
                        color
                    }
                    tags{
                        id
                        name
                    }
                    notes
                    nature
                    matchDistance {
                        prevDays
                        nextDays
                    }
                    matchCycle
                    startTimestamp
                    drillCount
                }
            
            }
        }
    }
`;

export const ATHLETE_SESSIONS_PROFILES_ATHLETES = gql`
    query AthleteSessionProfilesAthletes(
        $teamId: ID!,
        $athleteIds: [ID],
        $categoryIds: [ID],
        $startDate: Date,
        $endDate: Date,
        $exactDate: Date,
        $drillEnabled: Boolean,
        $matchDistance: [Int],
        $matchCycle: [Int],
        $nature: [AthleteSessionNatureFilter],
        $starter: Boolean,
        $multipleFilters: String,
        $first: Int,
        $skip: Int,
        $sort: String,
        $uniqueAthletes: Boolean,
    ) {
        res: athletesessions(
            teamId: $teamId,
            athleteIds: $athleteIds,
            categoryIds: $categoryIds,
            startDate: $startDate,
            endDate: $endDate,
            exactDate: $exactDate,
            drillEnabled: $drillEnabled,
            matchDistance: $matchDistance,
            matchCycle: $matchCycle,
            nature: $nature,
            starter: $starter,
            multipleFilters: $multipleFilters,
            first: $first,
            skip: $skip,
            sort: $sort,
            uniqueAthletes: $uniqueAthletes,
        ) {
            content {
                athlete {
                    name
                    id
                }
            }
        }
    }
`;

export const ATHLETE_SESSIONS_DELTADAYS = gql`
    query AthleteSessionsDeltaDays(
        $teamId: ID!,
        $athleteIds: [ID],
        $categoryIds: [ID],
        $tagIds: [ID],
        $startDate: Date,
        $endDate: Date,
        $exactDate: Date,
        $matchDistance: [Int],
        $matchCycle: [Int],
        $starter: Boolean,
    ) {
        res: athletesessionsDeltaDays(
            teamId: $teamId,
            athleteIds: $athleteIds,
            categoryIds: $categoryIds,
            tagIds: $tagIds,
            startDate: $startDate,
            endDate: $endDate,
            exactDate: $exactDate,
            matchDistance: $matchDistance,
            matchCycle: $matchCycle,
            starter: $starter,
        )
    }
`;

export const ATHLETE_SESSIONS = gql`
    query AthleteSessions(
        $teamId: ID!,
        $athleteIds: [ID],
        $categoryIds: [ID],
        $startDate: Date,
        $endDate: Date,
        $exactDate: Date,
        $templateId: ID,
        $first: Int,
        $skip: Int,
        $sort: String,
        $drillEnabled: Boolean,
        $matchDistance: [Int],
        $matchCycle: [Int],
        $nature: [AthleteSessionNatureFilter],
        $trackIds: [ID],
        $starter: Boolean,
        $multipleFilters: String,
    ) {
        res: athletesessions(
            teamId: $teamId,
            templateId: $templateId,
            first: $first,
            skip: $skip,
            sort: $sort,
            athleteIds: $athleteIds,
            categoryIds: $categoryIds,
            startDate: $startDate,
            endDate: $endDate,
            exactDate: $exactDate,
            drillEnabled: $drillEnabled,
            matchDistance: $matchDistance,
            matchCycle: $matchCycle,
            nature: $nature,
            trackIds: $trackIds,
            starter: $starter,
            multipleFilters: $multipleFilters,
        ) {
            content {
                id
                state
                nature
                startDate
                startTime
                starter
                masterAthleteSession
                needReprocess
                startTimestamp
                isGk
                categories {
                  color
                  name
                  notes
                }
                tags
                teamsession{
                  id
                  drill
                  tags{
                    id
                    name
                  }
                  matchCycle
                }
                track {
                    id
                    timestamp
                }
                athlete {
                    id
                    firstName
                    lastName
                    name
                }
                isConsolidate
                drill
                duration {
                    value
                    unit
                    uom
                }
            }
            count
            offset
            pageSize
        }
    }
`;

export type AthSessionVars = {
  templateId?: string,
  id: string,
  start?: number,
  end?: number,
  drill?: number,
}
export type AthSessionRes = {
  res: AthleteSessionType,
}
export const ATHLETE_SESSION = gql`
${getTeamSessionFragment(['with_team_session_category'])}
query AthleteSession(
  $templateId: ID,
  $id: ID!
  $start: Float,
  $end: Float,
  $drill: Int,  
) {
  res: athletesession(
    templateId: $templateId,
    id: $id,
    start: $start,
    end: $end,
    drill: $drill,  
  ) {
    id
    createdOn
    updatedOn
    state
    startTime
    analysisModelVersion
    isGk
    needReprocess
    track {
      id
      device {
        id
        serialId
      }
      timestamp
      nSessions
      notes
      ground {
        id
        name
      }
      incomingFileFormat
      storedOnGlacier
      team {
          id
      }
      vo2MaxNet {
          unit
          uom
          value
      }
    }
    athlete {
      id
      name
      firstName
      lastName
      shortName
      thumbnail
      playerSet {
          id
          number
          team {
              id
              name
          }
      }
    }
    teamsession {
      ...${getTeamSessionFragmentName(['with_team_session_category'])}
    }
    isStatsValid
    drill
    isConsolidate
    equivalentDistance {
      value
      unit
      uom
    }
    averageV {
      value
      unit
      uom
    }
    averageHr {
      value
      unit
      uom
    }
    averageP {
      element {
        value
        unit
        uom
      }
      lowerBound {
        value
        unit
        uom
      }
      upperBound {
        value
        unit
        uom
      }
    }
    mpe {
        value
        unit
        uom
    }
    averagePowerAer {
      value
      unit
      uom
    }
    totalEnergy {
      value
      unit
      uom
    }
    anaerobicEnergy {
      value
      unit
      uom
    }
    recoveryAverageTime {
      value
      unit
      uom
    }
    recoveryAveragePower {
      value
      unit
      uom
    }
    edwardsTl {
      value
      unit
      uom
    }
    timeRunning {
      value
      unit
      uom
    }
    timeWalking {
      value
      unit
      uom
    }
    distRunning {
      value
      unit
      uom
    }
    distWalking {
      value
      unit
      uom
    }
    energyRunning {
      value
      unit
      uom
    }
    energyWalking {
      value
      unit
      uom
    }
    totalTime {
      value
      unit
      uom
    }
    powerEventsAvgTime {
      value
      unit
      uom
    }
    powerEventsAvgPower {
        value
        unit
        uom
    }
    maxValuesSpeed {
      element { unit, uom, value}
      upperBound { value }
      lowerBound { value }  
    }
    maxValuesAcc {
      value
      unit
      uom
    }
    maxValuesDec {
        value
        unit
        uom
    }
    maxValuesPower {
        value
        unit
        uom
    }
    maxValuesCardio {
        value
        unit
        uom
    }
    startDate
    endDate
    duration {
        value
        unit
        uom
    }
    distance {
        value
        unit
        uom
    }
    kpi {
      name
      value
      unit
      uom
    }
    layout {
      key
      value {
        key
        value
      }
      order
    }
    drills {
      wholeSession {
        id
        index
        totalTime {
          value
          unit
          uom
        }
        start
        end
      }
      relatedDrills {
        id
        index
        totalTime {
          value
          unit
          uom
        }
        start
        end
      }
    }
    starter
    startTime
  }
}
`;

export type AthleteSessionByTrackVars = {
  templateId?: string,
  trackId: string,
  start: number | null,
  end: number | null,
}
export type AthleteSessionBySessionVars = {
  templateId?: string,
  id: string,
  start: number,
  end: number,
  drill: number
}

export const ATHLETE_SESSION_BY_TRACK = gql`
${getTeamSessionFragment(['with_team_session_category'])}
${getAthleteFragment([])}
query AthleteSessionByTrack(
  $templateId: ID,
  $trackId: ID!,
  $start: Float,
  $end: Float
) {
  res: trackAthletesession(
    templateId: $templateId,
    trackId: $trackId,
    start: $start,
    end: $end,
  ) {
    id
    createdOn
    updatedOn
    state
    analysisModelVersion
    isGk
    track {
      id
      team {
          id
          club{
              id
              name
          }
      }  
      incomingFileFormat
      storedOnGlacier
      device {
        id
        deviceId
        serialId
      }
      timestamp
      nSessions
      notes
      ground {
          id
          name
      }
      athlete {
          id
          picture
          playerSet {
              id
              number
              team {
                  id
                  name
              }
          }
          ...${getAthleteFragmentName([])}
      }
      vo2MaxNet {
          unit
          uom
          value
      }
    }
    teamsession {
      ...${getTeamSessionFragmentName(['with_team_session_category'])}
    }
    isStatsValid
    isConsolidate
    drill
    equivalentDistance{
      value
      unit
      uom
    }
    averageV{
      value
      unit
      uom
    }
    averageHr{
      value
      unit
      uom
    }
    averageP{
      element {
        value
        unit
        uom
      }
      lowerBound {
        value
        unit
        uom
      }
      upperBound {
        value
        unit
        uom
      }
    }
    averagePowerAer{
      value
      unit
      uom
    }
    totalEnergy{
      value
      unit
      uom
    }
    anaerobicEnergy{
      value
      unit
      uom
    }
    recoveryAverageTime{
      value
      unit
      uom
    }
    recoveryAveragePower{
      value
      unit
      uom
    }
    edwardsTl{
      value
      unit
      uom
    }
    timeRunning{
      value
      unit
      uom
    }
    timeWalking{
      value
      unit
      uom
    }
    distRunning{
      value
      unit
      uom
    }
    distWalking{
      value
      unit
      uom
    }
    energyRunning{
      value
      unit
      uom
    }
    energyWalking{
      value
      unit
      uom
    }
    totalTime{
      value
      unit
      uom
    }
    powerEventsAvgTime{
      value
      unit
      uom
    }
    powerEventsAvgPower{
      value
      unit
      uom
    }
    maxValuesSpeed {
        element { unit, uom, value}
        upperBound { value }
        lowerBound { value }
    }
    maxValuesAcc{
      value
      unit
      uom
    }
    maxValuesDec{
      value
      unit
      uom
    }
    maxValuesPower{
      value
      unit
      uom
    }
    maxValuesCardio{
      value
      unit
      uom
    }
    startDate
    endDate
    startTime  
    duration {
      value
      unit
      uom
    }
    distance {
      value
      unit
      uom
    }
    kpi {
      name
      value
      unit
      uom
    }
    layout {
      key
      value {
        key
        value
      }
      order
    }
    teamsession {
        id
        drill
        team {
            club {
                id
                name
            }
        }
    }
  }
}
`;
