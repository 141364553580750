import React, {
  useState, useContext, useMemo, useRef, useEffect,
} from 'react';
import { GroundType } from 'models/ground';
import { CursorContext } from '../track/TrackChartContainer';
import { useQueryCached } from '../utils/graphql';
import { GroundRes, GroundVars, GROUND_DETAILS } from '../../query/grounds';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import Error from '../layout/Error';
import { coordsGPSToLPS, getPlayersLines, getPlayersPointsGPS, getSizesFromRef } from '../utils/groundNew';
import GroundField from './GroundField';
import { useDebouncedResize } from '../../lib/dom';
import { GPSGroundProps } from '../../types/webPlayer';

const GroundGPS = ((props: GPSGroundProps) => {
  const {
    activePlayer,
    hoverPlayer,
    defaultPlayersColor,
    enabledPlayers,
    groundId,
    jerseyOrNumber,
    onPlayerClick,
    onPlayerEnter,
    onPlayerLeave,
    playerLinks,
    playersColors,
    playersDetails,
    playersExtraData,
    removeLink,
    series,
    showGroundName,
    showVertices,
    trailsEnabled,
  } = props;

  const parentRef = useRef<HTMLDivElement | null>(null);

  const [zoom, setZoom] = useState(1);
  const [bearing, setBearing] = useState(0);
  const [scale, setScale] = useState(1);
  const [groundData, setGroundData] = useState<GroundType | null>(null);
  const [lpsGroundData, setLpsGroundData] = useState<GroundType | undefined>(undefined);
  const { cursor } = useContext(CursorContext);
  const [vertex, setVertex] = useState({
    vertexA: {
      x: 0,
      y: 0,
    },
    vertexB: {
      x: 0,
      y: 0,
    },
    vertexC: {
      x: 0,
      y: 0,
    },
    vertexD: {
      x: 0,
      y: 0,
    },
  });

  const [windowSize, setWindowSize] = useState({
    WINDOW_WIDTH: 0,
    WINDOW_HEIGHT: 0,
  })

  useEffect(() => {
    setWindowSize(getSizesFromRef(parentRef))
  }, []);

  useDebouncedResize(() => {
    setWindowSize(getSizesFromRef(parentRef))
  }, 200);

  const {
    WINDOW_WIDTH, WINDOW_HEIGHT,
  } = windowSize;

  const {
    error: errorGround,
  } = useQueryCached<GroundRes, GroundVars>(GROUND_DETAILS, {
    variables: {
      id: groundId,
    },
    skip: groundId == null,
    ...CACHE_AND_NETWORK,
    onCompleted: (response) => {
      const {
        vertexA,
        vertexB,
        vertexC,
        vertexD,
        scale: scaleValue,
        localBearing,
      } = coordsGPSToLPS({
        ground: response.res,
        WINDOW_WIDTH,
        WINDOW_HEIGHT,
      });

      setGroundData(response.res);

      // sovrascrivo i dati locali nei dettagli del ground da passare al Pitch
      setLpsGroundData({
        ...response.res,
        ...{
          vertexAX: { ...response.res.vertexAX, ...{ value: vertexA.x } },
          vertexAY: { ...response.res.vertexAY, ...{ value: vertexA.y } },
          vertexBX: { ...response.res.vertexBX, ...{ value: vertexB.x } },
          vertexBY: { ...response.res.vertexBY, ...{ value: vertexB.y } },
          vertexCX: { ...response.res.vertexCX, ...{ value: vertexC.x } },
          vertexCY: { ...response.res.vertexCY, ...{ value: vertexC.y } },
          vertexDX: { ...response.res.vertexDX, ...{ value: vertexD.x } },
          vertexDY: { ...response.res.vertexDY, ...{ value: vertexD.y } },
        },
      });

      setScale(scaleValue);
      setBearing(localBearing);
      setVertex({
        vertexA,
        vertexB,
        vertexC,
        vertexD,
      });
    },
  });

  const scaledWidth = vertex.vertexC.x && vertex.vertexA.x
    ? vertex.vertexC.x - vertex.vertexA.x
    : 1;
  const scaledHeight = vertex.vertexA.y && vertex.vertexC.y
    ? vertex.vertexC.y - vertex.vertexA.y
    : 1;

  const offX = 1 - (WINDOW_WIDTH - (scaledWidth * scale)) / 2;
  const offY = 1 - scaledHeight * scale - (WINDOW_HEIGHT - (scaledHeight * scale)) / 2;

  /*
  @todo varie
  selettore nome breve o jersey
  linee tra i player con la distanza tra i punti (A-B, C-D, A-C)
  area tra i vari punti
  selettore velocità
  integrare con sessions (se singolo drill mostrare solo quello)
  full screen controlli traccia su hover 1/5 inferiore

  sentire loris per la parte GPS
   */

  const playersPoints = useMemo(() => getPlayersPointsGPS({
    bearing,
    cursor,
    enabledPlayers,
    groundData,
    scale,
    series,
  }), [scale, bearing, groundData, series, cursor, enabledPlayers]);

  const playersLines = useMemo(() => getPlayersLines({
    playerLinks,
    playersPoints,
  }), [playerLinks, playersPoints]);

  if (errorGround) return <Error />;

  return (
    <>
      {showGroundName
        && (
          <div>
            {lpsGroundData?.id}
            {' '}
            -
            {lpsGroundData?.name}
          </div>
        )}

      <GroundField
        WINDOW_HEIGHT={WINDOW_HEIGHT}
        WINDOW_WIDTH={WINDOW_WIDTH}
        activePlayer={activePlayer}
        hoverPlayer={hoverPlayer}
        cursor={cursor}
        dataGround={lpsGroundData}
        defaultPlayersColor={defaultPlayersColor}
        enabledPlayers={enabledPlayers}
        jerseyOrNumber={jerseyOrNumber}
        offX={offX}
        offY={offY}
        onPlayerClick={onPlayerClick}
        onPlayerEnter={onPlayerEnter}
        onPlayerLeave={onPlayerLeave}
        playersColors={playersColors}
        playersDetails={playersDetails}
        playersExtraData={playersExtraData}
        playersLines={playersLines}
        ref={parentRef}
        removeLink={removeLink}
        scale={scale}
        series={series}
        setZoom={setZoom}
        showVertices={!!showVertices}
        trailsEnabled={trailsEnabled}
        zoom={zoom}
      />
    </>
  );
});

export default GroundGPS;
