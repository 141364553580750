import { useState, useEffect, useRef } from 'react';

export default function useClickOutside(initialState) {
  const [isOpen, setIsOpen] = useState(initialState);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isOpen, setIsOpen };
}

// Hook debounce per il resize
export const useDebouncedResize = (callback: () => void, delay: number) => {
  const timeoutId = useRef<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = window.setTimeout(() => {
        callback();
      }, delay);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [callback, delay]);
}
