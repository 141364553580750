import { PlayerDataIntervals } from '../../types/webPlayer';

export class FpsCtrl {
  isPlaying = false;
  time: number | null = null;
  frame = -1;
  tref: number | undefined = undefined;
  callback: undefined | Function = undefined;
  delay = 1000;
  fps = 25;
  multiplier = 1;

  constructor(fps: number, callback: Function) {
    this.delay = 1000 / fps
    this.fps = fps;
    this.callback = callback;
  }

  loop = ( timestamp: number) => {
    if (this.time === null) {
      this.time = timestamp;
    }

    const seg = Math.floor((timestamp - this.time) / this.delay);
    if (seg > this.frame) {
      this.frame = seg;

      if (this.callback) {
        this.callback({
          frame: this.frame * this.multiplier,
        })
      }
    }

    this.tref = requestAnimationFrame(this.loop)
  };

  setMultiplier = (value: number) => {
    this.multiplier = value;
  }

  frameRate = ( newfps: number ) => {
    if (!newfps) {
      return this.fps;
    }

    this.fps = newfps;
    this.delay = 1000 / this.fps;
    this.frame = -1;
    this.time = null;
  };

  start = () => {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.tref = requestAnimationFrame(this.loop);
    }
  };

  stepForward = () => {
    if (this.isPlaying) {
      this.pause();
    }
    this.frame += this.fps;
    this.time = (this.time || 0) + 1000;

    if (this.callback) {
      this.callback({
        time: this.time,
        frame: this.frame
      })
    }
  };

  pause = () => {
    if (this.isPlaying) {
      if (this.tref) {
        cancelAnimationFrame(this.tref);
      }

      this.isPlaying = false;
      this.time = null;
      this.frame = -1;
    }
  };
}

export const checkNearEndOfInterval = (
  curTime: number,
  dataIntervals: PlayerDataIntervals,
  threshold = 20000,
  cb?: Function
) => {
  const isNearEnd = dataIntervals.find(interval => {
    if (!interval[1]) return false;
    return Math.abs(interval[1] - curTime) <= threshold;
  });

  if (isNearEnd) {
    if (cb) {
      cb(isNearEnd);
    }
  }
};
