import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import HighchartsReact from 'highcharts-react-official';
import { highchartsStock } from 'App';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Highcharts, { SeriesXrangeOptions } from 'highcharts';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { useQueryCached } from 'components/utils/graphql';
import * as HighCharts from 'highcharts';
import {
  EVENTS_DATA, SERIES_DATA, SERIES_ZONES_DATA, EVENTS_TYPES,
} from '../../query/track';
import { GlobalFiltersContext, TrackExportDataType } from '../track/View';
import { generateColor } from '../../lib/colors';
import {
  isJsonString,
} from '../utils/utils';
import { CursorContext } from '../track/TrackChartContainer';
import { serieApproximation, tooltipFormatter, TooltipKeys, tooltipStyle } from './utils';
import { useQueryParams } from '../utils/hooks';
import { UNITS_LABELS_FORMATS } from '../utils/units';
import { eventsColorByName } from "./colors";

type PropsIn = {
  hideLegend?: boolean,
  athleteSessionId: string | undefined,
  currentDrill: number,
  series: string[],
  events: string[],
  zones: string[],
  templateId: string,
  updateSeries: (series: string[]) => void,
  setTrackExportData: (state) => TrackExportDataType;
  setActiveSeries?: React.Dispatch<React.SetStateAction<TrackExportDataType>>,
  plotLinesY?: HighCharts.YAxisPlotLinesOptions[];
}

const seriesColors = {
  Vbatt_mV: '#935819',
  acc: '#8bbc21',
  dec: '#bc6721',
  accelerative_events: '#444444',
  active_muscle_power: '#b266ff',
  avg_anchors: '#777777',
  avg_residue: '#2667ad',
  bad_fixes_perchdop: '#111111',
  battery: '#935819',
  cardio: '#f28f43',
  cardio_percentual: '#f28f43',
  decelerative_events: '#666666',
  dist: '#f2ff49',
  equivalent_dist: '#ff8f49',
  event_dives: '#444444',
  event_impacts: '#444444',
  event_jumps: '#333333',
  hdop: '#111111',
  head_acc: '#999999',
  mark: '#444444',
  mech_power: '#ff33ff',
  nsat: '#777777',
  pdop: '#aaaaaa',
  power: '#b31217',
  power_aer: '#7CB5EC',
  power_events: '#222222',
  raw_speed: '#492970',
  satprsum: '#2667ad',
  speed: '#1aadce',
  relativeSpeed: '#1aadce',
  speed_acc: '#555555',
  speed_events: '#888888',
};

const getSerieTranslation = (z: string): EVENTS_TYPES => {
  switch (z) {
    case 'acceleration':
      return 'acc';

    case 'deceleration':
      return 'dec';

    case 'hr':
      return 'cardio';

    default:
      return z as EVENTS_TYPES;
  }
};

const createEventTooltip = (d, serieName: string, order: string[]) => {
  const tooltipRows: TooltipKeys[] = [];
  if (order.length) {
    order.forEach((key) => {
      const gv = d.genericValues.find((gk) => gk.key === key);
      const cv = d.customValues.find((ck) => ck.key === key);
      if (gv && !(gv.key === 'height' && serieName === 'dives')) {
        tooltipRows.push({
          key: gv.key,
          unit: gv.genericValue.unit,
          value: gv.genericValue.value,
          uom: gv.key === 'height' ? UNITS_LABELS_FORMATS.CM : gv.genericValue.uom,
          valueType: 'CustomValueType',
          customFormatter: gv.key === 'height' ? 'height' : undefined,
        });
      } else if (cv) {
        tooltipRows.push({
          key: cv.key,
          value: cv.value,
        });
      }
    });
    return tooltipFormatter(tooltipRows);
  }

  const mergedRows = [...d.genericValues, ...d.customValues];
  const formattedRows: TooltipKeys[] = mergedRows.map((el) => {
    if (el.genericValue) {
      return {
        key: el.key,
        unit: el.genericValue.unit,
        value: el.genericValue.value,
        uom: el.genericValue.uom,
        valueType: 'GenericValueType',
      };
    }
    return {
      key: el.key,
      value: el.value,
    };
  });
  return tooltipFormatter(formattedRows);
};

function debounce<F extends (...args: any[]) => any>(func: F, wait = 0) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function(...args: Parameters<F>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    return new Promise<ReturnType<F>>((resolve, reject) => {
      timeoutId = setTimeout(() => {
        try {
          resolve(func(...args));
        } catch (e) {
          reject(e);
        }
      }, wait);
    });
  };
}

const MultiLineChart = (props: PropsIn) => {
  const {
    athleteSessionId, templateId, currentDrill, zones, series, events, hideLegend, plotLinesY,
    updateSeries, setTrackExportData, setActiveSeries,
  } = props;

  const { t } = useTranslation();
  const client = useApolloClient();
  const [queryParams, setQueryParams, prevParams] = useQueryParams();
  const { filters, setFilters } = React.useContext(GlobalFiltersContext);
  const { setCursor } = React.useContext(CursorContext);
  const isMounted = useRef(0);
  const isChartInited = useRef(false);
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const synchronize = debounce((x: number | undefined) => {
    setCursor(x);
  }, 1);

  const [serieData, setSerieData] = useState<{[k:string]: any[]}>({});
  const [serieUom, setSerieUom] = useState<{[k:string]: string}>({});
  const [seriesUnit, setSeriesUnit] = useState<{[k:string]: string}>({});
  const [serieLoading, setSerieLoading] = useState<{[k:string]: boolean}>({});
  const [seriesZones, setSeriesZones] = useState({});
  const [eventData, setEventData] = useState<{[k:string]: any[]}>({});
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({});

  useEffect(() => {
    const handleReplaceHrDataCompleted = () => {
      updateSeries([]);
      setTimeout(() => updateSeries(['cardio']), 250);
    };
    window.addEventListener('hrDataChanged', handleReplaceHrDataCompleted);
    return () => {
      window.removeEventListener('hrDataChanged', handleReplaceHrDataCompleted);
    };
  }, []);

  const yAxisTitleStyle = {
    margin: 20,
    rotation: -90,
    style: {
      fontFamily: 'var(--font-family-main)',
      color: 'var(--secondary-color)',
    },
  };

  useQueryCached(
    SERIES_ZONES_DATA(
      zones && zones.length > 0 ? zones.map((z) => getSerieTranslation(z)) : ['null'],
    ),
    {
      variables: {
        templateId,
        id: athleteSessionId,
      },
      ...CACHE_AND_NETWORK,
      skip: !(zones && zones?.length > 0),
      onCompleted(serie) {
        let updatedSerie;
        if (zones[0] === 'relativeSpeed') {
          updatedSerie = JSON.parse(JSON.stringify(serie));
          const maxValueSpeed = updatedSerie.relativeSpeed.maxValuesSpeed.upperBound.value;
          // convert % values in numeric for relative speed zones:
          updatedSerie.relativeSpeed.relativeSpeedZones.forEach((obj) => {
            if (obj.lowerBound.value !== null) {
              obj.lowerBound.value = (obj.lowerBound.value * maxValueSpeed) / 100;
            }
            if (obj.upperBound.value !== null) {
              obj.upperBound.value = (obj.upperBound.value * maxValueSpeed) / 100;
            }
          });
        } else {
          updatedSerie = serie;
        }
        setSeriesZones(updatedSerie);
      },

    },
  );

  useEffect(() => {
    if (series) {
      const serieToDelete = Object.keys(serieData).filter((key) => !series.includes(key));
      const newData = { ...serieData };

      serieToDelete.forEach((key) => {
        delete newData[key];
      });

      setSerieData(newData);
    }

    if (series?.length > 0) {
      for (const serieSrc of series) {
        const serieName = getSerieTranslation(serieSrc);
        const serie = serieName === 'dec' ? 'acc' : serieName;
        if (!serieData[serie]) {
          setSerieLoading({ ...serieLoading, [serie]: true });
          client
            .query({
              query: SERIES_DATA([serie]),
              variables: {
                athleteSessionId,
                drill: currentDrill,
              },
              fetchPolicy: 'network-only',
            })
            .then(({ data }) => {
              setSerieData({
                ...serieData,
                [serie]: data[serie]?.data && isJsonString(data[serie].data) ? JSON.parse(data[serie].data) : [],
              });
              setSerieUom({
                ...serieUom,
                [serie]: data[serie]?.yAxis?.lowerBound?.uom,
              });
              setSeriesUnit({
                ...seriesUnit,
                [serie]: data[serie]?.yAxis?.lowerBound?.unit,
              });
            }).catch((e) => {
              console.log('error', serie, e);
            }).finally(() => {
              setSerieLoading((prevState) => ({ ...prevState, [serie]: false }));
            });
        }
      }
    }
  }, [series, athleteSessionId]);

  useEffect(() => {
    const newData = { ...eventData };
    const eventsToDelete = Object.keys(newData).filter((s) => !events.includes(s));

    eventsToDelete.forEach((e) => {
      delete newData[e];
    });

    setEventData(newData);

    if (events?.length > 0) {
      const localNewData = { ...newData };
      events.forEach((event) => {
        if (!newData.hasOwnProperty(event)) {
          // setSerieLoading({...serieLoading, [event]: true})

          client.query({
            query: EVENTS_DATA([event]),
            variables: {
              athletesessionId: athleteSessionId,
              drill: currentDrill,
            },
            fetchPolicy: 'network-only',
          }).then(({ data }) => {
            setEventData({
              ...localNewData,
              [event]: data[event],
            });

            localNewData[event] = data[event];
            if (updateSeries && !series?.includes(data[event].onSeries)) {
              updateSeries([
                ...series,
                ...[data[event].onSeries],
              ]);
            }
            //  setSerieLoading({...serieLoading, [event]: false})
          }).catch((e) => {
            console.log(e);
          });
        }
      });
    }
  }, [events]);

  useEffect(() => {
    if (updateSeries && zones?.length > 0) {
      const tmpSeries = [...series];
      // include speed as serie for relative_speed zones (don't add relativeSpeed to the series array)
      if (zones.includes('relativeSpeed')) {
        tmpSeries.push('speed');
      }
      updateSeries([
        ...tmpSeries,
        ...zones.filter((s) => !series.includes(s) && s !== 'relativeSpeed'),
      ]);
    }
  }, [zones]);

  const serieNames = Object.keys(serieData) || [];

  useEffect(() => {
    if (Object.keys(serieLoading).find((s) => serieLoading[s] === true) !== undefined) {
      if (chartComponentRef.current) {
        chartComponentRef.current.chart.showLoading();
      }
    } else if (chartComponentRef.current) {
      chartComponentRef.current.chart.hideLoading();
    }
  }, [serieLoading]);

  const [firstVisibileSerie, setFirstVisibleSerie] = useState<undefined | string>(chartComponentRef.current?.chart.series.find((s) => s.visible && s.type === 'line')?.name);
  const handleLegendClick = useCallback((t) => {
    setTimeout(() => {
      const vSerie = t.chart.series.find((s) => s.visible && s.type === 'line')?.name;
      setFirstVisibleSerie(vSerie ? getSerieTranslation(vSerie) : undefined);
    }, 0);
  }, []);

  useEffect(() => {
    setChartOptions({
      credits: {
        enabled: false,
      },
      time: {
        // @todo portare in configurazione generale?
        timezone: 'UTC',
        useUTC: true,
      },
      chart: {
        animation: false,
        zooming: {
          type: 'x',
        },
        type: 'stockChart',
        events: {
          render() { // @todo optimize
            if (!isChartInited.current && queryParams.start && queryParams.end) {
              isChartInited.current = true;
              setTimeout(() => {
                const min = parseInt(queryParams.start, 10);
                const max = parseInt(queryParams.end, 10);
                setFilters({
                  min: parseInt(min, 10),
                  max: parseInt(max, 10),
                });
                if (setTrackExportData) {
                  setTimeout(() => {
                    setTrackExportData((prev) => ({
                      ...prev,
                      start_ts: min,
                      end_ts: max,
                    }));
                  }, 0);
                }
                if (setActiveSeries) {
                  setTimeout(() => {
                    setActiveSeries((prev) => ({
                      ...prev,
                      start_ts: min,
                      end_ts: max,
                    }));
                  }, 0);
                }
                chartComponentRef.current?.chart.xAxis.forEach((axis) => {
                  if (axis.height !== 40) { // zoom su grafico ma non su navigator per non perder gli estremi
                    axis.setExtremes(min, max);
                  }
                });
              }, 500);
            }
          },
        },
      },
      legend: {
        enabled: !hideLegend,
      },
      rangeSelector: {
        enabled: false,
      },
      boost: {
        useGPUTranslations: true,
      },
      xAxis: {
        type: 'datetime',
        min: filters.min,
        max: filters.max,
        showEmpty: false,
        events: {
          afterSetExtremes(event) {
            if (event.userMin && event.userMax) {
              setQueryParams({
                ...prevParams,
                ...{
                  start: event.userMin,
                  end: event.userMax,
                },
              });
              setFilters({
                min: Math.floor(event.userMin),
                max: Math.ceil(event.userMax),
              });
              if (setTrackExportData) {
                setTimeout(() => {
                  setTrackExportData((prev) => ({
                    ...prev,
                    start_ts: Math.floor(event.userMin),
                    end_ts: Math.ceil(event.userMax),
                  }));
                }, 0);
              }
              if (setActiveSeries) {
                setTimeout(() => {
                  setActiveSeries((prev) => ({
                    ...prev,
                    start_ts: Math.floor(event.userMin),
                    end_ts: Math.ceil(event.userMax),
                  }));
                }, 0);
              }
            }
          },
        },
        scrollbar: {
          enabled: false,
          showFull: true,
        },
      },
      plotOptions: {
        line: {
          animation: {
            duration: 0,
          },
        },
        series: {
          showInNavigator: true,
          allowPointSelect: true,
          boostThreshold: 100,
          animation: false,
          point: {
            events: {
              mouseOver() {
                synchronize(this.category);
              },
            },
          },
          events: {
            legendItemClick() {
              handleLegendClick(this);
              return true;
            },
          },
        },
      },
      tooltip: {
        ...tooltipStyle,
        valueDecimals: 2,
        split: false,
        enabled: true,
        shared: true,
        formatter() {
          if (this.series?.options?.type === 'flags') {
            return this.point.options.text;
          }
          const kpiLine = (finalString, pt) => {
            const tooltipArray = [{
              key: pt.series.name,
              value: pt.y,
              uom: pt.series.userOptions.uom,
              unit: pt.series.userOptions.unit,
              valueType: 'CustomValueType',
            }] as TooltipKeys[];
            return `${finalString}<br/>${tooltipFormatter(tooltipArray)}`;
          };
          return this.points?.reduce(
            (s, point) => `${kpiLine(s, point)}`,
            `${t('table.time', 'time')}<span style="fontFamily: var(--font-family-alt); font-weight: 600;">: ${(new Date(this.x).toISOString().slice(11, 19))}</span>`,
          ) || '';
        },
      },
      series: [] as SeriesXrangeOptions[],
      scrollbar: {
        liveRedraw: false,
      },
    });
  }, []);

  const getFlagFillColor = (group) => {
    if (!group) {
      return '#ffffff';
    }
    return eventsColorByName[group];
  };

  const getFlagColor = (seriesName, flagName) => {
    if (['dives', 'jumps'].includes(seriesName)) {
      return `<p style="color: #ffffff;">${flagName}</p>`;
    }
    return flagName;
  };

  useEffect(() => {
    if (isMounted.current) {
      setChartOptions((prevOptions) => (
        {
          ...prevOptions,
          ...{
            yAxis: [
              ...serieNames.length > 0
                ? serieNames.reduce((acc, serie) => {
                  const existingItemIndex = acc.findIndex((s) => s.id === `serie-${serieUom[serie] || serie}`) > -1 && serie !== 'power_aer';
                  if (!existingItemIndex) {
                    acc.push({
                      showEmpty: false,
                      title: {
                        text: `${serieUom[serie] || ''}`,
                        x: 15,
                        offset: 50,
                        ...yAxisTitleStyle,
                      },
                      gridLineWidth: zones.length > 0 ? 0 : 1,
                      labels: {
                        formatter() {
                          return `<span>${this.value}</span>`;
                        },
                      },
                      plotLines: serie === 'power_aer' && plotLinesY || [],
                      id: `serie-${serieUom[serie] || serie}`,
                      opposite: false,
                      max: undefined,
                      plotBands: seriesZones && seriesZones[zones[0]]
                        && seriesZones[zones[0]][`${zones[0]}Zones`].map((z) => ({
                          color: seriesColors[zones[0]]
                            ? generateColor(seriesColors[zones[0]], '#ffffff', 6, null, '66')[z.thresholdIndex] : undefined,
                          from: z.lowerBound.value || 0,
                          to: z.upperBound.value || 9999,
                          style: {
                            opacity: 0.5,
                          },
                        })) || [],
                    });
                  }

                  return acc;
                }, [] as Highcharts.YAxisOptions[])
                : [],
            ],
            series: [
              ...serieNames.length > 0
                ? serieNames.map((serie) => ({
                  data: serieData[serie],
                  type: 'line',
                  name: t(`series.${serie}.label`, { defaultValue: serie }),
                  color: seriesColors[serie],
                  id: serie,
                  // @todo disabiliato per ora, valutare se accedendo alle api si può identificare meglio la serie attiva
                  // showInNavigator: firstVisibileSerie && firstVisibileSerie === serie,
                  yAxis: `serie-${serieUom[serie] || serie}`,
                  uom: serieUom[serie],
                  unit: seriesUnit[serie],
                  tooltip: {
                    pointFormat: '<span style="color:{serie.color}">{serie.name}</span>: <span style="font-family: var(--font-family-alt); font-weight: 600;">{point.y}</span><br/>',
                  },
                  dataGrouping: {
                    approximation: serieApproximation(serie),
                  },
                }))
                : [],
              ...(eventData && Object.keys(eventData)
                .filter((serie) => serieData[eventData[serie]?.onSeries])
                .map((serie) => {
                  return {
                    data: eventData[serie]?.data.map((d) => ({
                      x: d.x,
                      title: d.genericValues[0].key === 'brake' ? `<p> BR${d.title.slice(1)} </p>` : getFlagColor(eventData[serie].name, d.title),
                      text: createEventTooltip(d, eventData[serie].name, eventData[serie].keyOrder),
                      fillColor: ['dives', 'jumps'].includes(eventData[serie]?.name) ? getFlagFillColor(d.group) : '#ffffff',
                    })) || [],
                    type: 'flags',
                    showInLegend: true,
                    name: t(`series.${serie}.label`, {defaultValue: serie}),
                    onSeries: eventData[serie]?.onSeries,
                    // linkedTo: eventData[serie]?.onSeries,
                    shape: 'squarepin',
                    yAxis: `serie-${serieUom[eventData[serie]?.onSeries] || eventData[serie]?.onSeries}`,
                    allowOverlapX: true,
                    // yAxis: `serie-${serieUom[serie] || serie}`,
                    color: eventData[serie] ? seriesColors[eventData[serie].onSeries] : undefined,
                  }
                })) || [],
            ] as SeriesXrangeOptions[],
          },
        }
      ));
    } else {
      isMounted.current += 1;
    }
  }, [serieData, serieUom, seriesUnit, eventData, seriesZones, zones, firstVisibileSerie]);
  return (
    <HighchartsReact
      highcharts={highchartsStock}
      options={chartOptions}
      constructorType="stockChart"
      ref={chartComponentRef}
    />
  );
};

export { MultiLineChart };
