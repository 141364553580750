import React from 'react';
import BaseButton from 'components/form/BaseButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@images/IconHeader/cancel.svg';
import { useTranslation } from 'react-i18next';
import { Row } from 'components/layout/Row';

export interface DialogTitleProps {
  id?: string;
  onClose: () => void;
  children?: React.ReactNode;
}

export interface DialogContentProps {
  id?: string;
  content?: string | Element;
  children?: React.ReactNode;
}

export interface DialogActionsProps {
  buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary' | 'danger';
  buttonCancel?: string,
  buttonAction?: string;
  buttonActionClassName?: string;
  icon: React.ReactNode;
  disabledButton?: boolean;
  isValidation?: boolean;
  onClick?: () => void;
  onClose: () => void;
  children?: React.ReactNode;
  noCancel?: boolean;
}

type PropsIn = {
  title: string;
  content?: string | Element;
  visible: boolean;
  onClick?: () => void;
  onClose: () => void;
  buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary' | 'danger';
  buttonCancel?: string;
  buttonAction?: string;
  buttonActionClassName?: string;
  icon: React.ReactNode;
  disabledButton?: boolean;
  isValidation?: boolean;
  children?: React.ReactNode;
  buttons?: React.ReactNode;
  noCancel?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    '& .MuiPaper-root': {
      minHeight: '250px',
      minWidth: '400px',
    },
    '& .MuiDialog-paperWidthSm': {
      overflow: 'hidden',
    },
    '& .MuiDialogActions-spacing': {
      backgroundColor: 'var(--border-disabled)',
      paddingRight: '3rem',
    },
    '& .MuiDialogContent-root': {

      margin: '0.5rem 0 2rem 0',
    },
    '& .MuiBadge-root': {
      marginRight: '15px',
    },
  },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      <IconButton
        id="modal-close-icon"
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

function BootstrapDialogContent(props: DialogContentProps) {
  const { children, content, ...other } = props;

  return (
    <DialogContent {...other}>
      {content
      && (
      <Typography gutterBottom>
        {content}
      </Typography>
      )}
      {children}
    </DialogContent>
  );
}

function BootstrapDialogActions(props: DialogActionsProps) {
  const {
    buttonCancel,
    buttonAction,
    buttonActionClassName,
    buttonColor,
    icon,
    disabledButton,
    isValidation,
    onClick,
    onClose,
    children,
    noCancel,
  } = props;

  const { t } = useTranslation();

  if (buttonAction) {
    return (
      <DialogActions>
        {!noCancel ? (
          <Row className="modal-buttons-box">
            <BaseButton
              key="cancel"
              buttonSize="sm"
              startIcon={<CancelIcon />}
              onClick={onClose}
            >
              {buttonCancel || t('dialog.action.cancel', 'cancel')}
            </BaseButton>
            <BaseButton
              buttonSize="sm"
              color={buttonColor || 'primary'}
              disabled={disabledButton}
              isValidation={isValidation}
              autoFocus
              onClick={onClick}
              startIcon={icon || undefined}
            >
              {buttonAction}
            </BaseButton>
          </Row>
        )
          : (
            <BaseButton
              className={buttonActionClassName}
              buttonSize="sm"
              color={buttonColor || 'primary'}
              disabled={disabledButton}
              isValidation={isValidation}
              autoFocus
              onClick={onClick}
              startIcon={icon || undefined}
            >
              {buttonAction}
            </BaseButton>

          )}
      </DialogActions>
    );
  }

  return <DialogActions>{children}</DialogActions>;
}

export default function Modal(props: PropsIn) {
  const {
    title,
    content,
    visible,
    onClick,
    onClose,
    buttonColor,
    buttonCancel,
    buttonAction,
    buttonActionClassName,
    icon,
    disabledButton,
    isValidation,
    children,
    buttons,
    noCancel,
  } = props;

  const classes = useStyles();

  return visible && (
    <div>
      <Dialog
        className={classes.root}
        aria-labelledby="dialog"
        open={visible}
        onClose={onClose}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </BootstrapDialogTitle>
        <hr />
        <BootstrapDialogContent content={content}>
          {children}
        </BootstrapDialogContent>
        <BootstrapDialogActions
          buttonCancel={buttonCancel}
          buttonAction={buttonAction}
          buttonActionClassName={buttonActionClassName}
          buttonColor={buttonColor}
          icon={icon}
          disabledButton={disabledButton}
          isValidation={isValidation}
          onClick={onClick}
          onClose={onClose}
          noCancel={noCancel}
        >
          {buttons}
        </BootstrapDialogActions>
      </Dialog>
    </div>
  ) || null;
}
