import React, { useState } from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ConfirmIcon from '@images/icons/svg/confirm.svg';
import { useApolloClient } from '@apollo/client';
import { clearCachePersist } from 'storage/dataDbCache';
import { useTranslation } from 'react-i18next';
import DialogBox from './DialogBox';
import { IconWrapper } from '../lib/icons';

type PropsIn = {
    iconName?: string;
    title: string;
    iconSize?: string;
    text?: {textBefore:string; textSpecial:string; textAfter: string} | string
    underlineText?: boolean;
    linkTo?: string;
    academyLink?: string;
    width?: string;
    height?: string;
    noMargins?: boolean;
    isUnorderedList?:boolean;
    bulletPoints?: true | number[];
    nLineSpace?: number;
    classNamePaper?: string;
    classNameMessage?: string;
    clearCacheButton?: boolean;
    colorPalette?: string;
}

export default function PageMessage(props:PropsIn) {
  const {
    iconName, title, iconSize, text, underlineText, linkTo, academyLink, noMargins, width, height, isUnorderedList, bulletPoints,
    nLineSpace, classNamePaper, classNameMessage, clearCacheButton, colorPalette,
  } = props;

  const { t } = useTranslation();
  const ulText = text && typeof text === 'string' && text?.split('.');
  const client = useApolloClient();
  const [clearStarted, setClearStarted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const clearCacheAll = () => {
    setClearStarted(true);
    client.cache.reset(); // clear Apollo client cache
    localStorage.clear(); // clear LocalStorage
    clearCachePersist()
      .then(() => { // clear IndexedDB persisting cache
        console.log('cache cleared successfully');
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('error', error);
      });
    // clears all remaining indexedDb databases
    window.indexedDB.databases()
      .then((r) => {
        for (let i = 0; i < r.length; i += 1) {
          if (r[i].name !== undefined) {
            // @ts-ignore
            window.indexedDB.deleteDatabase(r[i].name);
          }
        }
      })
      .catch((error) => {
        console.error('error', error);
      });
    setOpenModal(false);
    // restore color palette
    localStorage.setItem('userColorsPalette', String(colorPalette));
  };

  return (
    <Paper
      className={classNamePaper || ''}
      style={{ width: width || '600px', maxWidth: 'calc(100% - 8px)', height: height || 'fit-content' }}
    >
      <Box className={classNameMessage || ''} p={2} mt={noMargins ? undefined : 2}>
        <section className="pagemessage-box">
          <IconWrapper name={iconName} size={iconSize || 'extralarge'} color="primary" pointer />
          <div>
            <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>
              {title}
            </Typography>

            {!isUnorderedList && !linkTo && typeof text === 'string' ? (
              <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                {(text)}
              </Typography>
            ) : !isUnorderedList && !linkTo && !academyLink && !clearCacheButton && typeof text === 'object' ? (
              <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                {text.textBefore}
                <span style={{ textDecoration: underlineText ? 'underline' : 'none' }}>{text.textSpecial}</span>
                {text.textAfter}
              </Typography>
            ) : !isUnorderedList && !linkTo && !academyLink && clearCacheButton && typeof text === 'object' ? (
              <>
                <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                  {text.textBefore}
                  <span
                    style={{ color: '#00876F', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    {text.textSpecial}
                  </span>
                  {text.textAfter}
                </Typography>
                <DialogBox
                  title={`${t('dialog.title.warning', 'warning')}`}
                  buttonAction={t('dialog.action.confirm', 'confirm')}
                  buttonColor="primary"
                  icon={<ConfirmIcon />}
                  content={(
                        t('global.clearCache.modal', 'Are you sure you want to clear the cache?')
                      )}
                  visible={openModal}
                  onClose={() => setOpenModal(false)}
                  onClick={() => clearCacheAll()}
                />
              </>
            ) : !isUnorderedList && !linkTo && academyLink && !clearCacheButton && typeof text === 'object' ? (
              <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                {text?.textBefore}
                <span
                  style={{ color: '#00876F', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => window.open(academyLink)}
                >
                  {text?.textSpecial}
                </span>
                {text?.textAfter}
              </Typography>
            ) : !isUnorderedList && linkTo && !academyLink && !clearCacheButton && typeof text === 'object' ? (
              <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                {text?.textBefore}
                <Link style={{ color: '#00876F', textDecoration: 'underline' }} to={linkTo}>{text?.textSpecial}</Link>
                {text?.textAfter}
              </Typography>
            ) : (
              <ul style={{ listStyle: 'disc' }}>
                {ulText?.map((line, index) => (bulletPoints === true || bulletPoints?.includes(index) ? (
                  <li style={{ marginTop: nLineSpace === index ? '0.5rem' : '0.125rem' }}>
                    {`${line}.`}
                  </li>
                ) : (
                  <p style={{ marginTop: '0.125rem' }}>{`${line}.`}</p>
                )))}
              </ul>
            )}

          </div>
        </section>
      </Box>
    </Paper>
  );
}
