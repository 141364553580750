import { Box } from '@material-ui/core';
import { Layer, Stage } from 'react-konva';
import React, { forwardRef } from 'react';
import { getOuterGroundColor, GroundType } from '../../models/ground';
import { Pitch } from '../track/ground/Ground';
import PlayersLinks from './PlayersLinks';
import PlayersMarkers from './PlayersMarkers';
import ZoomControls from './ZoomControls';
import { PlayersColors, SeriesState } from './WebPlayer';
import { PlayersDetails } from './PlayersTable';

export type PlayerLines = {
  start: [number, number],
  end: [number, number],
  distance: number,
}[]

export interface GroundFieldProps {
  activePlayer?: string | null
  hoverPlayer?: string | null
  children?: React.ReactNode;
  cursor: any;
  defaultPlayersColor?: string;
  enabledPlayers?: Array<string>;
  jerseyOrNumber?: boolean;
  onPlayerClick?: (playerID: string) => void;
  onPlayerDblClick?: (playerID: string) => void;
  onPlayerEnter?: (playerID: string) => void;
  onPlayerLeave?: (playerID: string) => void;
  playersColors?: PlayersColors;
  playersDetails?: PlayersDetails;
  playersLines?: PlayerLines;
  removeLink?: (idx: number) => void;
  series?: SeriesState;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  showVertices?: boolean;
  trailsEnabled?: boolean;
}

export interface GroundFieldData {
  dataGround?: GroundType;
  offX: number;
  offY: number;
  scale: number;
  WINDOW_WIDTH: number;
  WINDOW_HEIGHT: number;
  zoom: number;
}

const GroundField = forwardRef<HTMLDivElement, GroundFieldProps & GroundFieldData>((props, parentRef) => {
  const {
    activePlayer,
    hoverPlayer,
    children,
    cursor,
    defaultPlayersColor,
    dataGround,
    enabledPlayers,
    jerseyOrNumber,
    offX,
    offY,
    onPlayerClick,
    onPlayerDblClick,
    onPlayerEnter,
    onPlayerLeave,
    playersColors,
    playersDetails,
    playersLines,
    removeLink,
    scale,
    series,
    setZoom,
    showVertices,
    trailsEnabled,
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
    zoom,
  } = props;

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="center" width="100%">
        <Box
          bgcolor={getOuterGroundColor(dataGround?.groundSurfaceColor)}
          position="relative"
          width="100%"
        >
          <div
            className="player__wrapper"
            ref={parentRef}
            style={{
              width: '100%',
              height: 'auto',
              aspectRatio: '16/9',
            }}
          >
            <Stage
              id="container"
              offsetX={offX}
              offsetY={offY}
              draggable
              width={WINDOW_WIDTH}
              height={WINDOW_HEIGHT}
              scaleX={zoom}
              scaleY={zoom}
            >
              <Layer listening={false} id="layer-pitch">
                <Pitch
                  defaultGround={false}
                  groundData={dataGround}
                  scale={scale}
                  vertices={showVertices}
                />
              </Layer>

              {
                playersLines
                && removeLink
                && (
                  <PlayersLinks
                    playersLines={playersLines}
                    removeLink={removeLink}
                    groundSurfaceColor={dataGround?.groundSurfaceColor}
                  />
                )
              }

              {
                enabledPlayers
                && onPlayerClick
                && playersDetails
                && series
                && playersColors
                && (
                  <PlayersMarkers
                    activePlayer={activePlayer || null}
                    hoverPlayer={hoverPlayer || null}
                    cursor={cursor}
                    defaultPlayersColor={defaultPlayersColor}
                    enabledPlayers={enabledPlayers}
                    groundSurfaceColor={dataGround?.groundSurfaceColor}
                    jerseyOrNumber={!!jerseyOrNumber}
                    onPlayerClick={onPlayerClick}
                    onPlayerDblClick={onPlayerDblClick}
                    onPlayerEnter={onPlayerEnter}
                    onPlayerLeave={onPlayerLeave}
                    playersColors={playersColors}
                    playersDetails={playersDetails}
                    scale={scale}
                    series={series}
                    trailsEnabled={!!trailsEnabled}
                  />
                )
              }

              {children}
            </Stage>

            <ZoomControls setZoom={setZoom} zoom={zoom} />
          </div>
        </Box>
      </Box>
    </Box>
  );
});

export default GroundField;
