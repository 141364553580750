import { Group, Layer } from 'react-konva';
import React from 'react';
import { getGroundTextColor } from 'models/ground';
import Path from './Path';
import Marker from './Marker';
import { DEFAULT_GROUND_COLOR, PLAYER_MARKER_COLOR } from '../track/ground/constants';
import { PlayersMarkersProps } from 'types/webPlayer';

const PlayersMarkers = (props: PlayersMarkersProps) => {
  const {
    activePlayer,
    hoverPlayer,
    cursor,
    enabledPlayers,
    jerseyOrNumber,
    onPlayerClick,
    onPlayerDblClick,
    onPlayerEnter,
    onPlayerLeave,
    playersColors,
    playersDetails,
    playersExtraData,
    scale,
    series,
    trailsEnabled,
    defaultPlayersColor,
    groundSurfaceColor,
  } = props;

  return (
    <Layer id="layer-players-markers">
      {
        enabledPlayers
          .map((playerID) => (
            series[playerID] && (
            <Group
              key={playerID}
            >
              <Marker
                active={activePlayer === playerID}
                hover={hoverPlayer === playerID}
                color={playersColors[playerID] || defaultPlayersColor || PLAYER_MARKER_COLOR}
                textColor={groundSurfaceColor === 'rgb(0,221,181)' || groundSurfaceColor === DEFAULT_GROUND_COLOR
                  ? '#004d41'
                  : '#fff'}
                cursor={cursor}
                id={playerID}
                onClick={onPlayerClick}
                onDblClick={onPlayerDblClick}
                onMouseEnter={onPlayerEnter}
                onMouseLeave={onPlayerLeave}
                pathData={series[playerID].pathData}
                extraData={playersExtraData ? playersExtraData[playerID] : null}
                scale={scale}
                text={
                  playersDetails[playerID]
                    ? String(playersDetails[playerID][jerseyOrNumber ? 'shortName' : 'number'] || '---')
                    : '---'
                }
              />
              {
                trailsEnabled
                && (
                <Path
                  cursor={cursor}
                  pathData={series[playerID].pathData}
                  scale={scale}
                  pathColor={getGroundTextColor(groundSurfaceColor)}
                />
                )
              }
            </Group>
            ) || null
          ))
      }
    </Layer>
  );
};

export default PlayersMarkers;
