import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatColor } from 'components/utils/utils';
import { Loader } from 'lib/icons';
import BaseChip from './BaseChip';

export type OptionType = {
  color?: string;
  disabled?: boolean;
  groupBy?: string;
  id: string;
  value: string;
  label?: string;
  image?: any;
};

export interface PropsInCommon {
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  maxNumberItems?: number;
  onChange: (value: OptionType[] | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  loading?: boolean;
  options: OptionType[];
  singleSelection?: boolean;
  disableEnter?: boolean;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
}

interface PropsInSimple {
  autocomplete?: false;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string;
}

export interface PropsInAutocomplete {
  autocomplete: true;
  canAddNewItems?: boolean;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  id: string;
  loading?: boolean;
  multiple?: boolean;
  naked?: boolean;
  onChange: (value: OptionType[] | string[]) => void;
  type?: 'none' | 'checkbox' | 'radio';
  value?: OptionType[];
}

type ISelectField = PropsInCommon & PropsInSimple | PropsInCommon & PropsInAutocomplete;

export const SelectField = (props: ISelectField) => {
  const {
    autocomplete,
    // canAddNewItems,
    disabled,
    // disableClearable,
    // disableCloseOnSelect,
    fullWidth,
    label,
    loading,
    maxNumberItems,
    // multiple,
    singleSelection,
    onChange,
    options,
    placeholder,
    readonly,
    required,
    // type,
    value,
    variant,
    disableEnter,
  } = props;

  const { t } = useTranslation();

  return autocomplete ? (
    <Autocomplete
      className={`select-field${disabled && ' disabled' || ''}${readonly ? ' readonly' : ''}${props.naked ? ' input-naked' : ''}`}
      freeSolo={props.canAddNewItems}
      clearOnBlur={props.canAddNewItems}
      handleHomeEndKeys={props.canAddNewItems}
      selectOnFocus={props.canAddNewItems}
      loading={loading}
      value={value}
      multiple={!singleSelection}
      disabled={disabled ? true : maxNumberItems ? value && value.length > maxNumberItems : disabled}
      aria-readonly={readonly}
      open={readonly ? false : undefined}
      getOptionSelected={(option: OptionType, v: OptionType) => option.id === v.id}
      getOptionDisabled={(option) => !!option.disabled}
      disableClearable={readonly || props.disableClearable}
      disableCloseOnSelect={
        (props.disableCloseOnSelect || props.multiple) && (maxNumberItems ? value && value.length < maxNumberItems : true)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant || 'outlined'}
          fullWidth={fullWidth}
          label={label}
          required={required}
          placeholder={
            (!loading && (value && value.length === 0) ? placeholder && placeholder.length ? placeholder : t('form.datePicker.defaultPlaceholder', 'type or choose') : '')
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      groupBy={(option: OptionType) => (option.groupBy ? option.groupBy : '')}
      renderOption={(option: OptionType) => (
        <div className="select-field__option">
          {props.type === 'checkbox' && (
            <Checkbox
              checked={value && value.some((e) => e.id === option.id)}
              color="primary"
              disabled={option.disabled}
            />
          )}
          {props.type === 'radio' && (
            <Radio
              checked={value && value.some((e) => e.id === option.id)}
              color="primary"
              disabled={option.disabled}
            />
          )}
          <div className="select-field__value">
            {option.color && (
              <div
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: '100%',
                  backgroundColor: formatColor(option.color),
                }}
              />
            )}
            <span>{option.value}</span>
          </div>
        </div>
      )}
      renderTags={(v: OptionType[]) => {
        if (loading) return <Loader className="select-field-loading" />;
        if (!props.multiple) {
          return (
            <div className="select-field__tags">
              <span>{v[0].value}</span>
              {v[0].color && (
                <div
                  className="select-field__tags__bg"
                  style={{
                    backgroundColor: v[0].color ? `#${v[0].color.substring(1)}` : '',
                  }}
                />
              )}
            </div>
          );
        }
        if (v) {
          return v.map((option) => (
            <BaseChip
              key={option.value}
              label={option.value}
              disabled={disabled || readonly}
              onDelete={disabled || readonly ? undefined : value ? () => onChange(value.filter((val) => val.id !== option.id)) : undefined}
              hasBackground={option.color ? `#${option.color.substring(1)}` : undefined}
            />
          ));
        }

        return null;
      }}
      options={options}
      getOptionLabel={(opt: OptionType) => (opt.value)}
      onChange={(e, v) => {
        if (disableEnter && e?.key === 'Enter') {
          return;
        }
        onChange(v as OptionType[]);
      }}
    />
  ) : (
    <TextField
      className={`select-field${disabled && ' disabled' || ''}${readonly ? ' readonly' : ''}${props.naked ? ' input-naked' : ''}`}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        readOnly: readonly,
      }}
      fullWidth={fullWidth}
      label={label}
      onChange={(e) => onChange(e)} // @todo fix event or value
      required={required}
      select
      value={value}
      variant={variant || 'outlined'}
      disabled={disabled}
      aria-readonly={readonly}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {loading ? <Loader /> : options?.map((item) => (
        <MenuItem disabled={item.disabled} key={item.id} value={item.value}>
          <Box
            style={{
              display: 'flex',
              gap: '.5rem',
              alignItems: 'center',
            }}
          >
            {item.color && (
              <div
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: '100%',
                  backgroundColor: formatColor(item.color),
                }}
              />
            )}
            {item.image}
            {item.label || item.value}
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
};
