/* eslint-disable react/jsx-closing-bracket-location */
import {
  Box,
} from '@material-ui/core';
import { Row } from 'components/layout/Row';
import React, {
  useState, useMemo, useEffect, ChangeEvent, useRef, useCallback,
} from 'react';
import { Column } from 'components/layout/Column';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { DEFAULT_GROUND_COLOR, PLAYER_MARKER_COLOR } from 'components/track/ground/constants';
import { CLUB, ClubTypeRes, ClubTypeVars } from 'query/club';
import { GroundType, getPairedGroundColor } from 'models/ground';
import { useLazyQueryCached, useQueryCached } from '../utils/graphql';
import { TeamSessionsType } from '../../models/team_session';
import { TeamSessionsVars, SESSION, TEAMSESSION_ATHLETES_SESSIONS, TeamSessionType } from '../../query/session';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import { rotate, flipPoint, computeAngle } from '../track/ground/utils';
import { CursorContext } from '../track/TrackChartContainer';
import PlayerControls from './PlayerControls';
import PlayersTable from './PlayersTable';
import ErrorGuard from '../layout/ErrorGuard';
import WebPlayerHeader from './WebPlayerHeader';
import { LPSFromGPSPath } from '../../lib/geoNew';
import Ground from './Ground';
import { webplayerWorkerNew } from '../../workers';
import { ViewerDataTuple } from '../../query/track';
import { checkNearEndOfInterval, FpsCtrl } from './utils';
import {
  DrillTimesType, MarkDrill,
  PlayerDataInterval,
  PlayerDataIntervals,
  PlayersDetails,
  PlayersExtraData,
  SeriesState,
  STATUS_TEXT,
  WebPlayerProps
} from '../../types/webPlayer';
import { OptionType } from '../Autocomplete';

// @todo verifica se il timestamp del cursore va oltre il limite della traccia

const timeFormat = 'H:mm:ss';
const valueLabelFormat = ( value: number, _: number ) => format(value, timeFormat);
const loadingTimeFrame = 300_000; // 5 minuti
const FPS = 15;
const maxSamplesPerFrame = 5;

const keyboadEventHandler = (
  event: KeyboardEvent,
  keyCallbacks: {key: string, callbackFn: Function, ctrlKey?:boolean, altKey?: boolean, shiftKey?: boolean, metaKey?: boolean}[]
) => {
  const callback = keyCallbacks.find(( k ) => k.key === event.code && !!k.ctrlKey === event.ctrlKey && !!k.altKey === event.altKey && !!k.metaKey === event.metaKey && !!k.shiftKey === event.shiftKey
  );

  if (callback) {
    callback.callbackFn();
  }
}

const WebPlayer = ( props: WebPlayerProps ) => {
  const {
    currentDrill,
    defaultGround,
    sessionGround,
    sessionId,
    showVertices,
    templateId,
    teamId,
  } = props;

  const [currentActivePlayer, setCurrentActivePlayer] = useState<null | string>(null);
  const [currentHoverPlayer, setCurrentHoverPlayer] = useState<null | string>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [enabledPlayers, setEnabledPlayers] = useState<Array<string>>([]);
  const [ground, setGround] = useState<GroundType | undefined>(sessionGround);
  const [intervals, setIntervals] = useState<PlayerDataIntervals>([]);
  const [loadingNextData, setLoadingNextData] = useState<string[]>([]);
  const [jerseyOrNumber, setJerseyOrNumber] = useState(true);
  const [loadingPlayers, setLoadingPlayers] = useState<Array<string>>([]);
  const [marks, setMarks] = useState<MarkDrill[]>([]);
  const [playerSpeed, setPlayerSpeed] = useState(1); // ms tra i frame (1/25)
  const [playersColors, setPlayersColors] = useState<Record<string, string>>({});
  const [series, setSeries] = useState<SeriesState>({});
  const [status, setStatus] = useState(STATUS_TEXT.INIT);
  const [tickThrottle, setTickThrottle] = useState(false);
  const [timestamps, setTimestamps] = useState<number[]>([]);
  const [timestampsToAdd, setTimestampsToAdd] = useState<number[]>([]);
  const [trailsEnabled, setTrailsEnabled] = useState(false);
  const [workerIsReady, setWorkerIsReady] = useState(false)

  const workerCheckerRef = useRef<any>(null);

  const playerRef = React.useRef <null | HTMLDivElement>(null);
  const currDrillRef = useRef(1);
  const currStepRef = useRef(1);
  const currPlayerSpeedRef = useRef(1);
  const fpsCtrlRef = useRef<any>();
  const lastRequestedTimestamp = useRef<number | null>(null);
  const lastPlayerStatusIsPlaying = useRef(false);

  useEffect(() => {
    currPlayerSpeedRef.current = playerSpeed;
    if (fpsCtrlRef.current) {
      let wasPlaing = fpsCtrlRef.current.isPlaying;
      if (wasPlaing) {
        handlePlayClick();
      }

      fpsCtrlRef.current.setMultiplier(playerSpeed);

      if (wasPlaing) {
        handlePlayClick(true);
      }
    }
  }, [playerSpeed]);

  useEffect(() => {
    if (timestamps.length > 0) {
      const wasPlaying = fpsCtrlRef.current?.isPlaying;

      if (wasPlaying) {
        fpsCtrlRef.current.pause();
      }

      fpsCtrlRef.current = new FpsCtrl(
        FPS,
        (timeData: {frame: number}) => {
        const {frame} = timeData;

        setCurrentTime(( prevState ) => (lastRequestedTimestamp.current || prevState)
          + frame * (1 / FPS) * 1000);
      })

      if (wasPlaying) {
        fpsCtrlRef.current.start();
      }
    }
  }, [timestamps]);

  const findNearTimestamp = useCallback((ts: number, tss?: number[]) => (tss || timestamps).find((t) => (t - ts) < 500 && (t - ts) >= 0), [timestamps]);
  const findNearTimestampIndex = useCallback((ts: number, tss?: number[]) => (tss || timestamps).findIndex((t) => Math.abs(t - ts) < 1500), [timestamps]);

  const handleCurrentTimeChange = useCallback((value: number) => {
    lastRequestedTimestamp.current = value;
    setCurrentTime(value);
  },[]);

  const cursorValue = useMemo(() => ({
    cursor: findNearTimestamp(currentTime),
    setCursor: handleCurrentTimeChange,
  }), [currentTime, findNearTimestamp, handleCurrentTimeChange]);

  useEffect(() => {
    const keysEventListener = (event: KeyboardEvent) => keyboadEventHandler(event, [
      {key: 'Space', callbackFn: () => handlePlayClick()},
      {key: 'ArrowLeft', callbackFn: console.log},
      {key: 'ArrowLeft', callbackFn: console.log, shiftKey: true},
      {key: 'ArrowRight', callbackFn: fpsCtrlRef.current.stepForward},
      {key: 'ArrowRight', callbackFn: console.log, shiftKey: true},
    ]);

    document.addEventListener(
      "keydown",
      keysEventListener,
      false,
    );
    return () => {
      document.removeEventListener('keydown', keysEventListener);
    }
  }, [
    fpsCtrlRef.current?.isPlaying
  ]);

  const {
    // error,
    loading: sessionLoading,
    data,
  } = useQueryCached<{ res: TeamSessionType }, TeamSessionsVars>(SESSION, {
    variables: {
      drill: currentDrill || null,
      id: parseInt(sessionId, 10),
      templateId: parseInt(templateId, 10),
      withAthleteSession: true,
      withGround: true,
    },
    ...CACHE_AND_NETWORK,
    // returnPartialData: true,
    onCompleted: ( result ) => {
      const tracksGround = result?.res?.athleteSessions && result.res.athleteSessions.length > 0
        && result.res.athleteSessions[0] && result.res.athleteSessions[0].track?.ground;

      // @todo and not is playing OR `needs?
      if (result?.res?.startTimestamp) {
        const timezoneOffset = -new Date(result.res.startTimestamp).getTimezoneOffset() * 60000;
        // @todo lastRequestedTimestamp.current ?
        const newTimestamp = new Date(result.res.startTimestamp).getTime() + timezoneOffset;
        setCurrentTime(newTimestamp);
        lastRequestedTimestamp.current = newTimestamp;
        // setCurrentTime(timestamps[0]);
        setStatus(STATUS_TEXT.SET_START_TIME);
      }

      if (result?.res?.drills?.relatedDrills) {
        const drillsMarks: {
          value: number,
          label: string,
          index?: number,
          id?: number,
          tags?: string[],
        }[] = [];
        setStatus(STATUS_TEXT.DATA_SESSION_LOADED);
        result.res.drills.relatedDrills.forEach(( drill ) => {
          const timezoneOffset = drill?.start
            ? -new Date(drill.start).getTimezoneOffset() * 60000
            : 0;

          drillsMarks.push({
            value: drill ? (new Date(drill.start)).getTime() + timezoneOffset : 0,
            label: drill ? `${format(parseISO(drill.start), timeFormat)}` : '---',
            index: drill?.index || undefined,
            id: drill?.id || undefined,
            tags: drill?.tags || undefined,
          });

          // @todo fare in modo che il valore sia con indice e non timestamp
          drillsMarks.push({
            value: drill ? (new Date(drill.end)).getTime() + timezoneOffset : 0,
            label: drill ? `${format(parseISO(drill.end), timeFormat)}` : '---',
            index: drill?.index || undefined,
            id: drill?.id || undefined,
            tags: drill?.tags || undefined,
          });
        });

        setMarks(drillsMarks);
        setStatus(STATUS_TEXT.SET_DRILLS);
      }

      if (tracksGround) {
        setGround(tracksGround);
      }

      setStatus(STATUS_TEXT.PLAYERS_LOADING);
      // eslint-disable-next-line no-use-before-define
      loadSessions();
    },
  });


  // @todo usare webworker per non bloccare il main thread
  const [loadSessions, {
    // error: detailsError,
    data: detailsData,
    // loading: detailsLoading,
  }] = useLazyQueryCached<{ res: TeamSessionsType }, TeamSessionsVars>(TEAMSESSION_ATHLETES_SESSIONS, {
    variables: {
      drill: currentDrill || null,
      fieldsLimit: 6,
      id: parseInt(sessionId, 10),
      templateId: parseInt(templateId, 10),
    },
    fetchPolicy: 'cache-first',
    onCompleted: () => {
      setStatus(STATUS_TEXT.PLAYERS_READY);
    },
    onError: ( error ) => {
      console.error('Error fetching athletes', error);
    },
  });

  const isGPSGround = ground?.groundCoordsType !== 'LOCAL';

  const currentGround = !isGPSGround
    ? ground
    : ground || sessionGround;

  const athSess = detailsData?.res.athleteSessions || [];
  const groundDetails = data?.res?.athleteSessions && data.res.athleteSessions.length > 0
    && data.res.athleteSessions[0] && data.res.athleteSessions[0].track?.ground;

  const jwtToken = (localStorage.getItem('exelio_token')) || undefined;
  const maxSamples = loadingTimeFrame * maxSamplesPerFrame;

  useEffect(() => {
    webplayerWorkerNew.onmessage = ( e: MessageEvent<{
      type: 'loadedPathData' | 'ready',
      serie: {
        id: string,
        drill: number | null,
        data: ViewerDataTuple[],
        intervals: PlayerDataIntervals,
      }
    }> ) => {
      // il worker risponde, è pronto
      if (e.data?.type === 'ready' && !workerIsReady) {
        console.log('%c - webplayerWorker ✔', 'color: green');
        workerCheckerRef.current && clearInterval(workerCheckerRef.current);
        setWorkerIsReady(true);
      }

      if (!e.data?.serie?.id) {
        return;
      }

      const {
        id,
        data: d,
        intervals: dataIntervals,
      } = e.data.serie;

      let tmpPath: {
        t: number,
        x: number | null,
        x_0?: number | null,
        y: number | null,
        y_0?: number | null,
        s: number | null,
        h: number | null,
      }[] = [];

      if (d && d.length > 0) {
        if (ground?.groundCoordsType === 'LOCAL') { // è un ground LPS
          d.forEach(p => {
            const point = {
              x: p[1],
              y: p[2],
              s: p[3],
              h: p[4]
            };

            const newPoint = !p[1] || !p[2]
              ? {
                x: null,
                y: null,
                s: null,
                h: null,
              }
              : groundDetails
                ? defaultGround
                  ? point
                  : {
                    ...rotate(
                      flipPoint(point, groundDetails),
                      computeAngle(groundDetails),
                    ),
                    ...{
                      s: point.s,
                      h: point.h,
                    }
                  }
                : point;

            tmpPath.push({
              t: Math.trunc(p[0] * 1000),
              x: newPoint.x,
              y: newPoint.y,
              s: newPoint.s,
              h: newPoint.h,
            });
          });
        } else {
          try {
            const { vertexALatitude, vertexALongitude, vertexBLatitude, vertexBLongitude, vertexCLatitude, vertexCLongitude } = currentGround || {};

            tmpPath = LPSFromGPSPath(
              d.map(( p ) => ({
                t: Math.trunc(p[0] * 1000),
                x: p[1],
                y: p[2],
                s: p[3],
                h: p[4]
              })),
              { latitude: vertexALatitude || 0, longitude: vertexALongitude || 0 },
              { latitude: vertexBLatitude || 0, longitude: vertexBLongitude || 0 },
              { latitude: vertexCLatitude || 0, longitude: vertexCLongitude || 0 }
            );
          } catch (e) {
            console.log('Cannot compute LPS from GPS path', e, currentGround);
          }
        }
      } else {
        console.log('No path data', d);
      }

      const pathDataObj = tmpPath.reduce(( acc, curr ) => {
        acc[curr.t] = {
          x: curr.x || null,
          x_0: curr.x_0 || null,
          y: curr.y || null,
          y_0: curr.y_0 || null,
          s: curr.s || null,
          h: curr.h || null,
        }
        return acc;
      }, {});

      if (id === athSess[0].id && (Object.keys(pathDataObj).length > 0) ) {
        setTimestampsToAdd(Object.keys(pathDataObj).map((t) => parseInt(t, 10)).sort());
      }

      setSeries(( prevState ) => ({
        ...prevState,
        [String(id)]: {
          pathData: pathDataObj
        }
      }));

      setEnabledPlayers(( prevState ) => {
        const newEnabledPlayers = [...prevState];

        if (!newEnabledPlayers.includes(id)) {
          newEnabledPlayers.push(id);
        }

        return newEnabledPlayers;
      });

      setLoadingPlayers(( prevState ) => {
        const newLoadingPlayers = [...prevState];
        newLoadingPlayers.splice(newLoadingPlayers.indexOf(String(id)), 1)

        return newLoadingPlayers;
      });

      setLoadingNextData(( prevState ) => {
        const newLoadingPlayers = [...prevState];
        newLoadingPlayers.splice(newLoadingPlayers.indexOf(String(id)), 1)

        return newLoadingPlayers;
      })

      setIntervals(dataIntervals)
    }
  }, [ground, groundDetails]);

  const loadAthSessionData = useCallback(async () => {
    if (!detailsData?.res?.athleteSessions || !currentGround) {
      return;
    }

    setStatus(STATUS_TEXT.PLAYERS_LOADED);

    if (!sessionLoading && athSess.length > 0) {
      let newLoadingPlayers = athSess.map(( athS ) => athS.id);
      setLoadingPlayers(newLoadingPlayers);

      const timezoneOffset = data?.res.startTimestamp
        ? -new Date(data?.res.startTimestamp).getTimezoneOffset() * 60000
        : 0;
      const startTime = currentTime
        ? currentTime
        : data?.res.startTimestamp
          ? (new Date(data?.res.startTimestamp)).getTime() + timezoneOffset
          : 0;

      webplayerWorkerNew.postMessage({
        action: 'loadMultiplePathData',
        athleteSessionIds: athSess.map(( a ) => a.id),
        drill: currentDrill,
        jwtToken,
        maxSamples,
        start: startTime,
        end: startTime + loadingTimeFrame, // 5 minuti
      })
    }

    setStatus(STATUS_TEXT.PLAYERS_READY);
  }, [
    detailsData?.res.athleteSessions,
    data?.res.athleteSessions,
    ground?.groundCoordsType,
    defaultGround,
    timestamps.length,
    currentDrill,
    data?.res?.endTimestamp,
    data?.res?.startTimestamp,
    sessionLoading,
  ]);

  // @todo verifica caching posizioni durante il loading progressivo

  useEffect(() => {
    // attendo che il worker sia pronto
    if (!workerIsReady && !workerCheckerRef.current) {
      workerCheckerRef.current = setInterval(() => {
        webplayerWorkerNew.postMessage({
          action: 'requireInit',
        })
      }, 500)
    }

    return () => {
      workerCheckerRef.current && clearInterval(workerCheckerRef.current);
    }
  }, []);

  useEffect(() => {
    if (timestamps && timestamps.length > 0 && data?.res?.startTimestamp && data?.res?.endTimestamp) {
      currStepRef.current = 1 / FPS;
    }
  }, [timestamps, data?.res?.startTimestamp, data?.res?.endTimestamp]);

  // @todo migliorare la verifica, ipoteticamente i timestampsToAdd devono essere solo quelli della prima serie
  useEffect(() => {
    if (timestampsToAdd.length > 0) {
      const wasPlaying = fpsCtrlRef.current?.isPlaying;

      if (wasPlaying) {
        handlePlayClick(false, false);
      }

      setTimestamps(timestampsToAdd.sort());
      setTimestampsToAdd([]);

      if (lastRequestedTimestamp.current) {
        setCurrentTime(lastRequestedTimestamp.current)
      }

      if (wasPlaying) {
        handlePlayClick(true, false);
      }
    }
  }, [timestampsToAdd, fpsCtrlRef.current]);

  useEffect(() => {
    if (workerIsReady) {
      loadAthSessionData();
    }
  }, [workerIsReady, detailsData, ground, currentDrill]);

  const {
    data: dataGrounds,
  } = useQueryCached<ClubTypeRes, ClubTypeVars>(CLUB, {
    variables: {
      id: teamId,
    },
    ...CACHE_AND_NETWORK,
  });

  const groundSet = dataGrounds?.res?.club?.groundSet || [];

  const groundOptions = useMemo<OptionType[]>(() => groundSet?.map(( g ) => ({
    id: String(g.id),
    value: String(g.name),
    label: String(g.name),
  })), [groundSet]);

  useEffect(() => {
    if (
      !ground
      && groundOptions.length > 0
      && groundSet.length > 0
      && detailsData
      && data
    ) {
      const selectedGround = groundSet.find(( g ) => g.id === groundOptions[0].id);

      if (selectedGround) {
        setGround(selectedGround);
      }
    }
  }, [groundOptions, ground, groundSet, detailsData, data])

  // @todo verificare se il time è presente nei timestamp o se c'è uno vicino
  // se c'è punto currentTimeIdx, se non c'è vuol dire che devo richiedere i dati
  // @todo verificare che al change reimposto il currDrillRef al valore corretto
  const handleChange = ( _: ChangeEvent<{}> | null, time: number ) => {
    if (timestamps[time]) {
      // ho il time nei timestamp, posiziono idx
      lastRequestedTimestamp.current = time;
      setCurrentTime(time);
    } else {
      // non ho time, ne cerco uno vicino
      const nearIdx = findNearTimestampIndex(time);
      if (nearIdx > -1) {
        // c'è uno vicino
        lastRequestedTimestamp.current = time;
        setCurrentTime(time)
      } else {
        // non c'è ne time ne uno vicino, mancano dati, richiedo nuovi dati
        lastRequestedTimestamp.current = time;
        lastPlayerStatusIsPlaying.current = fpsCtrlRef.current.isPlaying;

        // fpsCtrlRef.current.pause(); @todo-restore

        const athleteSessionIds = athSess.map(( a ) => a.id);
        setLoadingPlayers(athleteSessionIds);

        webplayerWorkerNew.postMessage({
          action: 'loadMultiplePathData',
          athleteSessionIds,
          drill: currentDrill,
          jwtToken,
          maxSamples,
          start: time,
          end: time + loadingTimeFrame, // 5 minuti
        })
      }
    }
  };

  // @todo verificare che al change reimposto il currDrillRef al valore corretto
  // @todo durante il playing da posizioni non corrette, va resettato il playing
  const goToNextDrill = () => {
    const wasPlaying = fpsCtrlRef.current.isPlaying;
    if (wasPlaying) {
      handlePlayClick(false, true);
    }

    // i mark sono sempre doppi, inizio e fine
    if (currDrillRef.current < marks.length / 2) {
      currDrillRef.current += 1;
    }

    const nextDrill = marks.filter(( m ) => m.index === currDrillRef.current);
    if (nextDrill.length) {
      handleChange(null, nextDrill[0].value);
    }

    if (wasPlaying) {
      handlePlayClick(true, true);
    }
  };

  // @todo verificare valori negativi
  // @todo durante il playing da posizioni non corrette, va resettato il playing
  const goToPrevDrill = () => {
    const wasPlaying = fpsCtrlRef.current.isPlaying;
    if (wasPlaying) {
      handlePlayClick(false, true);
    }
    if (currDrillRef.current > 1) {
      currDrillRef.current -= 1;
    }
    const prevDrill = marks.filter(( m ) => m.index === currDrillRef.current);
    if (prevDrill.length) {
      handleChange(null, prevDrill[0].value);
    }

    if (wasPlaying) {
      handlePlayClick(true, true);
    }
  };

  const drillTimes: DrillTimesType = useMemo(() => {
    const times = {};

    marks.forEach(( drill ) => {
      const {index, value} = drill;
      // @todo verificare null
      if (index) {
        if (!times[index]) {
          times[index] = {start: value, end: value};
        } else {
          times[index].start = Math.min(times[index].start, value);
          times[index].end = Math.max(times[index].end, value);
        }
      }
    });

    return Object.values(times);
  }, [marks]);


  const handlePlayClick = useCallback(( forcePlay = false, dontSetLastRequestedTimestamp = false ) => {
    if (!dontSetLastRequestedTimestamp) {
      lastRequestedTimestamp.current = currentTime;
    }
    if (
      forcePlay || fpsCtrlRef.current && !fpsCtrlRef.current.isPlaying
    ) {
      lastPlayerStatusIsPlaying.current = fpsCtrlRef.current.isPlaying;
      fpsCtrlRef.current.start();
    } else {
      fpsCtrlRef.current.pause();
      lastPlayerStatusIsPlaying.current = fpsCtrlRef.current.isPlaying;
    }
  }, [fpsCtrlRef.current?.isPlaying, currentTime]);

  // @todo mettere su funzioni a parte, deve pulire eventuali selezioni non attive
  const [playerLinks, setPlayerLinks] = useState<Set<[string, string]>>(new Set());

  const handlePlayerClick = useCallback(( playerID: string ) => {
    if (!currentActivePlayer) {
      setCurrentActivePlayer(playerID);
    } else if (currentActivePlayer && currentActivePlayer === playerID) {
      setCurrentActivePlayer(null);
    } else {
      const existingLink = Array.from(playerLinks)
      .find(( link ) => link[0] === currentActivePlayer && link[1] === playerID
        || link[0] === playerID && link[1] === currentActivePlayer);

      if (!existingLink) {
        const newPlayerLinks = new Set(playerLinks);
        newPlayerLinks.add([currentActivePlayer, playerID]);
        setPlayerLinks(newPlayerLinks);
      }

      setCurrentActivePlayer(null);
    }
  }, [currentActivePlayer, playerLinks]);

  const handlePlayerEnter = ( playerID: string ) => {
    setCurrentHoverPlayer(playerID);
  };

  const handlePlayerLeave = () => {
    setCurrentHoverPlayer(null);
  };

  const playersDetails: PlayersDetails = useMemo(() => (detailsData?.res?.athleteSessions
    ? detailsData?.res?.athleteSessions.reduce(( acc, curr ) => {
      if (curr.athlete?.id) {
        acc[curr.id] = {
          id: curr.athlete?.id,
          name: curr.athlete?.name,
          number: curr.athlete?.playerSet.find(( ps ) => ps.team?.id && ps.team?.id === teamId)?.number || null,
          shortName: curr.athlete?.shortName,
        };
      }

      return acc;
    }, {})
    : {}), [detailsData?.res?.athleteSessions, teamId]);

  const handleRemoveLink = ( linkIdx: number ) => {
    // eslint-disable-next-line no-restricted-globals
    const newPlayerLinks = Array.from(playerLinks);
    newPlayerLinks.splice(linkIdx, 1);
    setPlayerLinks(new Set(newPlayerLinks));
  };

  const enterFullscreen = () => {
    const elem = playerRef.current;
    // @ts-ignore
    if (elem.requestFullscreen) {
      // @ts-ignore
      elem.requestFullscreen();
      // @ts-ignore
    } else if (elem.mozRequestFullScreen) { // Firefox
      // @ts-ignore
      elem.mozRequestFullScreen();
      // @ts-ignore
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      // @ts-ignore
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      // @ts-ignore
    } else if (document.mozCancelFullScreen) {
      // @ts-ignore
      document.mozCancelFullScreen();
      // @ts-ignore
    } else if (document.webkitExitFullscreen) {
      // @ts-ignore
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreenClick = async () => {
    if (document.fullscreenElement) {
      exitFullscreen();
    } else if (playerRef.current) {
      try {
        enterFullscreen();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const defaultPlayersColor = !isGPSGround
    ? ground?.groundSurfaceColor ? getPairedGroundColor(ground?.groundSurfaceColor) : PLAYER_MARKER_COLOR
    : sessionGround?.groundSurfaceColor
      ? getPairedGroundColor(sessionGround?.groundSurfaceColor)
      : PLAYER_MARKER_COLOR;

  const indexedMarks = useMemo(() => (marks || []).map((m) => ({
    ...m,
    idx: findNearTimestampIndex(m.value),
  })), [marks, findNearTimestampIndex]);

  const playersExtraData = useMemo(
    () => enabledPlayers.reduce<PlayersExtraData>((acc, p) => {
      const dataTimestamp = currentTime && findNearTimestamp(currentTime);
      acc[p] = dataTimestamp && series[p].pathData[dataTimestamp] || null;
      return acc;
    }, {}),
    [currentTime, enabledPlayers, series, findNearTimestamp]
  );

  useEffect(() => {
    // check if currentTime is near the end of an interval
    checkNearEndOfInterval(
      currentTime,
      intervals,
      20000 * playerSpeed,
      (interval: PlayerDataInterval) => {
      if (loadingNextData.length === 0) {
        const athleteSessionIds = athSess.map(( a ) => a.id);

        setLoadingNextData(athleteSessionIds)

        setLoadingPlayers(athleteSessionIds);

        webplayerWorkerNew.postMessage({
          action: 'loadMultiplePathData',
          athleteSessionIds,
          drill: currentDrill,
          jwtToken,
          maxSamples,
          start: interval[1],
          end: interval[1] + loadingTimeFrame, // 5 minuti
        })
      }
    });
  }, [currentTime, intervals]);

  const tickIntervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    tickIntervalRef.current = setInterval(() => {
      setTickThrottle(!tickThrottle);
    }, 300)

    return () => {
      if (tickIntervalRef.current) {
        clearInterval(tickIntervalRef.current);
      }
    }
  }, []);

  const playersTable = useMemo(() => (<PlayersTable
    athleteSessions={detailsData?.res?.athleteSessions || []}
    enabledPlayers={enabledPlayers}
    loadingPlayers={loadingPlayers}
    groundSurfaceColor={ground?.groundSurfaceColor || DEFAULT_GROUND_COLOR}
    jerseyOrNumber={jerseyOrNumber}
    playersColors={playersColors}
    playersDetails={playersDetails}
    setEnabledPlayers={setEnabledPlayers}
    setPlayersColors={setPlayersColors}
    status={status}
  />), [
    detailsData?.res?.athleteSessions,
    enabledPlayers,
    loadingPlayers,
    ground?.groundSurfaceColor,
    jerseyOrNumber,
    playersColors,
    playersDetails,
    status
  ]);

  const isLoadingData = loadingPlayers.length > 0;

  return (
    <ErrorGuard>
      <div className="tracks-player" ref={playerRef}>

        <WebPlayerHeader
          ground={ground}
          groundOptions={groundOptions}
          groundSet={groundSet}
          jerseyOrNumber={jerseyOrNumber}
          setGround={setGround}
          setJerseyOrNumber={setJerseyOrNumber}
          setTrailsEnabled={setTrailsEnabled}
          trailsEnabled={trailsEnabled}
          isSessionDataReady={!!data}
        />
        <Row>
          <Column xs={8}>
            <Box p={2} style={{paddingLeft: '10px'}}>
              <CursorContext.Provider value={cursorValue}>
                {
                  currentGround
                  && (
                    <Ground
                      currentActivePlayer={currentActivePlayer}
                      hoverPlayer={currentHoverPlayer}
                      defaultPlayersColor={defaultPlayersColor}
                      enabledPlayers={enabledPlayers}
                      ground={currentGround}
                      handlePlayerClick={handlePlayerClick}
                      handlePlayerEnter={handlePlayerEnter}
                      handlePlayerLeave={handlePlayerLeave}
                      handleRemoveLink={handleRemoveLink}
                      isLoadingData={isLoadingData}
                      jerseyOrNumber={jerseyOrNumber}
                      playerLinks={playerLinks}
                      playersColors={playersColors}
                      playersDetails={playersDetails}
                      playersExtraData={playersExtraData}
                      series={series}
                      showVertices={showVertices}
                      trailsEnabled={trailsEnabled}
                    />
                  )
                }

                <PlayerControls
                  currDrillRef={currDrillRef}
                  currentDrill={currentDrill}
                  currentTime={currentTime}
                  // currentTimeIdx={Math.floor(currentTimeIdx)}
                  drillTimes={drillTimes}
                  enabled={!isLoadingData}
                  endTimestamp={data?.res?.endTimestamp}
                  goToNextDrill={goToNextDrill}
                  goToPrevDrill={goToPrevDrill}
                  handleChange={handleChange}
                  handleFullscreenClick={handleFullscreenClick}
                  handlePlayClick={() => handlePlayClick()}
                  intervals={intervals}
                  marks={indexedMarks}
                  playerSpeed={playerSpeed}
                  playerStatus={!!fpsCtrlRef.current?.isPlaying ? 1 : 0}
                  setPlayerSpeed={setPlayerSpeed}
                  startTimestamp={data?.res?.startTimestamp}
                  timeFormat={timeFormat}
                  valueLabelFormat={valueLabelFormat}
                  timestamps={timestamps}
                />
              </CursorContext.Provider>
            </Box>
          </Column>

          <Column xs={4}>
            {playersTable}
          </Column>

        </Row>

      </div>
    </ErrorGuard>
  );
};

export default WebPlayer;
