import React from 'react';
import { ZoomControlsProps } from 'types/webPlayer';
import { BUTTON_SCALE_FACTOR } from '../track/ground/constants';

const ZoomControls = (props: ZoomControlsProps) => {
  const { setZoom, zoom } = props;
  return (
    <div className="player__zoom">
      <button
        type="button"
        style={{
          backgroundColor: 'white',
          cursor: 'pointer',
          display: 'block',
          fontSize: '24px',
          fontWeight: '800',
          height: '40px',
          width: '40px',
        }}
        onClick={() => {
          setZoom(1);
        }}
      >
        <svg
          fill="none"
          height="24"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </button>
      <button
        type="button"
        style={{
          backgroundColor: 'white',
          cursor: 'pointer',
          display: 'block',
          fontSize: '24px',
          fontWeight: '800',
          height: '40px',
          width: '40px',
        }}
        title="zoom in"
        onClick={() => {
          setZoom(zoom * BUTTON_SCALE_FACTOR);
        }}
      >
        <svg
          fill="none"
          height="24"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
          <line x1="11" y1="8" x2="11" y2="14" />
          <line x1="8" y1="11" x2="14" y2="11" />
        </svg>
      </button>
      <button
        onClick={() => {
          setZoom(zoom / BUTTON_SCALE_FACTOR);
        }}
        style={{
          backgroundColor: 'white',
          cursor: 'pointer',
          display: 'block',
          fontSize: '24px',
          fontWeight: '800',
          height: '40px',
          width: '40px',
        }}
        title="zoom out"
        type="button"
      >
        <svg
          fill="none"
          height="24"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
          <line x1="8" y1="11" x2="14" y2="11" />
        </svg>
      </button>

    </div>
  );
};

export default ZoomControls;
