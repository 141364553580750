import React, {
  useContext, useLayoutEffect, useMemo, useState, useEffect,
} from 'react';
import { Box } from '@material-ui/core';
import {
   Polyline,
} from 'react-leaflet';
import { PathOptions } from 'leaflet';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import { useLazyQueryCached } from 'components/utils/graphql';
import geomagnetism from '../../lib/geomagnetism';
import { PATH_DATA, COURSE_TRACK, CourseTrackRes, CourseTrackVars } from '../../query/track';
import { eventsColors } from './colors';
import MapComponent, { getMapBoundsFromPoints, PolylineArrow } from '../layout/maps/MapComponent';
import { MapLayerType, MAP_LAYER_TYPE_DEFAULT } from '../../lib/maps';
import { TeamContext } from '../../Authenticator';
import { CACHE_AND_NETWORK } from '../../lib/cache';
import { GlobalFiltersContext } from '../track/View';
import { computeWorker } from '../../workers';
import MapChartCursor from './MapChartCursor';

interface PropsIn {
  trackId: string,
  athleteSessionId: string,
  currentDrill: number,
  mapMode?: string,
  layerType?: string,
  setRenderGround?: (cursor: boolean) => void;
  setEnableButtons?: (cursor: boolean) => void;
}


const colorGradients = [
  'rgba(0, 0, 255, 0)',
  'rgba(0, 0, 255, 1)',
  'rgba(0, 31, 255, 1)',
  'rgba(0, 63, 255, 1)',
  'rgba(0, 91, 255, 1)',
  'rgba(0, 127, 255, 1)',
  'rgba(0, 159, 255, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 223, 255, 1)',
  'rgba(0, 255, 255, 1)',
  'rgba(0, 255, 223, 1)',
  'rgba(0, 255, 191, 1)',
  'rgba(0, 255, 159, 1)',
  'rgba(0, 255, 127, 1)',
  'rgba(0, 255, 91, 1)',
  'rgba(0, 255, 63, 1)',
  'rgba(0, 255, 31, 1)',
  'rgba(0, 255, 0, 1)',
  'rgba(31, 223, 0, 1)',
  'rgba(63, 191, 0, 1)',
  'rgba(91, 159, 0, 1)',
  'rgba(127, 127, 0, 1)',
  'rgba(159, 91, 0, 1)',
  'rgba(191, 63, 0, 1)',
  'rgba(223, 31, 0, 1)',
  'rgba(255, 0, 0, 1)',
].reduce((acc, cur, index) => {
  acc[index / 25] = cur;
  return acc;
}, {});

const getMapMultiLines = (pathData: [number, number][]): [number, number][][] => {
  const paths: [number, number][][] = [];
  let currentPath: [number, number][] = [];
  pathData?.forEach((p) => {
    if (p[0] === null) {
      if (currentPath.length > 0) {
        paths.push(currentPath);
      }
      currentPath = [];
    } else {
      currentPath.push(p);
    }
  });

  if (currentPath.length > 0) {
    paths.push(currentPath);
  }

  return paths;
};

const MapChart = (props: PropsIn) => {
  const {
    trackId, // cursor,
    athleteSessionId,
    currentDrill,
    setEnableButtons,
  } = props;

  const { globalTeam } = useContext(TeamContext);
  const { filters } = useContext(GlobalFiltersContext);
  const zipped = false;

  const [fetchData, {
    data,
  }] = useLazyQueryCached(
    PATH_DATA(trackId ? 'track' : 'session', zipped),
    {
      variables: trackId
        ? {
          trackId,
          drill: currentDrill,
        }
        : {
          id: athleteSessionId,
          drill: currentDrill,
        },
        onCompleted: () => {
          setEnableButtons && setEnableButtons(true)
        },
      ...CACHE_AND_NETWORK,
    },
  );

  const [path, setPath] = useState<{ [k: number]: [number, number] }>({});
  const [courseData, setCourseData] = useState<{ [k: number]: number }>({});
  const [powerEvents, setPowerEvents] = useState({});
  const [mapMode, setMapMode] = useState('1');
  const [geo, setGeo] = useState<any>(null);
  const [timestamps, setTimestamps] = useState<number[]>([]);
  const [computeRequested, setComputeRequested] = useState(false);
  const [layerType, setLayerType] = useState<MapLayerType>(props.layerType || MAP_LAYER_TYPE_DEFAULT);

  const [fetchHeading, courseResponse] = useLazyQueryCached<CourseTrackRes, CourseTrackVars>(
    COURSE_TRACK,
    {
      variables: {
        athletesessionId: athleteSessionId,
        maxSamples: '20000',
      },
      ...CACHE_AND_NETWORK,
    },
  );

  useEffect(() => {
    if (props.mapMode) {
      setMapMode(props.mapMode);
    }
  }, [props.mapMode]);

  useEffect(() => {
    if (props.layerType) {
      setLayerType(props.layerType);
    }
  }, [props.layerType]);


  useEffect(() => {
    fetchData();
    fetchHeading();

    if (typeof window.Worker !== 'undefined') {
      computeWorker.onmessage = (e: any) => {
        setTimestamps(e?.data?.timestamps);
        setCourseData(e?.data?.heading);
        setPath(e?.data?.path);
      };
    }
  }, []);

  useEffect(() => {
    if (data?.res?.path?.data?.length && courseResponse?.data?.res?.data?.length) {
      // evitare doppioni, check status inizializzato
      if (!computeRequested) {
        setComputeRequested(true);
        computeWorker.postMessage({
          action: 'pathHeadingInterpolate',
          data: {
            path: data?.res?.path?.data,
            courseData: courseResponse?.data?.res?.data ? JSON.parse(courseResponse?.data?.res?.data) : [],
          },
        });
      }
    }
  }, [data?.res?.path?.data, courseResponse?.data?.res?.data]);

   useLayoutEffect(() => {
    const powerEventsData = {};

    if (data?.res?.path?.data?.length) {
      setGeo(geomagnetism.model()
      .point([data?.res?.path?.data[0][1], data?.res?.path?.data[0][2]])?.decl || 0);
    }

    if (mapMode === '3' && data?.res?.powerEventsPath?.data) {
      data.res.powerEventsPath.data.forEach((sequence) => {
        const [timeStart, timeEnd, sequenceColorIndex] = sequence;
        const currentPathData = data?.res?.path?.data?.reduce((result, p) => {
          if (timeStart <= p[0] && p[0] <= timeEnd) {
            result.push([p[1], p[2]]);
          }
          return result;
        }, []);

        powerEventsData[Math.trunc(timeStart / 1000) * 1000] = {
          data: currentPathData,
          color: sequenceColorIndex !== undefined ? eventsColors[sequenceColorIndex] : '#000000',
        };
      });

      setPowerEvents(
        Object.entries(powerEventsData).reduce((acc, [key, value]) => {
          const k = parseInt(key, 10);
          if (!filters || !filters.min || !filters.max || (k >= filters.min && k <= filters.max)) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      );
    }
  }, [mapMode, data, filters]);

  const options: PathOptions = {
    color: 'var(--primary-gradient-2)',
    opacity: 0.8,
    weight: 2,
    fillOpacity: 0.35,
  };

  const mapLPoints: [number, number][] = useMemo(() => Object.keys(path)
    .filter(p => !filters.min || !filters.max || parseInt(p) > filters.min && parseInt(p) < filters.max)
    .map(p => path[p]), [path, filters.min, filters.max]);

  const mapType = globalTeam?.club?.mapService;
  const mapHasData = useMemo(() => mapLPoints?.length > 0 && !!mapLPoints.find((point) => point[0] || point[1]), [mapLPoints]);
  const mapMultiLines = useMemo(() => mapMode !== '1' ? [] : getMapMultiLines(mapLPoints), [mapLPoints, mapMode]);
  const mapBoundsFromPoints = useMemo(() => mapHasData && getMapBoundsFromPoints(mapLPoints) || undefined, [mapLPoints, mapHasData])

  if (!mapHasData) {
    console.warn('[Map] - no data');
  }

  return (
    <>
      {
        mapHasData
        && (
          <Box height="550px" bgcolor="#dddddd">
            <MapComponent
              bounds={mapBoundsFromPoints}
              zoom={13}
              layer={layerType}
              type={mapType}
            >
              {
                mapMode === '1'
                && (
                  <Polyline
                    positions={mapMultiLines}
                    pathOptions={options}
                  />
                )
              }

              {
                mapMode === '2'
                && (
                  <HeatmapLayer
                    fitBoundsOnLoad
                    fitBoundsOnUpdate
                    points={mapLPoints.filter(p => p[0] !== null && p[1] !== null)}
                    longitudeExtractor={(m) => m[1]}
                    latitudeExtractor={(m) => m[0]}
                    intensityExtractor={() => 0.01}
                    radius={5}
                    blur={10}
                    minOpacity={1}
                    gradient={colorGradients}
                  />
                )
              }

              {
                mapMode === '3'
                && Object.keys(powerEvents)
                  .map((sequence, index) => (
                    <PolylineArrow
                      key={index}
                      positions={powerEvents[sequence].data}
                      pathOptions={{
                        ...options,
                        ...{
                          color: eventsColors[2],
                        },
                      }}
                    />
                  ))
              }

              <MapChartCursor
                geo={geo}
                heading={courseData}
                path={path}
                timestamps={timestamps}
              />

              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="map-compass"
              >
                <path
                  d="M6.19957 20.6341L11.8683 10.2414C11.9252 10.1372 12.0748 10.1372 12.1317 10.2414L17.8004 20.6341C17.8677 20.7575 17.7436 20.8974 17.613 20.8452L13.077 19.0308C13.0291 19.0116 12.9944 18.9692 12.9852 18.9184L12.1476 14.3117C12.1177 14.1475 11.8823 14.1475 11.8524 14.3117L11.0148 18.9184C11.0056 18.9692 10.9709 19.0116 10.923 19.0308L6.38697 20.8452C6.25644 20.8974 6.13226 20.7575 6.19957 20.6341Z"
                  fill="currentcolor"
                />
                <path
                  d="M9 9V3.12071C9 3.07617 9.05386 3.05386 9.08536 3.08536L14.9146 8.91465C14.9461 8.94614 15 8.92383 15 8.87929V3"
                  stroke="currentcolor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </MapComponent>

          </Box>
        )
      }
    </>
  );
};

export default MapChart;
