const dataLoader = new Worker(new URL('./session.ts', import.meta.url));

export default dataLoader;

export const computeWorker = new Worker(new URL('./compute.ts', import.meta.url));

export const athletesLoader = new Worker(new URL('./athletes.ts', import.meta.url));

export const teamsLoader = new Worker(new URL('./teams.ts', import.meta.url));

export const webplayerWorker = new Worker(new URL('./webplayer.ts', import.meta.url));

export const webplayerWorkerNew = new Worker(new URL('./webplayerNew.ts', import.meta.url));
