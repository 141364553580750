import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { kpiLabelExtractor } from 'components/utils/kpiLabelExtractor';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import { GridColDef, XGrid } from '@material-ui/x-grid';
import {
  ATHLETE_SESSION,
  AthSessionRes,
  AthSessionVars,
} from 'query/athletesessions';
import { getKpiTableHeaderNicer } from 'components/KpiTableHeader';
import { durationToHHMMSS, getUomTranslation } from 'components/utils/utils';
import { mergeAll } from 'ramda';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import TitleView from 'components/layout/TitleView';
import { CACHE_AND_NETWORK, CACHE_FIRST_CACHE_AND_NETWORK } from 'lib/cache';
import DropdownButton from 'components/DropdownButton';
import { MAP_LAYER_TYPE_DEFAULT, mapLayerTypes } from 'lib/maps';
import { CLUB, ClubTypeRes, ClubTypeVars } from 'query/club';
import TrackSeriesChart from './TrackSeriesChart';
import MapChart from '../charts/MapChart';
import { GlobalFiltersContext, TrackExportDataType } from './View';
import { LayoutType } from '../../query/track';
import { elementIsEnabled } from '../utils/layout';
import Ground from './ground/Ground';
import { computeEndTime } from '../athletesessions/utils';
import { TIME_COURSE_KPI } from '../constants';
import { Loader, IconWrapper } from '../../lib/icons';
import { renderAlignedTopHeader } from '../Table';
import { useLazyQueryCached, useQueryCached } from '../utils/graphql';
import { formatterChoice } from '../utils/unitsFormatter';
import { TemplateType } from '../../models/template';
import { TEMPLATE } from '../../query/template';
import { withMe } from 'components/MeUserWrapper';
import { MeType } from 'models/me';
import { GroundTypeRes, QueryGroundsArgs } from '__generated__/graphql';
import { GROUNDS_BY_CLUB } from 'query/grounds';

type PropsIn = {
  clubId: string;
  data: DetailRes;
  athleteSessionId?: string;
  trackId?: string;
  templateId: string;
  teamId: string;
  layout?: LayoutType[];
  currentDrill?: number;
  setActiveSeries?: React.Dispatch<React.SetStateAction<TrackExportDataType>>,
  setTrackExportData: React.Dispatch<React.SetStateAction<TrackExportDataType>>;
  disabled?: boolean;
  me?: MeType;
};

export const CursorContext = React.createContext<{
  cursor: any;
  setCursor:(cursor: any) => void;
    }>({
      cursor: {},
      setCursor: () => {},
    });

interface TopTableProps {
  athleteSessionId?: string;
  currentDrill?: number;
  data: DetailRes;
  teamId: string;
  templateId: string;
  validKpiNames: string[];
}

const TopTable = (props: TopTableProps) => {
  const {
    athleteSessionId,
    currentDrill,
    data,
    teamId,
    templateId,
    validKpiNames,
  } = props;
  const [rows, setRows] = useState<GridColDef[]>([]);
  const { t } = useTranslation();
  const globalFilters = useContext(GlobalFiltersContext);

  const [selectionQuery, selectionResponse] = useLazyQueryCached<AthSessionRes, AthSessionVars>(
    ATHLETE_SESSION,
    {
      variables: {
        templateId,
        id: athleteSessionId,
        start: globalFilters?.filters?.min || undefined,
        end: globalFilters?.filters?.max || undefined,
        drill: currentDrill,
      },
      ...CACHE_AND_NETWORK,
    },
  );

  useEffect(() => {
    if (globalFilters?.filters?.min && globalFilters?.filters?.max) {
      selectionQuery();
    }
  }, [globalFilters.filters]);

  const columns: GridColDef[] = [
    {
      field: '',
      headerName: '',
      headerClassName: 'table-header__subtitle-empty',
      headerAlign: 'left',
      cellClassName: 'font-bold',
      align: 'left',
      sortable: false,
      flex: 150,
      hideSortIcons: true,
      renderCell: (params) => (
        <Box className="bold-label nolink-col-left" style={{ display: 'flex', gap: '40px' }}>
          {params.row.first}
        </Box>
      ),
    },
    {
      field: 'time',
      headerName: t('table.time', 'time'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 250,
      hideSortIcons: true,
      renderCell: (params) => {
        if (params.id === 'selection') {
          return (selectionResponse.loading && selectionResponse.called) ? <Loader className="select-field-loading" /> : selectionResponse?.data?.res
            ? `${selectionResponse.data.res.startTime} -
            ${computeEndTime(selectionResponse.data.res.startTime, selectionResponse?.data?.res?.duration?.value)}`
            : '';
        }
      },
      renderHeader: renderAlignedTopHeader,
    },
    {
      field: 'duration',
      headerName: t('table.duration', 'duration'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 150,
      hideSortIcons: true,
      renderCell: (params) => {
        if (params.id === 'selection') {
          return (selectionResponse.loading && selectionResponse.called) ? null : params.row.duration;
        }
      },
      renderHeader: renderAlignedTopHeader,
    },
    ...(data?.res?.kpi?.slice(0, TIME_COURSE_KPI) || [])
    .filter((k) => k.name !== 'average_time' && validKpiNames.includes(k.name))
    .map((kpi) => {
      const label = `${kpiLabelExtractor(teamId, kpi.name) || '-'} ${getUomTranslation(t, kpi.name === 'eccentric_index' ? '%' : kpi.uom)}`;
      return {
        field: kpi.name,
        headerName: label,
        headerClassName: 'table-header__subtitle',
        headerAlign: 'right' as 'right',
        align: 'right' as 'right',
        flex: label.length > 10 ? 220 : 130,
        sortable: false,
        hideSortIcons: true,
        renderCell: (params) => {
          if (params.id === 'selection') {
            return (selectionResponse.loading && selectionResponse.called) ? null
              : <span className="nolink-col-right">{params.row[kpi.name]}</span>;
          }
          return <span className="nolink-col-right">{params.row[kpi.name]}</span>;
        },
        renderHeader: (params) => getKpiTableHeaderNicer(params, kpi, teamId),
      };
    }),
  ];

  useEffect(() => {
    if (data?.res?.startTime) {
      const rowsTmp = {
        id: 'full_track',
        first: t('track.widgets.labels.wholeTrack', 'whole track'),
        time: `${data.res.startTime} - ${computeEndTime(data.res.startTime, data.res.duration.value)}`,
        duration: durationToHHMMSS(data.res.totalTime.value),
      };
      const kpisTmp = data.res?.kpi?.slice(0, TIME_COURSE_KPI)
      .filter((k) => k.name !== 'average_time' && validKpiNames.includes(k.name))
      .map((k) => ({
        [k.name]: formatterChoice(k.unit)(k.uom, k.value) || 0,
      }));
      const kpis = mergeAll(kpisTmp);
      setRows([
        {
          ...rowsTmp,
          ...kpis,
        },
        {
          id: 'selection',
          first: t('track.widgets.labels.selection', 'selection'),
          duration: selectionResponse?.data?.res?.totalTime
            ? selectionResponse.data
              ? durationToHHMMSS(selectionResponse.data.res.totalTime.value || 0)
              : ''
            : null,
          ...(selectionResponse?.data?.res?.kpi
            ? mergeAll(
              selectionResponse.data.res.kpi.map((i) => ({
                [i.name]: i.name === 'average_time' ? durationToHHMMSS(i.value)
                  : i.value !== null ? formatterChoice(i.unit)(i.uom, i.value) : '-',
              })),
            )
            : {}),
        },
      ]);
    }
  }, [selectionResponse.data?.res, data?.res]);


  return (
    <XGrid
      style={{ borderRadius: '12px', marginBottom: '16px' }}
      columns={columns}
      autoHeight
      disableSelectionOnClick
      disableColumnMenu
      disableColumnResize
      disableColumnFilter
      disableMultipleColumnsSorting
      getRowClassName={() => 'font-roboto'}
      disableMultipleSelection
      hideFooter
      rows={rows}
      paginationMode="client"
      rowCount={2}
    />
  );
};

const TrackSummary = (props: PropsIn) => {
  const {
    data, athleteSessionId, trackId, templateId, teamId, layout, setActiveSeries, currentDrill, setTrackExportData, me,
  } = props;

  const {
    data: templateData,
    refetch: refetchTemplateData,
  } = useQueryCached<{ res: TemplateType }, { id: any }>(TEMPLATE, {
    variables: {
      id: templateId,
    },
    ...CACHE_FIRST_CACHE_AND_NETWORK,
  });

  useEffect(() => {
    refetchTemplateData
  }, [templateId]);

  const validKpiNames = useMemo(() => (templateData?.res.templatefieldSet || []).map(( f) => f.field), [templateData])

  return (
    <Box width="100%" borderRadius={12} style={{ backgroundColor: 'white' }}>
      <Box marginBottom="45px">
        {
          (validKpiNames.length > 0) &&
          (<TopTable
            athleteSessionId={athleteSessionId}
            currentDrill={currentDrill}
            data={data}
            teamId={teamId}
            templateId={templateId}
            validKpiNames={validKpiNames}
          />)
        }
      </Box>

      <MapsComponent
        clubId={me?.lastTeam?.club?.id}
        templateId={templateId}
        teamId={teamId}
        trackId={trackId}
        currentDrill={currentDrill}
        layout={layout}
        setActiveSeries={setActiveSeries}
        data={data}
        athleteSessionId={athleteSessionId}
        setTrackExportData={setTrackExportData}
      />
    </Box>
  );
};

const MapsComponent = React.memo((props: PropsIn) => {
  const {
    clubId, templateId, teamId, trackId, currentDrill, layout, setActiveSeries, data, athleteSessionId, setTrackExportData,
  } = props;
  const [cursor, setCursor] = React.useState(0);

  const isLpexeTrack = data?.res?.track?.incomingFileFormat?.toUpperCase() === 'LPEXE';

  const { t } = useTranslation();
  const [mapMode, setMapMode] = React.useState('1');
  const [layerType, setLayerType] = React.useState(data?.res?.track?.ground || isLpexeTrack ? 'ground' : MAP_LAYER_TYPE_DEFAULT);
  const [mapModeLabel, setMapModeLabel] = React.useState(`${t('track.widgets.map.modes.1', 'standard mode')}`);
  const mapModes = ['1', '2', '3'];
  const [enableGround, setEnableGround] = React.useState(!!data?.res?.track?.ground || isLpexeTrack);
  const [enableButtons, setEnableButtons] = React.useState(false);
  const [groundModeLabel, setGroundModeLabel] = React.useState(data?.res?.track?.ground ? data?.res?.track?.ground.name : `${t('grounds.ground', 'ground')}`);
  const [groundId, setGroundId] = React.useState(data?.res?.track?.ground?.id);

  const { data: groundSet } = useQueryCached<{res: GroundTypeRes}, QueryGroundsArgs>(GROUNDS_BY_CLUB, {
    variables: {
      clubId,
    },
    ...CACHE_AND_NETWORK,
  });

  const grounds = groundSet?.res?.content?.map((ground) => ({
      value: ground.id.toString(),
      label: ground.name || '-',
    }))
    || [];

  const handleGroundModeChange = (ground) => {
    setGroundModeLabel(ground.label);
    setGroundId(ground.value);
  };

  const handleMapModeChange = (value) => {
    setMapMode(value);
    setMapModeLabel(t(`track.widgets.map.modes.${value}`, { defaultValue: value }));
  };

  const cursorValue = useMemo(() => ({ cursor, setCursor }), [cursor]);

  const groundsAvailable = groundSet?.res?.content || [];

  useEffect(() => {
    if (!data?.res?.track?.ground && isLpexeTrack && groundsAvailable?.length) {
      setGroundId(groundsAvailable[0]?.id);
      setGroundModeLabel(groundsAvailable[0]?.name);
    }
  }, [data, groundSet, isLpexeTrack]);

  useEffect(() => {
    if (data?.res?.track?.ground) {
      setGroundModeLabel(data?.res?.track?.ground.name);
      setGroundId(data?.res?.track?.ground.id);
    }
  }, [data]);

  useEffect(() => {
    if (groundsAvailable?.length
      && !groundId && enableGround) {
      setGroundId(groundsAvailable[0].id);
      setGroundModeLabel(groundsAvailable[0].name);
    }
  }, [groundSet, groundId, enableGround]);

  return (
    <CursorContext.Provider value={cursorValue}>
      <TrackSeriesChart
        templateId={templateId}
        teamId={teamId}
        currentDrill={currentDrill}
        athletesessionId={athleteSessionId}
        trackId={trackId}
        layout={layout}
        setActiveSeries={setActiveSeries}
        setTrackExportData={setTrackExportData}
        plotLinesY={data?.res?.track?.vo2MaxNet
          ? [{
            color: 'var(--secondary-color)',
            dashStyle: 'Dot',
            value: data.res.track.vo2MaxNet.value,
            width: 2,
            label: {
              text: 'net VO₂max',
              style: {
                color: 'var(--secondary-color)',
              },
            },
            zIndex: 1,
          }]
          : undefined}
      />
      <Box display="flex" className="track-series__selects">
        <div className="track-series__zones">
          <DropdownButton
            disabled={!enableButtons}
            label={t(`track.widgets.map.modes.${layerType}`, layerType)}
            popoverContent={
                  [
                    ...(!isLpexeTrack
                      ? mapLayerTypes.map((id) => ({
                        value: id,
                        label: t(`track.widgets.map.modes.${id}`, { defaultValue: id }),
                        onClick: () => {
                          if (layerType === 'ground') setEnableButtons(false);
                          setLayerType(id);
                          setEnableGround(false);
                        },
                      }))
                      : []),
                    (grounds?.length ? {
                      value: 'ground',
                      label: 'ground',
                      onClick: () => {
                        setLayerType('ground');
                        setEnableGround(true);
                        if (!groundId) {
                          setGroundId(groundsAvailable[0]?.id);
                          setGroundModeLabel(groundsAvailable[0]?.name);
                          setEnableButtons(false);
                        }
                      },
                    } : null),
                  ].filter(Boolean)
                }
            small
            multiple={false}
            values={[layerType]}
            anchor="right"
          />
        </div>
        <div className="track-series__zones">
          <DropdownButton
            label={groundModeLabel}
            disabled={!grounds || !enableGround || !enableButtons}
            popoverContent={
              (grounds || []).map((ground) => ({
                value: ground.value,
                label: ground.label,
                onClick: () => handleGroundModeChange(ground),
              })) || []
            }
            small
            multiple={false}
            values={[groundId]}
            anchor="right"
          />
        </div>
        <div className="track-series__zones">
          <DropdownButton
            disabled={!enableButtons}
            label={mapModeLabel}
            popoverContent={
              mapModes.map((id) => ({
                value: id,
                label: t(`track.widgets.map.modes.${id}`, { defaultValue: id }),
                onClick: () => handleMapModeChange(id),
              })) || []
            }
            small
            multiple={false}
            values={[mapMode]}
            anchor="right"
          />
        </div>
      </Box>

      { enableGround ? (
        <GlobalFiltersContext.Consumer>
          {(filters) => (
            <Ground
              clubId={clubId}
              teamId={teamId}
              trackId={trackId}
              athleteSessionId={athleteSessionId}
              groundId={groundId}
              filters={filters.filters}
              currentDrill={currentDrill}
              setRenderGround={data?.res?.track?.incomingFileFormat?.toUpperCase() === 'GPEXE' ? setEnableGround : undefined}
              mapMode={mapMode}
              setEnableButtons={setEnableButtons}
            />
          )}
        </GlobalFiltersContext.Consumer>
      ) : (
        elementIsEnabled(layout, 'basic_kpi', 'map') && (
          <MapChart
            trackId={trackId}
            athleteSessionId={athleteSessionId}
            currentDrill={currentDrill}
            setRenderGround={data?.res?.track?.ground ? setEnableGround : undefined}
            mapMode={mapMode}
            layerType={layerType}
            setEnableButtons={setEnableButtons}
          />
        )
      )}
    </CursorContext.Provider>
  );
});

const TrackView = (props: PropsIn) => {
  const { t } = useTranslation();
  return (
    <Accordion
      className="accordion" elevation={3} disabled={props?.disabled}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <TitleView
          title={t('track.widgets.labels.timeCourses', 'time courses analysis & map')}
          size="medium"
          noMargins
          prefixIcon={<IconWrapper name="timeCourse" size="medium" color="primary" />}
        />
      </AccordionSummary>
      <AccordionDetails>
        <TrackSummary {...props} />
      </AccordionDetails>
    </Accordion>
  );
}

export default withMe(TrackView);
