import { ClubType } from './club';
import { TrackType } from './track';
import { IncomingFileType } from './file';
import { GenericValueType } from '../query/track';

export type GroundType = {
  club: ClubType;
  createdOn: Date;
  groundCoordsType: GroundsGroundGroundCoordsTypeChoices;
  groundSurface: GroundsGroundGroundSurfaceChoices;
  groundSurfaceColor: string;
  groundType: GroundsGroundGroundTypeChoices;
  id: string;
  incomingfileSet: IncomingFileType[]; // TODO [mb] questa informazione al client non serve. Toglia dalla query e dal tipo
  name: string;
  offset: number;
  teamSet: TrackType[]; // TODO [mb] questa informazione al client non serve. Toglia dalla query e dal tipo
  trackSet: TrackType[]; // TODO [mb] questa informazione al client non serve. Toglia dalla query e dal tipo
  updatedOn: Date;
  vertexALatitude: number;
  vertexALongitude: number;
  vertexAX: GenericValueType<number>;
  vertexAY: GenericValueType<number>;
  vertexBLatitude:  number;
  vertexBLongitude:  number;
  vertexBX: GenericValueType<number>;
  vertexBY: GenericValueType<number>;
  vertexCLatitude:  number;
  vertexCLongitude:  number;
  vertexCX: GenericValueType<number>;
  vertexCY: GenericValueType<number>;
  vertexDLatitude:  number;
  vertexDLongitude:  number;
  vertexDX: GenericValueType<number>;
  vertexDY: GenericValueType<number>;
  hasTracks: boolean;
};

export type GroundsGroundGroundCoordsTypeChoices = 'GLOBAL' | 'LOCAL';

export type GroundsGroundGroundSurfaceChoices = 'COMPACT' | 'GRASS' | 'SYNTHETIC';

export type GroundsGroundGroundTypeChoices =
  | 'BADMINTON'
  | 'BASKET'
  | 'EMPTY'
  | 'FOOTBALL'
  | 'HANDBALL'
  | 'RUGBY'
  | 'RUGBY_HALF'
  | 'FUTSAL'
  | 'ICE_HOCKEY';

export const GROUND_COLORS = {
  green: {
    inner: 'var(--primary-gradient-2)',
    outer: 'var(--primary-gradient-3)',
  },
  purple: {
    inner: 'var(--secondary-gradient-2)',
    outer: 'var(--secondary-gradient-4)',
  },
  orange: {
    inner: 'var(--tertiary-gradient-2)',
    outer: 'var(--tertiary-gradient-3)',
  },
};

export const DEFAULT_GROUND_COLORS = [
  'rgb(0,221,181)', // var(--primary-gradient-2
  'rgb(69,10,223)', // var(--secondary-gradient-2
  'rgb(255,115,0)', // var(--tertiary-gradient-2
];

export const getOuterGroundColor = (color?: string) => {
  switch (color) {
    case 'rgb(69,10,223)' || GROUND_COLORS.purple.inner:
      return GROUND_COLORS.purple.outer;
    case 'rgb(255,115,0)' || GROUND_COLORS.orange.inner:
      return GROUND_COLORS.orange.outer;
    default:
      return GROUND_COLORS.green.outer;
  }
};

export const getPairedGroundColor = (color: string) => {
  // left colors hardcoded cause marker colors property cannot read variables.
  switch (color) {
    case 'rgb(255,115,0)' || 'ff7300':
      return '#00ddb5';
    default:
      return '#ff3700';
  }
};

export const getGroundTextColor = (color?: string) => {
  switch (color) {
    case 'rgb(69,10,223)' || GROUND_COLORS.purple.inner:
      return '#43fcdb';
    case 'rgb(255,115,0)' || GROUND_COLORS.orange.inner:
      return '#450adf';
    default:
      return '#004d41';
  }
};
