import React, { memo, useMemo } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { GridCellParams } from '@material-ui/x-grid';
import VisibilityIcon from '@images/icons/svg/visibility.svg';
import NoVisibilityIcon from '@images/icons/svg/noVisibility.svg';
import ColorPicker from 'components/form/ColorPicker';
import { PLAYER_MARKER_COLOR } from 'components/track/ground/constants';
import { Loader } from 'lib/icons';
import { useTranslation } from 'react-i18next';
import { getPairedGroundColor } from 'models/ground';
import { convertToTitle } from 'components/utils/utils';
import Table, { PropsIn as TableProps } from '../Table';
import { PlayersTableProps } from 'types/webPlayer';
import InfoIcon from '@material-ui/icons/Info';

const PlayersTable = (props: PlayersTableProps) => {
  const {
    athleteSessions,
    enabledPlayers,
    groundSurfaceColor,
    loadingPlayers,
    playersDetails,
    // playersExtraData,
    setEnabledPlayers,
    setPlayersColors,
  } = props;

  const { t } = useTranslation();

  const handlePlayersEnable = (checked: boolean, athId: string) => {
    const newSet = [...enabledPlayers];
    if (checked && !newSet.includes(athId)) {
      newSet.push(athId);
    } else if (!checked && newSet.includes(athId)) {
      newSet.splice(newSet.indexOf(athId), 1);
    }

    setEnabledPlayers(newSet);
  };

  const rows = useMemo(() => (athleteSessions.length ? athleteSessions.map((athlete) => ({
    id: athlete.id,
    name: athlete?.athlete?.name || '',
    number: playersDetails[athlete.id] && playersDetails[athlete.id].number || '',
    shortName: athlete?.athlete?.shortName || '',
    // speed: playersExtraData[athlete.id]?.s ? playersExtraData[athlete.id]?.s?.toFixed(2) : '',
  })) : []), [athleteSessions, playersDetails]);

  const tableProps: TableProps = useMemo(() => ({
    columns: [
      {
        field: 'visibility',
        headerClassName: 'hide-table-header',
        minWidth: 32,
        width: 32,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        hideSortIcons: true,
        renderCell: (params: GridCellParams) => {
          const isEnabled = enabledPlayers.includes(params.row.id);
          const isLoading = loadingPlayers.includes(params.row.id);

          return (
            isLoading
              ? <Loader className="players-loading" />
              : (
                <Box onClick={() => handlePlayersEnable(!isEnabled, params.row.id)}>
                  {isEnabled ? <VisibilityIcon /> : <NoVisibilityIcon />}
                </Box>
              )
          );
        },
      },
      {
        field: 'info',
        headerClassName: 'hide-table-header',
        minWidth: 32,
        width: 32,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        hideSortIcons: true,
        renderCell: (params: GridCellParams) => {
          const isEnabled = enabledPlayers.includes(params.row.id);
          const isLoading = loadingPlayers.includes(params.row.id);

          return (
            isLoading
              ? null
              : (
                <Box onClick={() => handlePlayersEnable(!isEnabled, params.row.id)}>
                  <InfoIcon />
                </Box>
              )
          );
        },
      },
      {
        field: 'color',
        headerClassName: 'hide-table-header',
        headerName: undefined,
        minWidth: 32,
        width: 32,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <ColorPicker
            style={{ position: 'absolute', margin: '10px' }}
            width="23px"
            height="23px"
            onChange={(color) => setPlayersColors((prevState) => ({
              ...prevState,
              [params.row.id]: color,
            }))}
            defaultColor={groundSurfaceColor ? getPairedGroundColor(groundSurfaceColor) : PLAYER_MARKER_COLOR}
            disableGradientPicker
            showDefaultColors
          />
        ),
      },
      {
        field: 'athlete',
        headerClassName: 'hide-table-header',
        headerName: t('table.athlete', 'athlete'),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <Tooltip arrow placement="right" title={`${params.row.name}`}>
            <span className="nolink-col-left">
              {params.row.number ? `${params.row.number} - ${params.row.shortName}` : `${params.row.shortName}`}
            </span>
          </Tooltip>
        ),
      },
    ],
    rows,
  }), [enabledPlayers, loadingPlayers, groundSurfaceColor, setPlayersColors, rows, t]);

  return (
    <Box p={2} marginLeft="-20px">
      <Table
        loading={rows.length === 0}
        hideTopFilters
        compactRows
        compactHeader
        footerTotalText={`${convertToTitle(t('athlete.title', 'athletes'))}: `}
        {...tableProps}
      />
    </Box>
  );
};

export default memo(PlayersTable);
