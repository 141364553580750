import { minutesToMMSS, secondsToMmSs } from './utils';
import { UNITS_LABELS_FORMATS } from './units';

export type UomType = {
  KM_H: 'km/h',
  M_MIN: 'm/min',
  M_S: 'm/s',
  M_S2: 'm/s²',
  M: 'm',
  CM: 'cm',
  B_MIN: 'b/min',
  J_KG: 'J/kg',
  ML_O2_KG: 'ml\u00A0O\u2082/kg' | 'ml O₂/kg',
  KCAL_KG: 'kcal/kg',
  W_KG: 'W/kg',
  ML_O2_KG_MIN: 'ml\u00A0O\u2082/kg\u00B7min' | 'ml O₂/kg·min',
  _P: '%',
  S: 's',
  MIN: 'min',
  C: 'C',
  F: 'F',
  DEGREE: '°',
};

export type UnitsType = 'hr' | 'speed' | 'power' | 'acceleration' | 'energy' | 'distance' | 'number' |'integer'
  | 'duration' | 'time' | 'temperature' | 'percentage' | 'percentage1' | 'degree';

export type CustomFormattersType = 'hdop' | 'pdop' | 'height' | 'number_avg' | 'zone_time';

const speedFormatter = (uom?: UomType['KM_H'] | UomType['M_MIN'] | UomType['M_S'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.KM_H:
      return numericValue.toFixed(1);
    case UNITS_LABELS_FORMATS.M_MIN:
      return numericValue.toFixed();
    case UNITS_LABELS_FORMATS.M_S:
      return numericValue.toFixed(2);
    default:
      return numericValue.toString();
  }
};

const powerFormatter = (uom?: UomType['W_KG'] | UomType['ML_O2_KG_MIN'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.W_KG:
      return numericValue.toFixed(2);
    case UNITS_LABELS_FORMATS.ML_O2_KG_MIN:
    case UNITS_LABELS_FORMATS.ML_O2_KG_MIN_STANDARD:
      return numericValue.toFixed(1);
    default:
      return numericValue.toString();
  }
};

const energyFormatter = (uom?: UomType['J_KG'] | UomType['ML_O2_KG'] | UomType['KCAL_KG'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.J_KG:
      return numericValue.toFixed();
    case UNITS_LABELS_FORMATS.ML_O2_KG:
    case UNITS_LABELS_FORMATS.ML_O2_KG_STANDARD:
      return numericValue.toFixed();
    case UNITS_LABELS_FORMATS.KCAL_KG:
      return numericValue.toFixed();
    default:
      return numericValue.toString();
  }
};

const percentageFormatter = (uom?: UomType['_P'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS._P:
      return numericValue.toFixed();
    default:
      return numericValue.toString();
  }
};

const percentage1Formatter = (uom?: UomType['_P'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS._P:
      return numericValue.toFixed(1);
    default:
      return numericValue.toString();
  }
};

const temperatureFormatter = (uom?: UomType['C'] | UomType['F'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.C:
      return numericValue.toFixed();
    case UNITS_LABELS_FORMATS.F:
      return numericValue.toFixed();
    default:
      return numericValue.toString();
  }
};

const cardioFormatter = (uom?: UomType['B_MIN'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.B_MIN:
      return numericValue.toFixed();
    default:
      return numericValue.toString();
  }
};

const accelerationFormatter = (uom?: UomType['M_S2'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.M_S2:
      return numericValue.toFixed(2);
    default:
      return numericValue.toString();
  }
};

const distanceFormatter = (uom?: UomType['M'], value?: number | string, nDecimals = 1) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.M:
      return numericValue.toFixed(nDecimals);
    default:
      return numericValue.toString();
  }
};

const durationFormatter = (uom?: UomType['S'] | UomType['MIN'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  if (typeof value === 'string' && value.split(':').length > 1) {
    return value;
  }
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.S:
      return numericValue.toFixed(1);
    case UNITS_LABELS_FORMATS.MIN:
      return minutesToMMSS(numericValue);
    default:
      return value.toString();
  }
};

// da capire differenza tra "timeFormatter" unit e "durationFormatter" unit. In tanto li lascio uguali

const timeFormatter = (uom?: UomType['S'] | UomType['MIN'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  if (typeof value === 'string' && value.split(':').length > 1) {
    return value;
  }
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.S:
      return numericValue.toFixed(2);
    case UNITS_LABELS_FORMATS.MIN:
      return minutesToMMSS(numericValue);
    default:
      return value.toString();
  }
};

const zoneTimeFormatter = (uom?: UomType['S'] | UomType['MIN'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  if (typeof value === 'string' && value.split(':').length > 1) {
    return value;
  }
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.S:
      return secondsToMmSs(numericValue);
    case UNITS_LABELS_FORMATS.MIN:
      return minutesToMMSS(numericValue);
    default:
      return value.toString();
  }
};

const numberFormatter = (uom?: ' ', value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case ' ':
      return numericValue.toFixed();
    default:
      return numericValue.toString();
  }
};

const integerFormatter = (uom?: ' ', value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case ' ':
      return numericValue.toFixed();
    default:
      return numericValue.toString();
  }
};

const degreeFormatter = (uom?: UomType['DEGREE'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.DEGREE:
      return numericValue.toFixed();
    default:
      return numericValue.toString();
  }
};

const unchangedFormatter = (uom?: string, value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  return value.toString();
};

/* exceptional formatters */
const hdopFormatter = (uom?: ' ', value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case ' ':
      return numericValue.toFixed(1);
    default:
      return numericValue.toString();
  }
};

const pdopFormatter = (uom?: ' ', value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case ' ':
      return numericValue.toFixed(1);
    default:
      return numericValue.toString();
  }
};

const heightFormatter = (uom?: UomType['M'], value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (uom) {
    case UNITS_LABELS_FORMATS.M:
    case UNITS_LABELS_FORMATS.CM:
      // temporarly convert meters to cm. Separate when height will be a field of UserUnits
      return Math.round(numericValue * 100).toFixed();
    default:
      return numericValue.toString();
  }
};

const numberAvgFormatter = (uom?: string, value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  return numericValue.toFixed(1);
};

export const formatterChoice = (unit?: UnitsType, customFormatter?: CustomFormattersType) => {
  if (customFormatter) {
    switch (customFormatter) {
      case 'hdop':
        return hdopFormatter;
      case 'pdop':
        return pdopFormatter;
      case 'height':
        return heightFormatter;
      case 'number_avg':
        return numberAvgFormatter;
      case 'zone_time':
        return zoneTimeFormatter;
      default:
        return unchangedFormatter;
    }
  }
  switch (unit) {
    case 'hr':
      return cardioFormatter;
    case 'speed':
      return speedFormatter;
    case 'power':
      return powerFormatter;
    case 'acceleration':
      return accelerationFormatter;
    case 'energy':
      return energyFormatter;
    case 'distance':
      return distanceFormatter;
    case 'number':
      return numberFormatter;
    case 'integer':
      return integerFormatter;
    case 'degree':
      return degreeFormatter;
    case 'duration':
      return durationFormatter;
    case 'time':
      return timeFormatter;
    case 'temperature':
      return temperatureFormatter;
    case 'percentage':
      return percentageFormatter;
    case 'percentage1':
      return percentage1Formatter;
    default:
      return unchangedFormatter;
  }
};

export const avgFormatter = (uom?: UomType, unit?: UnitsType, value?: number | string) => {
  if (value === null || value === undefined) return undefined;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  switch (unit) {
    case 'number':
      return numericValue?.toFixed(1);
    default:
      return formatterChoice(unit)(uom, numericValue);
  }
};

export {
  speedFormatter, powerFormatter, energyFormatter, percentageFormatter, percentage1Formatter, temperatureFormatter, cardioFormatter,
  accelerationFormatter, distanceFormatter, heightFormatter, numberFormatter, degreeFormatter, durationFormatter, timeFormatter,
};
